import React, {Fragment, useEffect, useRef, useState} from 'react'
import { useHistory } from "react-router";
import { Editor } from '@tinymce/tinymce-react';
import NavBar from "../Commun/NavBar";
import FloatFooter from "../Commun/FloatFooter";
import CheckPostField from "../Commun/CheckPostField";
import {useCookies} from "react-cookie";
const {REACT_APP_API_URL} = process.env;

const CreateBoard = () =>
{
    const history = useHistory();
    const [useOnce1, setUseOnce1] = useState(0);
    const [items1, setItems1] = useState([]);
    const [isLoaded1, setIsLoaded1] = useState(false);
    const [error1, setError1] = useState(null);
    const [cookies] = useCookies();
    const [firstname, setFirstname] = useState([]);
    const [lastname, setLastname] = useState([]);
    const [textarea, setTextarea] = useState([]);
    const [picture1, setPicture1] = useState(null);
    const [picture2, setPicture2] = useState(null);
    const [picture3, setPicture3] = useState(null);
    const [picture4, setPicture4] = useState(null);
    const [picture5, setPicture5] = useState(null);
    const [picture6, setPicture6] = useState(null);
    const [picture7, setPicture7] = useState(null);
    const [picture8, setPicture8] = useState(null);
    const [picture9, setPicture9] = useState(null);
    const [picture10, setPicture10] = useState(null);
    const [picture11, setPicture11] = useState(null);
    const [picture12, setPicture12] = useState(null);
    const [picture13, setPicture13] = useState(null);
    const [picture14, setPicture14] = useState(null);
    const [picture15, setPicture15] = useState(null);
    const [picture16, setPicture16] = useState(null);
    const [picture17, setPicture17] = useState(null);
    const [picture18, setPicture18] = useState(null);
    const [picture19, setPicture19] = useState(null);
    const [picture20, setPicture20] = useState(null);
    const [year, setYear] = useState(0);
    const [updateError, setUpdateError] = useState(null);
    const [messageStyle, setMessageStyle] = useState("hidden");

    const selectedFile=useRef();
    const editorRef = useRef(null);

    useEffect(() =>
    {
        if (useOnce1 === 0)
        {
            const tokenMember = cookies['ndmToken'];

            if (tokenMember)
            {
                let request = REACT_APP_API_URL + "member/get-autorisation/" + tokenMember;

                const optionsAPI =
                    {
                        method: 'GET'
                    };

                fetch(request, optionsAPI)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            setIsLoaded1(true);
                            setItems1(result);
                        },

                        (error) => {
                            setIsLoaded1(true);
                            setError1(error);
                        }
                    )
            }
            setUseOnce1(1);
        }
    })

    const handleSubmit = event =>
    {
        event.preventDefault();

        let formStatus = 1;
        for (let i = 1; i < 21; i++)
        {
            if (!firstname[i])
            {
                setMessageStyle("visible")
                setUpdateError("Le prénom du membre n°" + i + " n'est pas défini");
                formStatus = 0
            }
            if (!lastname[i])
            {
                setMessageStyle("visible")
                setUpdateError("Le nom du membre n°" + i + " n'est pas défini");
                formStatus = 0
            }
        }

        const currentTime = new Date();
        const actualYear = currentTime.getFullYear()

        if (year < actualYear - 1 || year > actualYear)
        {
            setMessageStyle("visible")
            setUpdateError("L'année choisie n'est bonne");
            formStatus = 0
        }

        if (formStatus === 1)
        {
            const memberToken = cookies['ndmToken'];
            const { REACT_APP_API_URL } = process.env;
            let request = REACT_APP_API_URL + "add-board";

            let selectFileData = new FormData();

            selectFileData.append('year', year);
            selectFileData.append('firstname', JSON.stringify(firstname));
            selectFileData.append('lastname', JSON.stringify(lastname));
            selectFileData.append('textarea', JSON.stringify(textarea));
            selectFileData.append('picture1', picture1);
            selectFileData.append('picture2', picture2);
            selectFileData.append('picture3', picture3);
            selectFileData.append('picture4', picture4);
            selectFileData.append('picture5', picture5);
            selectFileData.append('picture6', picture6);
            selectFileData.append('picture7', picture7);
            selectFileData.append('picture8', picture8);
            selectFileData.append('picture9', picture9);
            selectFileData.append('picture10', picture10);
            selectFileData.append('picture11', picture11);
            selectFileData.append('picture12', picture12);
            selectFileData.append('picture13', picture13);
            selectFileData.append('picture14', picture14);
            selectFileData.append('picture15', picture15);
            selectFileData.append('picture16', picture16);
            selectFileData.append('picture17', picture17);
            selectFileData.append('picture18', picture18);
            selectFileData.append('picture19', picture19);
            selectFileData.append('picture20', picture20);
            selectFileData.append('memberToken', memberToken);

            const options =
                {
                    method: 'POST',
                    body: selectFileData,
                };
            fetch(request, options)
                .then(
                    (result) => {
                        history.push('/ndmback/gerer-trombinoscopes');
                    },

                    (error) => {
                        setMessageStyle("visible")
                        setUpdateError(error);
                    }
                )
        }
    }

    const handleFileChange = event =>
    {
        event.preventDefault();

        const { name } = event.target;
        const file = event.target.files[0];

        if (name.substr(0,7) === 'picture')
        {
            const postPictureCheck = CheckPostField('picture', file);
            if (postPictureCheck === 0)
            {
                let i = 0;
                let j = '';

                if (name.length === 8)
                {
                    j = name.substr(7,1);
                }
                else
                {
                    j = name.substr(7,2);
                }
                i = parseInt(j);

                switch (i)
                {
                    case 1:
                        setPicture1(file);
                        break;
                    case 2:
                        setPicture2(file);
                        break;
                    case 3:
                        setPicture3(file);
                        break;
                    case 4:
                        setPicture4(file);
                        break;
                    case 5:
                        setPicture5(file);
                        break;
                    case 6:
                        setPicture6(file);
                        break;
                    case 7:
                        setPicture7(file);
                        break;
                    case 8:
                        setPicture8(file);
                        break;
                    case 9:
                        setPicture9(file);
                        break;
                    case 10:
                        setPicture10(file);
                        break;
                    case 11:
                        setPicture11(file);
                        break;
                    case 12:
                        setPicture12(file);
                        break;
                    case 13:
                        setPicture13(file);
                        break;
                    case 14:
                        setPicture14(file);
                        break;
                    case 15:
                        setPicture15(file);
                        break;
                    case 16:
                        setPicture16(file);
                        break;
                    case 17:
                        setPicture17(file);
                        break;
                    case 18:
                        setPicture18(file);
                        break;
                    case 19:
                        setPicture19(file);
                        break;
                    case 20:
                        setPicture20(file);
                        break;
                    default:
                        break;
                }

            }
        }
    }

    const handleFieldChange = event =>
    {
        event.preventDefault();

        const { name, value } = event.target;


        if(name === 'boardDate')
        {
            const yearCheck = CheckPostField('number', value);

            if (yearCheck === 0)
            {
                setYear(value);
            }
        }

        if (name.substr(0,9) === 'firstname')
        {
            const firstnameCheck = CheckPostField('name', value);

            if (firstnameCheck === 0)
            {
                let i = 0;
                let j = '';

                if (name.length === 10)
                {
                    j = name.substr(9,1);
                }
                else
                {
                    j = name.substr(9,2);
                }
                i = parseInt(j);

                let temp = firstname;
                temp[i] = value;
                setFirstname(temp);
            }
        }

        if (name.substr(0,8) === 'lastname')
        {
            const lastnameCheck = CheckPostField('name', value);

            if (lastnameCheck === 0)
            {
                let i = 0;
                let j = '';

                if (name.length === 9)
                {
                    j = name.substr(8,1);
                }
                else
                {
                    j = name.substr(8,2);
                }
                i = parseInt(j);

                console.log('i: ' + i);

                let temp = lastname;
                temp[i] = value;
                setLastname(temp);

                console.log('tempi: ' + temp[i]);
                console.log('lastname: ' + JSON.stringify(lastname));
            }
        }
    }

    if (error1)
    {
        return <div>Error: {error1.message}</div>;
    }
    else if (!isLoaded1)
    {
        return (
            <Fragment>
                <NavBar/>
                <section className="sectionNDM sectionAdmin section100">
                    <div className="containerNDM containerAdmin margTop">
                        En attente
                    </div>
                </section>
                <FloatFooter/>
            </Fragment>);
    }
    else
    {
        if (items1.status === 0 && (items1.roles.includes('EDITOR') || items1.roles.includes('ADMINISTRATOR')))
        {
            return (
                <Fragment>
                    <NavBar/>
                    <section className="sectionNDM sectionAdmin section100">
                        <div className="containerNDM containerAdmin margTop">
                            <div id="containerBackground">
                                <h1>Créer un nouveau trombinoscope du conseil d'administration</h1>
                                <form onSubmit={handleSubmit} id="formBackendView" encType="multipart/form-data" className="formFlex" action="">
                                    <div className="width100">
                                        <div className='postTitle flexFormColCenter'>
                                            <label className="formFont" htmlFor="boardDate">Quelle est l'année du conseil d'administration ?</label>
                                            <div>
                                                <input
                                                    type='number'
                                                    min="2015"
                                                    max="2100"
                                                    name='boardDate'
                                                    className="postTitle"
                                                    onChange={(e) => handleFieldChange(e)}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="width100">
                                        <div className='postTitle flexFormColCenter'>
                                            <label className="formFont" htmlFor="firstname1">Prénom du membre 1</label>
                                            <div>
                                                <input
                                                    type='text'
                                                    name='firstname1'
                                                    className="postTitle"
                                                    onChange={(e) => handleFieldChange(e)}/>
                                            </div>
                                        </div>
                                        <div className='postTitle flexFormColCenter'>
                                            <label className="formFont" htmlFor="lastname1">Nom du membre 1</label>
                                            <div>
                                                <input
                                                    type='text'
                                                    name="lastname1"
                                                    className="postTitle"
                                                    onChange={(e) => handleFieldChange(e)}/>
                                            </div>
                                        </div>
                                        <div className="flexFormColCenter postTextarea">
                                            <label className="formFont" htmlFor="textarea1">Description du membre 1</label>
                                            <Editor
                                                name="textarea1"
                                                onInit={(evt, editor) => editorRef.current = editor}
                                                value={textarea[1]}
                                                apiKey='yaysfwznq8jhd2wmq7ovzetookk8gjqqh4yls46m1fp7c8z3'
                                                init={{
                                                    height: 200,
                                                    menubar: false,
                                                    plugins: [
                                                        'advlist autolink lists link image charmap print preview anchor',
                                                        'searchreplace visualblocks code fullscreen',
                                                        'insertdatetime media table paste code help wordcount'
                                                    ],
                                                    toolbar: 'undo redo | formatselect | ' +
                                                        'bold italic forecolor underline | alignleft aligncenter ' +
                                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                                        'removeformat | help',
                                                    forced_root_block : false,
                                                    force_br_newlines : true,
                                                    force_p_newlines : false,
                                                }}
                                                onEditorChange={(value, editor) => {
                                                    let temp = textarea;
                                                    temp[1] = value;
                                                    setTextarea(temp);
                                                    }}/>
                                        </div>
                                        <div className="flexFormColCenter" id="uploadZone">
                                            <label className="formFont" htmlFor="picture1">Photo du membre 1</label>
                                            <input
                                                type="file"
                                                id="photo"
                                                name="picture1"
                                                ref={selectedFile}
                                                onChange={(e) => handleFileChange(e)}/>
                                        </div>
                                    </div>
                                    <div className="width100">
                                        <div className='postTitle flexFormColCenter'>
                                            <label className="formFont" htmlFor="firstname2">Prénom du membre 2</label>
                                            <div>
                                                <input
                                                    type='text'
                                                    name='firstname2'
                                                    className="postTitle"
                                                    onChange={(e) => handleFieldChange(e)}/>
                                            </div>
                                        </div>
                                        <div className='postTitle flexFormColCenter'>
                                            <label className="formFont" htmlFor="lastname1">Nom du membre 2</label>
                                            <div>
                                                <input
                                                    type='text'
                                                    name='lastname2'
                                                    className="postTitle"
                                                    onChange={(e) => handleFieldChange(e)}/>
                                            </div>
                                        </div>
                                        <div className="flexFormColCenter postTextarea">
                                            <label className="formFont" htmlFor="textarea2">Description du membre 2</label>
                                            <Editor
                                                name="textarea2"
                                                onInit={(evt, editor) => editorRef.current = editor}
                                                value={textarea[2]}
                                                apiKey='yaysfwznq8jhd2wmq7ovzetookk8gjqqh4yls46m1fp7c8z3'
                                                init={{
                                                    height: 200,
                                                    menubar: false,
                                                    plugins: [
                                                        'advlist autolink lists link image charmap print preview anchor',
                                                        'searchreplace visualblocks code fullscreen',
                                                        'insertdatetime media table paste code help wordcount'
                                                    ],
                                                    toolbar: 'undo redo | formatselect | ' +
                                                        'bold italic forecolor underline | alignleft aligncenter ' +
                                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                                        'removeformat | help',
                                                    forced_root_block : false,
                                                    force_br_newlines : true,
                                                    force_p_newlines : false,
                                                }}
                                                onEditorChange={(value, editor) => {
                                                    let temp = textarea;
                                                    temp[2] = value;
                                                    setTextarea(temp);
                                                }}/>
                                        </div>
                                        <div className="flexFormColCenter" id="uploadZone">
                                            <label className="formFont" htmlFor="picture2">Photo du membre 2</label>
                                            <input
                                                type="file"
                                                id="photo"
                                                name="picture2"
                                                ref={selectedFile}
                                                onChange={(e) => handleFileChange(e)}/>
                                        </div>
                                    </div>
                                    <div className="width100">
                                        <div className='postTitle flexFormColCenter'>
                                            <label className="formFont" htmlFor="firstname3">Prénom du membre 3</label>
                                            <div>
                                                <input
                                                    type='text'
                                                    name='firstname3'
                                                    className="postTitle"
                                                    onChange={(e) => handleFieldChange(e)}/>
                                            </div>
                                        </div>
                                        <div className='postTitle flexFormColCenter'>
                                            <label className="formFont" htmlFor="lastname3">Nom du membre 3</label>
                                            <div>
                                                <input
                                                    type='text'
                                                    name='lastname3'
                                                    className="postTitle"
                                                    onChange={(e) => handleFieldChange(e)}/>
                                            </div>
                                        </div>
                                        <div className="flexFormColCenter postTextarea">
                                            <label className="formFont" htmlFor="textarea3">Description du membre 3</label>
                                            <Editor
                                                name="textarea3"
                                                onInit={(evt, editor) => editorRef.current = editor}
                                                value={textarea[3]}
                                                apiKey='yaysfwznq8jhd2wmq7ovzetookk8gjqqh4yls46m1fp7c8z3'
                                                init={{
                                                    height: 200,
                                                    menubar: false,
                                                    plugins: [
                                                        'advlist autolink lists link image charmap print preview anchor',
                                                        'searchreplace visualblocks code fullscreen',
                                                        'insertdatetime media table paste code help wordcount'
                                                    ],
                                                    toolbar: 'undo redo | formatselect | ' +
                                                        'bold italic forecolor underline | alignleft aligncenter ' +
                                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                                        'removeformat | help',
                                                    forced_root_block : false,
                                                    force_br_newlines : true,
                                                    force_p_newlines : false,
                                                }}
                                                onEditorChange={(value, editor) => {
                                                    let temp = textarea;
                                                    temp[3] = value;
                                                    setTextarea(temp);
                                                }}/>
                                        </div>
                                        <div className="flexFormColCenter" id="uploadZone">
                                            <label className="formFont" htmlFor="picture3">Photo du membre 3</label>
                                            <input
                                                type="file"
                                                id="photo"
                                                name="picture3"
                                                ref={selectedFile}
                                                onChange={(e) => handleFileChange(e)}/>
                                        </div>
                                    </div>
                                    <div className="width100">
                                        <div className='postTitle flexFormColCenter'>
                                            <label className="formFont" htmlFor="firstname4">Prénom du membre 4</label>
                                            <div>
                                                <input
                                                    type='text'
                                                    name='firstname4'
                                                    className="postTitle"
                                                    onChange={(e) => handleFieldChange(e)}/>
                                            </div>
                                        </div>
                                        <div className='postTitle flexFormColCenter'>
                                            <label className="formFont" htmlFor="lastname4">Nom du membre 4</label>
                                            <div>
                                                <input
                                                    type='text'
                                                    name='lastname4'
                                                    className="postTitle"
                                                    onChange={(e) => handleFieldChange(e)}/>
                                            </div>
                                        </div>
                                        <div className="flexFormColCenter postTextarea">
                                            <label className="formFont" htmlFor="textarea4">Description du membre 4</label>
                                            <Editor
                                                name="textarea4"
                                                onInit={(evt, editor) => editorRef.current = editor}
                                                value={textarea[4]}
                                                apiKey='yaysfwznq8jhd2wmq7ovzetookk8gjqqh4yls46m1fp7c8z3'
                                                init={{
                                                    height: 200,
                                                    menubar: false,
                                                    plugins: [
                                                        'advlist autolink lists link image charmap print preview anchor',
                                                        'searchreplace visualblocks code fullscreen',
                                                        'insertdatetime media table paste code help wordcount'
                                                    ],
                                                    toolbar: 'undo redo | formatselect | ' +
                                                        'bold italic forecolor underline | alignleft aligncenter ' +
                                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                                        'removeformat | help',
                                                    forced_root_block : false,
                                                    force_br_newlines : true,
                                                    force_p_newlines : false,
                                                }}
                                                onEditorChange={(value, editor) => {
                                                    let temp = textarea;
                                                    temp[4] = value;
                                                    setTextarea(temp);
                                                }}/>
                                        </div>
                                        <div className="flexFormColCenter" id="uploadZone">
                                            <label className="formFont" htmlFor="picture4">Photo du membre 4</label>
                                            <input
                                                type="file"
                                                id="photo"
                                                name="picture4"
                                                ref={selectedFile}
                                                onChange={(e) => handleFileChange(e)}/>
                                        </div>
                                    </div>
                                    <div className="width100">
                                        <div className='postTitle flexFormColCenter'>
                                            <label className="formFont" htmlFor="firstname5">Prénom du membre 5</label>
                                            <div>
                                                <input
                                                    type='text'
                                                    name='firstname5'
                                                    className="postTitle"
                                                    onChange={(e) => handleFieldChange(e)}/>
                                            </div>
                                        </div>
                                        <div className='postTitle flexFormColCenter'>
                                            <label className="formFont" htmlFor="lastname5">Nom du membre 5</label>
                                            <div>
                                                <input
                                                    type='text'
                                                    name='lastname5'
                                                    className="postTitle"
                                                    onChange={(e) => handleFieldChange(e)}/>
                                            </div>
                                        </div>
                                        <div className="flexFormColCenter postTextarea">
                                            <label className="formFont" htmlFor="textarea5">Description du membre 5</label>
                                            <Editor
                                                name="textarea5"
                                                onInit={(evt, editor) => editorRef.current = editor}
                                                value={textarea[5]}
                                                apiKey='yaysfwznq8jhd2wmq7ovzetookk8gjqqh4yls46m1fp7c8z3'
                                                init={{
                                                    height: 200,
                                                    menubar: false,
                                                    plugins: [
                                                        'advlist autolink lists link image charmap print preview anchor',
                                                        'searchreplace visualblocks code fullscreen',
                                                        'insertdatetime media table paste code help wordcount'
                                                    ],
                                                    toolbar: 'undo redo | formatselect | ' +
                                                        'bold italic forecolor underline | alignleft aligncenter ' +
                                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                                        'removeformat | help',
                                                    forced_root_block : false,
                                                    force_br_newlines : true,
                                                    force_p_newlines : false,
                                                }}
                                                onEditorChange={(value, editor) => {
                                                    let temp = textarea;
                                                    temp[5] = value;
                                                    setTextarea(temp);
                                                }}/>
                                        </div>
                                        <div className="flexFormColCenter" id="uploadZone">
                                            <label className="formFont" htmlFor="picture5">Photo du membre 5</label>
                                            <input
                                                type="file"
                                                id="photo"
                                                name="picture5"
                                                ref={selectedFile}
                                                onChange={(e) => handleFileChange(e)}/>
                                        </div>
                                    </div>
                                    <div className="width100">
                                        <div className='postTitle flexFormColCenter'>
                                            <label className="formFont" htmlFor="firstname6">Prénom du membre 6</label>
                                            <div>
                                                <input
                                                    type='text'
                                                    name='firstname6'
                                                    className="postTitle"
                                                    onChange={(e) => handleFieldChange(e)}/>
                                            </div>
                                        </div>
                                        <div className='postTitle flexFormColCenter'>
                                            <label className="formFont" htmlFor="lastname6">Nom du membre 6</label>
                                            <div>
                                                <input
                                                    type='text'
                                                    name='lastname6'
                                                    className="postTitle"
                                                    onChange={(e) => handleFieldChange(e)}/>
                                            </div>
                                        </div>
                                        <div className="flexFormColCenter postTextarea">
                                            <label className="formFont" htmlFor="textarea6">Description du membre 6</label>
                                            <Editor
                                                name="textarea6"
                                                onInit={(evt, editor) => editorRef.current = editor}
                                                value={textarea[6]}
                                                apiKey='yaysfwznq8jhd2wmq7ovzetookk8gjqqh4yls46m1fp7c8z3'
                                                init={{
                                                    height: 200,
                                                    menubar: false,
                                                    plugins: [
                                                        'advlist autolink lists link image charmap print preview anchor',
                                                        'searchreplace visualblocks code fullscreen',
                                                        'insertdatetime media table paste code help wordcount'
                                                    ],
                                                    toolbar: 'undo redo | formatselect | ' +
                                                        'bold italic forecolor underline | alignleft aligncenter ' +
                                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                                        'removeformat | help',
                                                    forced_root_block : false,
                                                    force_br_newlines : true,
                                                    force_p_newlines : false,
                                                }}
                                                onEditorChange={(value, editor) => {
                                                    let temp = textarea;
                                                    temp[6] = value;
                                                    setTextarea(temp);
                                                }}/>
                                        </div>
                                        <div className="flexFormColCenter" id="uploadZone">
                                            <label className="formFont" htmlFor="picture6">Photo du membre 6</label>
                                            <input
                                                type="file"
                                                id="photo"
                                                name="picture6"
                                                ref={selectedFile}
                                                onChange={(e) => handleFileChange(e)}/>
                                        </div>
                                    </div>
                                    <div className="width100">
                                        <div className='postTitle flexFormColCenter'>
                                            <label className="formFont" htmlFor="firstname7">Prénom du membre 7</label>
                                            <div>
                                                <input
                                                    type='text'
                                                    name='firstname7'
                                                    className="postTitle"
                                                    onChange={(e) => handleFieldChange(e)}/>
                                            </div>
                                        </div>
                                        <div className='postTitle flexFormColCenter'>
                                            <label className="formFont" htmlFor="lastname7">Nom du membre 7</label>
                                            <div>
                                                <input
                                                    type='text'
                                                    name='lastname7'
                                                    className="postTitle"
                                                    onChange={(e) => handleFieldChange(e)}/>
                                            </div>
                                        </div>
                                        <div className="flexFormColCenter postTextarea">
                                            <label className="formFont" htmlFor="textarea7">Description du membre 7</label>
                                            <Editor
                                                name="textarea7"
                                                onInit={(evt, editor) => editorRef.current = editor}
                                                value={textarea[7]}
                                                apiKey='yaysfwznq8jhd2wmq7ovzetookk8gjqqh4yls46m1fp7c8z3'
                                                init={{
                                                    height: 200,
                                                    menubar: false,
                                                    plugins: [
                                                        'advlist autolink lists link image charmap print preview anchor',
                                                        'searchreplace visualblocks code fullscreen',
                                                        'insertdatetime media table paste code help wordcount'
                                                    ],
                                                    toolbar: 'undo redo | formatselect | ' +
                                                        'bold italic forecolor underline | alignleft aligncenter ' +
                                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                                        'removeformat | help',
                                                    forced_root_block : false,
                                                    force_br_newlines : true,
                                                    force_p_newlines : false,
                                                }}
                                                onEditorChange={(value, editor) => {
                                                    let temp = textarea;
                                                    temp[7] = value;
                                                    setTextarea(temp);
                                                }}/>
                                        </div>
                                        <div className="flexFormColCenter" id="uploadZone">
                                            <label className="formFont" htmlFor="picture7">Photo du membre 7</label>
                                            <input
                                                type="file"
                                                id="photo"
                                                name="picture7"
                                                ref={selectedFile}
                                                onChange={(e) => handleFileChange(e)}/>
                                        </div>
                                    </div>
                                    <div className="width100">
                                        <div className='postTitle flexFormColCenter'>
                                            <label className="formFont" htmlFor="firstname8">Prénom du membre 8</label>
                                            <div>
                                                <input
                                                    type='text'
                                                    name='firstname8'
                                                    className="postTitle"
                                                    onChange={(e) => handleFieldChange(e)}/>
                                            </div>
                                        </div>
                                        <div className='postTitle flexFormColCenter'>
                                            <label className="formFont" htmlFor="lastname8">Nom du membre 8</label>
                                            <div>
                                                <input
                                                    type='text'
                                                    name='lastname8'
                                                    className="postTitle"
                                                    onChange={(e) => handleFieldChange(e)}/>
                                            </div>
                                        </div>
                                        <div className="flexFormColCenter postTextarea">
                                            <label className="formFont" htmlFor="textarea8">Description du membre 8</label>
                                            <Editor
                                                name="textarea8"
                                                onInit={(evt, editor) => editorRef.current = editor}
                                                value={textarea[8]}
                                                apiKey='yaysfwznq8jhd2wmq7ovzetookk8gjqqh4yls46m1fp7c8z3'
                                                init={{
                                                    height: 200,
                                                    menubar: false,
                                                    plugins: [
                                                        'advlist autolink lists link image charmap print preview anchor',
                                                        'searchreplace visualblocks code fullscreen',
                                                        'insertdatetime media table paste code help wordcount'
                                                    ],
                                                    toolbar: 'undo redo | formatselect | ' +
                                                        'bold italic forecolor underline | alignleft aligncenter ' +
                                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                                        'removeformat | help',
                                                    forced_root_block : false,
                                                    force_br_newlines : true,
                                                    force_p_newlines : false,
                                                }}
                                                onEditorChange={(value, editor) => {
                                                    let temp = textarea;
                                                    temp[8] = value;
                                                    setTextarea(temp);
                                                }}/>
                                        </div>
                                        <div className="flexFormColCenter" id="uploadZone">
                                            <label className="formFont" htmlFor="picture8">Photo du membre 8</label>
                                            <input
                                                type="file"
                                                id="photo"
                                                name="picture8"
                                                ref={selectedFile}
                                                onChange={(e) => handleFileChange(e)}/>
                                        </div>
                                    </div>
                                    <div className="width100">
                                        <div className='postTitle flexFormColCenter'>
                                            <label className="formFont" htmlFor="firstname9">Prénom du membre 9</label>
                                            <div>
                                                <input
                                                    type='text'
                                                    name='firstname9'
                                                    className="postTitle"
                                                    onChange={(e) => handleFieldChange(e)}/>
                                            </div>
                                        </div>
                                        <div className='postTitle flexFormColCenter'>
                                            <label className="formFont" htmlFor="lastname1">Nom du membre 9</label>
                                            <div>
                                                <input
                                                    type='text'
                                                    name='lastname9'
                                                    className="postTitle"
                                                    onChange={(e) => handleFieldChange(e)}/>
                                            </div>
                                        </div>
                                        <div className="flexFormColCenter postTextarea">
                                            <label className="formFont" htmlFor="textarea9">Description du membre 9</label>
                                            <Editor
                                                name="textarea9"
                                                onInit={(evt, editor) => editorRef.current = editor}
                                                value={textarea[9]}
                                                apiKey='yaysfwznq8jhd2wmq7ovzetookk8gjqqh4yls46m1fp7c8z3'
                                                init={{
                                                    height: 200,
                                                    menubar: false,
                                                    plugins: [
                                                        'advlist autolink lists link image charmap print preview anchor',
                                                        'searchreplace visualblocks code fullscreen',
                                                        'insertdatetime media table paste code help wordcount'
                                                    ],
                                                    toolbar: 'undo redo | formatselect | ' +
                                                        'bold italic forecolor underline | alignleft aligncenter ' +
                                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                                        'removeformat | help',
                                                    forced_root_block : false,
                                                    force_br_newlines : true,
                                                    force_p_newlines : false,
                                                }}
                                                onEditorChange={(value, editor) => {
                                                    let temp = textarea;
                                                    temp[9] = value;
                                                    setTextarea(temp);
                                                }}/>
                                        </div>
                                        <div className="flexFormColCenter" id="uploadZone">
                                            <label className="formFont" htmlFor="picture9">Photo du membre 9</label>
                                            <input
                                                type="file"
                                                id="photo"
                                                name="picture9"
                                                ref={selectedFile}
                                                onChange={(e) => handleFileChange(e)}/>
                                        </div>
                                    </div>
                                    <div className="width100">
                                        <div className='postTitle flexFormColCenter'>
                                            <label className="formFont" htmlFor="firstname10">Prénom du membre 10</label>
                                            <div>
                                                <input
                                                    type='text'
                                                    name='firstname10'
                                                    className="postTitle"
                                                    onChange={(e) => handleFieldChange(e)}/>
                                            </div>
                                        </div>
                                        <div className='postTitle flexFormColCenter'>
                                            <label className="formFont" htmlFor="lastname10">Nom du membre 10</label>
                                            <div>
                                                <input
                                                    type='text'
                                                    name='lastname10'
                                                    className="postTitle"
                                                    onChange={(e) => handleFieldChange(e)}/>
                                            </div>
                                        </div>
                                        <div className="flexFormColCenter postTextarea">
                                            <label className="formFont" htmlFor="textarea10">Description du membre 10</label>
                                            <Editor
                                                name="textarea10"
                                                onInit={(evt, editor) => editorRef.current = editor}
                                                value={textarea[10]}
                                                apiKey='yaysfwznq8jhd2wmq7ovzetookk8gjqqh4yls46m1fp7c8z3'
                                                init={{
                                                    height: 200,
                                                    menubar: false,
                                                    plugins: [
                                                        'advlist autolink lists link image charmap print preview anchor',
                                                        'searchreplace visualblocks code fullscreen',
                                                        'insertdatetime media table paste code help wordcount'
                                                    ],
                                                    toolbar: 'undo redo | formatselect | ' +
                                                        'bold italic forecolor underline | alignleft aligncenter ' +
                                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                                        'removeformat | help',
                                                    forced_root_block : false,
                                                    force_br_newlines : true,
                                                    force_p_newlines : false,
                                                }}
                                                onEditorChange={(value, editor) => {
                                                    let temp = textarea;
                                                    temp[10] = value;
                                                    setTextarea(temp);
                                                }}/>
                                        </div>
                                        <div className="flexFormColCenter" id="uploadZone">
                                            <label className="formFont" htmlFor="picture10">Photo du membre 10</label>
                                            <input
                                                type="file"
                                                id="photo"
                                                name="picture10"
                                                ref={selectedFile}
                                                onChange={(e) => handleFileChange(e)}/>
                                        </div>
                                    </div>
                                    <div className="width100">
                                        <div className='postTitle flexFormColCenter'>
                                            <label className="formFont" htmlFor="firstname11">Prénom du membre 11</label>
                                            <div>
                                                <input
                                                    type='text'
                                                    name='firstname11'
                                                    className="postTitle"
                                                    onChange={(e) => handleFieldChange(e)}/>
                                            </div>
                                        </div>
                                        <div className='postTitle flexFormColCenter'>
                                            <label className="formFont" htmlFor="lastname11">Nom du membre 11</label>
                                            <div>
                                                <input
                                                    type='text'
                                                    name='lastname11'
                                                    className="postTitle"
                                                    onChange={(e) => handleFieldChange(e)}/>
                                            </div>
                                        </div>
                                        <div className="flexFormColCenter postTextarea">
                                            <label className="formFont" htmlFor="textarea11">Description du membre 11</label>
                                            <Editor
                                                name="textarea11"
                                                onInit={(evt, editor) => editorRef.current = editor}
                                                value={textarea[11]}
                                                apiKey='yaysfwznq8jhd2wmq7ovzetookk8gjqqh4yls46m1fp7c8z3'
                                                init={{
                                                    height: 200,
                                                    menubar: false,
                                                    plugins: [
                                                        'advlist autolink lists link image charmap print preview anchor',
                                                        'searchreplace visualblocks code fullscreen',
                                                        'insertdatetime media table paste code help wordcount'
                                                    ],
                                                    toolbar: 'undo redo | formatselect | ' +
                                                        'bold italic forecolor underline | alignleft aligncenter ' +
                                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                                        'removeformat | help',
                                                    forced_root_block : false,
                                                    force_br_newlines : true,
                                                    force_p_newlines : false,
                                                }}
                                                onEditorChange={(value, editor) => {
                                                    let temp = textarea;
                                                    temp[11] = value;
                                                    setTextarea(temp);
                                                }}/>
                                        </div>
                                        <div className="flexFormColCenter" id="uploadZone">
                                            <label className="formFont" htmlFor="picture11">Photo du membre 11</label>
                                            <input
                                                type="file"
                                                id="photo"
                                                name="picture11"
                                                ref={selectedFile}
                                                onChange={(e) => handleFileChange(e)}/>
                                        </div>
                                    </div>
                                    <div className="width100">
                                        <div className='postTitle flexFormColCenter'>
                                            <label className="formFont" htmlFor="firstname12">Prénom du membre 12</label>
                                            <div>
                                                <input
                                                    type='text'
                                                    name='firstname12'
                                                    className="postTitle"
                                                    onChange={(e) => handleFieldChange(e)}/>
                                            </div>
                                        </div>
                                        <div className='postTitle flexFormColCenter'>
                                            <label className="formFont" htmlFor="lastname1">Nom du membre 12</label>
                                            <div>
                                                <input
                                                    type='text'
                                                    name='lastname12'
                                                    className="postTitle"
                                                    onChange={(e) => handleFieldChange(e)}/>
                                            </div>
                                        </div>
                                        <div className="flexFormColCenter postTextarea">
                                            <label className="formFont" htmlFor="textarea12">Description du membre 12</label>
                                            <Editor
                                                name="textarea12"
                                                onInit={(evt, editor) => editorRef.current = editor}
                                                value={textarea[12]}
                                                apiKey='yaysfwznq8jhd2wmq7ovzetookk8gjqqh4yls46m1fp7c8z3'
                                                init={{
                                                    height: 200,
                                                    menubar: false,
                                                    plugins: [
                                                        'advlist autolink lists link image charmap print preview anchor',
                                                        'searchreplace visualblocks code fullscreen',
                                                        'insertdatetime media table paste code help wordcount'
                                                    ],
                                                    toolbar: 'undo redo | formatselect | ' +
                                                        'bold italic forecolor underline | alignleft aligncenter ' +
                                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                                        'removeformat | help',
                                                    forced_root_block : false,
                                                    force_br_newlines : true,
                                                    force_p_newlines : false,
                                                }}
                                                onEditorChange={(value, editor) => {
                                                    let temp = textarea;
                                                    temp[12] = value;
                                                    setTextarea(temp);
                                                }}/>
                                        </div>
                                        <div className="flexFormColCenter" id="uploadZone">
                                            <label className="formFont" htmlFor="picture12">Photo du membre 12</label>
                                            <input
                                                type="file"
                                                id="photo"
                                                name="picture12"
                                                ref={selectedFile}
                                                onChange={(e) => handleFileChange(e)}/>
                                        </div>
                                    </div>
                                    <div className="width100">
                                        <div className='postTitle flexFormColCenter'>
                                            <label className="formFont" htmlFor="firstname13">Prénom du membre 13</label>
                                            <div>
                                                <input
                                                    type='text'
                                                    name='firstname13'
                                                    className="postTitle"
                                                    onChange={(e) => handleFieldChange(e)}/>
                                            </div>
                                        </div>
                                        <div className='postTitle flexFormColCenter'>
                                            <label className="formFont" htmlFor="lastname13">Nom du membre 13</label>
                                            <div>
                                                <input
                                                    type='text'
                                                    name='lastname13'
                                                    className="postTitle"
                                                    onChange={(e) => handleFieldChange(e)}/>
                                            </div>
                                        </div>
                                        <div className="flexFormColCenter postTextarea">
                                            <label className="formFont" htmlFor="textarea13">Description du membre 13</label>
                                            <Editor
                                                name="textarea13"
                                                onInit={(evt, editor) => editorRef.current = editor}
                                                value={textarea[13]}
                                                apiKey='yaysfwznq8jhd2wmq7ovzetookk8gjqqh4yls46m1fp7c8z3'
                                                init={{
                                                    height: 200,
                                                    menubar: false,
                                                    plugins: [
                                                        'advlist autolink lists link image charmap print preview anchor',
                                                        'searchreplace visualblocks code fullscreen',
                                                        'insertdatetime media table paste code help wordcount'
                                                    ],
                                                    toolbar: 'undo redo | formatselect | ' +
                                                        'bold italic forecolor underline | alignleft aligncenter ' +
                                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                                        'removeformat | help',
                                                    forced_root_block : false,
                                                    force_br_newlines : true,
                                                    force_p_newlines : false,
                                                }}
                                                onEditorChange={(value, editor) => {
                                                    let temp = textarea;
                                                    temp[13] = value;
                                                    setTextarea(temp);
                                                }}/>
                                        </div>
                                        <div className="flexFormColCenter" id="uploadZone">
                                            <label className="formFont" htmlFor="picture13">Photo du membre 13</label>
                                            <input
                                                type="file"
                                                id="photo"
                                                name="picture13"
                                                ref={selectedFile}
                                                onChange={(e) => handleFileChange(e)}/>
                                        </div>
                                    </div>
                                    <div className="width100">
                                        <div className='postTitle flexFormColCenter'>
                                            <label className="formFont" htmlFor="firstname14">Prénom du membre 14</label>
                                            <div>
                                                <input
                                                    type='text'
                                                    name='firstname14'
                                                    className="postTitle"
                                                    onChange={(e) => handleFieldChange(e)}/>
                                            </div>
                                        </div>
                                        <div className='postTitle flexFormColCenter'>
                                            <label className="formFont" htmlFor="lastname14">Nom du membre 14</label>
                                            <div>
                                                <input
                                                    type='text'
                                                    name='lastname14'
                                                    className="postTitle"
                                                    onChange={(e) => handleFieldChange(e)}/>
                                            </div>
                                        </div>
                                        <div className="flexFormColCenter postTextarea">
                                            <label className="formFont" htmlFor="textarea14">Description du membre 14</label>
                                            <Editor
                                                name="textarea14"
                                                onInit={(evt, editor) => editorRef.current = editor}
                                                value={textarea[14]}
                                                apiKey='yaysfwznq8jhd2wmq7ovzetookk8gjqqh4yls46m1fp7c8z3'
                                                init={{
                                                    height: 200,
                                                    menubar: false,
                                                    plugins: [
                                                        'advlist autolink lists link image charmap print preview anchor',
                                                        'searchreplace visualblocks code fullscreen',
                                                        'insertdatetime media table paste code help wordcount'
                                                    ],
                                                    toolbar: 'undo redo | formatselect | ' +
                                                        'bold italic forecolor underline | alignleft aligncenter ' +
                                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                                        'removeformat | help',
                                                    forced_root_block : false,
                                                    force_br_newlines : true,
                                                    force_p_newlines : false,
                                                }}
                                                onEditorChange={(value, editor) => {
                                                    let temp = textarea;
                                                    temp[14] = value;
                                                    setTextarea(temp);
                                                }}/>
                                        </div>
                                        <div className="flexFormColCenter" id="uploadZone">
                                            <label className="formFont" htmlFor="picture14">Photo du membre 14</label>
                                            <input
                                                type="file"
                                                id="photo"
                                                name="picture14"
                                                ref={selectedFile}
                                                onChange={(e) => handleFileChange(e)}/>
                                        </div>
                                    </div>
                                    <div className="width100">
                                        <div className='postTitle flexFormColCenter'>
                                            <label className="formFont" htmlFor="firstname15">Prénom du membre 15</label>
                                            <div>
                                                <input
                                                    type='text'
                                                    name='firstname15'
                                                    className="postTitle"
                                                    onChange={(e) => handleFieldChange(e)}/>
                                            </div>
                                        </div>
                                        <div className='postTitle flexFormColCenter'>
                                            <label className="formFont" htmlFor="lastname15">Nom du membre 15</label>
                                            <div>
                                                <input
                                                    type='text'
                                                    name='lastname15'
                                                    className="postTitle"
                                                    onChange={(e) => handleFieldChange(e)}/>
                                            </div>
                                        </div>
                                        <div className="flexFormColCenter postTextarea">
                                            <label className="formFont" htmlFor="textarea15">Description du membre 15</label>
                                            <Editor
                                                name="textarea15"
                                                onInit={(evt, editor) => editorRef.current = editor}
                                                value={textarea[15]}
                                                apiKey='yaysfwznq8jhd2wmq7ovzetookk8gjqqh4yls46m1fp7c8z3'
                                                init={{
                                                    height: 200,
                                                    menubar: false,
                                                    plugins: [
                                                        'advlist autolink lists link image charmap print preview anchor',
                                                        'searchreplace visualblocks code fullscreen',
                                                        'insertdatetime media table paste code help wordcount'
                                                    ],
                                                    toolbar: 'undo redo | formatselect | ' +
                                                        'bold italic forecolor underline | alignleft aligncenter ' +
                                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                                        'removeformat | help',
                                                    forced_root_block : false,
                                                    force_br_newlines : true,
                                                    force_p_newlines : false,
                                                }}
                                                onEditorChange={(value, editor) => {
                                                    let temp = textarea;
                                                    temp[15] = value;
                                                    setTextarea(temp);
                                                }}/>
                                        </div>
                                        <div className="flexFormColCenter" id="uploadZone">
                                            <label className="formFont" htmlFor="picture15">Photo du membre 15</label>
                                            <input
                                                type="file"
                                                id="photo"
                                                name="picture15"
                                                ref={selectedFile}
                                                onChange={(e) => handleFileChange(e)}/>
                                        </div>
                                    </div>
                                    <div className="width100">
                                        <div className='postTitle flexFormColCenter'>
                                            <label className="formFont" htmlFor="firstname16">Prénom du membre 16</label>
                                            <div>
                                                <input
                                                    type='text'
                                                    name='firstname16'
                                                    className="postTitle"
                                                    onChange={(e) => handleFieldChange(e)}/>
                                            </div>
                                        </div>
                                        <div className='postTitle flexFormColCenter'>
                                            <label className="formFont" htmlFor="lastname1">Nom du membre 16</label>
                                            <div>
                                                <input
                                                    type='text'
                                                    name='lastname16'
                                                    className="postTitle"
                                                    onChange={(e) => handleFieldChange(e)}/>
                                            </div>
                                        </div>
                                        <div className="flexFormColCenter postTextarea">
                                            <label className="formFont" htmlFor="textarea16">Description du membre 16</label>
                                            <Editor
                                                name="textarea16"
                                                onInit={(evt, editor) => editorRef.current = editor}
                                                value={textarea[16]}
                                                apiKey='yaysfwznq8jhd2wmq7ovzetookk8gjqqh4yls46m1fp7c8z3'
                                                init={{
                                                    height: 200,
                                                    menubar: false,
                                                    plugins: [
                                                        'advlist autolink lists link image charmap print preview anchor',
                                                        'searchreplace visualblocks code fullscreen',
                                                        'insertdatetime media table paste code help wordcount'
                                                    ],
                                                    toolbar: 'undo redo | formatselect | ' +
                                                        'bold italic forecolor underline | alignleft aligncenter ' +
                                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                                        'removeformat | help',
                                                    forced_root_block : false,
                                                    force_br_newlines : true,
                                                    force_p_newlines : false,
                                                }}
                                                onEditorChange={(value, editor) => {
                                                    let temp = textarea;
                                                    temp[16] = value;
                                                    setTextarea(temp);
                                                }}/>
                                        </div>
                                        <div className="flexFormColCenter" id="uploadZone">
                                            <label className="formFont" htmlFor="picture16">Photo du membre 16</label>
                                            <input
                                                type="file"
                                                id="photo"
                                                name="picture16"
                                                ref={selectedFile}
                                                onChange={(e) => handleFileChange(e)}/>
                                        </div>
                                    </div>
                                    <div className="width100">
                                        <div className='postTitle flexFormColCenter'>
                                            <label className="formFont" htmlFor="firstname17">Prénom du membre 17</label>
                                            <div>
                                                <input
                                                    type='text'
                                                    name='firstname17'
                                                    className="postTitle"
                                                    onChange={(e) => handleFieldChange(e)}/>
                                            </div>
                                        </div>
                                        <div className='postTitle flexFormColCenter'>
                                            <label className="formFont" htmlFor="lastname17">Nom du membre 17</label>
                                            <div>
                                                <input
                                                    type='text'
                                                    name='lastname17'
                                                    className="postTitle"
                                                    onChange={(e) => handleFieldChange(e)}/>
                                            </div>
                                        </div>
                                        <div className="flexFormColCenter postTextarea">
                                            <label className="formFont" htmlFor="textarea17">Description du membre 17</label>
                                            <Editor
                                                name="textarea17"
                                                onInit={(evt, editor) => editorRef.current = editor}
                                                value={textarea[17]}
                                                apiKey='yaysfwznq8jhd2wmq7ovzetookk8gjqqh4yls46m1fp7c8z3'
                                                init={{
                                                    height: 200,
                                                    menubar: false,
                                                    plugins: [
                                                        'advlist autolink lists link image charmap print preview anchor',
                                                        'searchreplace visualblocks code fullscreen',
                                                        'insertdatetime media table paste code help wordcount'
                                                    ],
                                                    toolbar: 'undo redo | formatselect | ' +
                                                        'bold italic forecolor underline | alignleft aligncenter ' +
                                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                                        'removeformat | help',
                                                    forced_root_block : false,
                                                    force_br_newlines : true,
                                                    force_p_newlines : false,
                                                }}
                                                onEditorChange={(value, editor) => {
                                                    let temp = textarea;
                                                    temp[17] = value;
                                                    setTextarea(temp);
                                                }}/>
                                        </div>
                                        <div className="flexFormColCenter" id="uploadZone">
                                            <label className="formFont" htmlFor="picture17">Photo du membre 17</label>
                                            <input
                                                type="file"
                                                id="photo"
                                                name="picture17"
                                                ref={selectedFile}
                                                onChange={(e) => handleFileChange(e)}/>
                                        </div>
                                    </div>
                                    <div className="width100">
                                        <div className='postTitle flexFormColCenter'>
                                            <label className="formFont" htmlFor="firstname1">Prénom du membre 18</label>
                                            <div>
                                                <input
                                                    type='text'
                                                    name='firstname18'
                                                    className="postTitle"
                                                    onChange={(e) => handleFieldChange(e)}/>
                                            </div>
                                        </div>
                                        <div className='postTitle flexFormColCenter'>
                                            <label className="formFont" htmlFor="lastname1">Nom du membre 18</label>
                                            <div>
                                                <input
                                                    type='text'
                                                    name='lastname18'
                                                    className="postTitle"
                                                    onChange={(e) => handleFieldChange(e)}/>
                                            </div>
                                        </div>
                                        <div className="flexFormColCenter" id="postTextarea">
                                            <label className="formFont" htmlFor="textarea18">Description du membre 18</label>
                                            <Editor
                                                name="textarea18"
                                                onInit={(evt, editor) => editorRef.current = editor}
                                                value={textarea[18]}
                                                apiKey='yaysfwznq8jhd2wmq7ovzetookk8gjqqh4yls46m1fp7c8z3'
                                                init={{
                                                    height: 200,
                                                    menubar: false,
                                                    plugins: [
                                                        'advlist autolink lists link image charmap print preview anchor',
                                                        'searchreplace visualblocks code fullscreen',
                                                        'insertdatetime media table paste code help wordcount'
                                                    ],
                                                    toolbar: 'undo redo | formatselect | ' +
                                                        'bold italic forecolor underline | alignleft aligncenter ' +
                                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                                        'removeformat | help',
                                                    forced_root_block : false,
                                                    force_br_newlines : true,
                                                    force_p_newlines : false,
                                                }}
                                                onEditorChange={(value, editor) => {
                                                    let temp = textarea;
                                                    temp[18] = value;
                                                    setTextarea(temp);
                                                }}/>
                                        </div>
                                        <div className="flexFormColCenter" id="uploadZone">
                                            <label className="formFont" htmlFor="picture18">Photo du membre 18</label>
                                            <input
                                                type="file"
                                                id="photo"
                                                name="picture18"
                                                ref={selectedFile}
                                                onChange={(e) => handleFileChange(e)}/>
                                        </div>
                                    </div>
                                    <div className="width100">
                                        <div className='postTitle flexFormColCenter'>
                                            <label className="formFont" htmlFor="firstname19">Prénom du membre 19</label>
                                            <div>
                                                <input
                                                    type='text'
                                                    name='firstname19'
                                                    className="postTitle"
                                                    onChange={(e) => handleFieldChange(e)}/>
                                            </div>
                                        </div>
                                        <div className='postTitle flexFormColCenter'>
                                            <label className="formFont" htmlFor="lastname19">Nom du membre 19</label>
                                            <div>
                                                <input
                                                    type='text'
                                                    name='lastname19'
                                                    className="postTitle"
                                                    onChange={(e) => handleFieldChange(e)}/>
                                            </div>
                                        </div>
                                        <div className="flexFormColCenter postTextarea">
                                            <label className="formFont" htmlFor="textarea1">Description du membre 19</label>
                                            <Editor
                                                name="textarea19"
                                                onInit={(evt, editor) => editorRef.current = editor}
                                                value={textarea[19]}
                                                apiKey='yaysfwznq8jhd2wmq7ovzetookk8gjqqh4yls46m1fp7c8z3'
                                                init={{
                                                    height: 200,
                                                    menubar: false,
                                                    plugins: [
                                                        'advlist autolink lists link image charmap print preview anchor',
                                                        'searchreplace visualblocks code fullscreen',
                                                        'insertdatetime media table paste code help wordcount'
                                                    ],
                                                    toolbar: 'undo redo | formatselect | ' +
                                                        'bold italic forecolor underline | alignleft aligncenter ' +
                                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                                        'removeformat | help',
                                                    forced_root_block : false,
                                                    force_br_newlines : true,
                                                    force_p_newlines : false,
                                                }}
                                                onEditorChange={(value, editor) => {
                                                    let temp = textarea;
                                                    temp[19] = value;
                                                    setTextarea(temp);
                                                }}/>
                                        </div>
                                        <div className="flexFormColCenter" id="uploadZone">
                                            <label className="formFont" htmlFor="picture19">Photo du membre 19</label>
                                            <input
                                                type="file"
                                                id="photo"
                                                name="picture19"
                                                ref={selectedFile}
                                                onChange={(e) => handleFileChange(e)}/>
                                        </div>
                                    </div>
                                    <div className="width100">
                                        <div className='postTitle flexFormColCenter'>
                                            <label className="formFont" htmlFor="firstname20">Prénom du membre 20</label>
                                            <div>
                                                <input
                                                    type='text'
                                                    name='firstname20'
                                                    className="postTitle"
                                                    onChange={(e) => handleFieldChange(e)}/>
                                            </div>
                                        </div>
                                        <div className='postTitle flexFormColCenter'>
                                            <label className="formFont" htmlFor="lastname1">Nom du membre 20</label>
                                            <div>
                                                <input
                                                    type='text'
                                                    name='lastname20'
                                                    className="postTitle"
                                                    onChange={(e) => handleFieldChange(e)}/>
                                            </div>
                                        </div>
                                        <div className="flexFormColCenter postTextarea">
                                            <label className="formFont" htmlFor="textarea1">Description du membre 20</label>
                                            <Editor
                                                name="textarea1"
                                                onInit={(evt, editor) => editorRef.current = editor}
                                                value={textarea[20]}
                                                apiKey='yaysfwznq8jhd2wmq7ovzetookk8gjqqh4yls46m1fp7c8z3'
                                                init={{
                                                    height: 200,
                                                    menubar: false,
                                                    plugins: [
                                                        'advlist autolink lists link image charmap print preview anchor',
                                                        'searchreplace visualblocks code fullscreen',
                                                        'insertdatetime media table paste code help wordcount'
                                                    ],
                                                    toolbar: 'undo redo | formatselect | ' +
                                                        'bold italic forecolor underline | alignleft aligncenter ' +
                                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                                        'removeformat | help',
                                                    forced_root_block : false,
                                                    force_br_newlines : true,
                                                    force_p_newlines : false,
                                                }}
                                                onEditorChange={(value, editor) => {
                                                    let temp = textarea;
                                                    temp[20] = value;
                                                    setTextarea(temp);
                                                }}/>
                                        </div>
                                        <div className="flexFormColCenter" id="uploadZone">
                                            <label className="formFont" htmlFor="picture20">Photo du membre 20</label>
                                            <input
                                                type="file"
                                                id="photo"
                                                name="picture20"
                                                ref={selectedFile}
                                                onChange={(e) => handleFileChange(e)}/>
                                        </div>
                                    </div>

                                    <button type="submit" className="button buttonSize">Envoyer</button>

                                    <div className="flexFormColCenter flexFormColCenter" style={{visibility: messageStyle}}>
                                        <label className="messageError">Message: {updateError}</label>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </section>
                    <FloatFooter/>
                </Fragment>
            );
        }
        else
        {
            return (
                <Fragment>
                    <NavBar/>
                    <section className="sectionNDM sectionAdmin section100">
                        <div className="containerNDM containerAdmin margTop">
                            Vous n'êtes pas autorisé à afficher cette page
                        </div>
                    </section>
                    <FloatFooter/>
                </Fragment>);
        }
    }
}

export default CreateBoard