const ChildHeight = (parent) =>
{
    const descendents = parent.getElementsByTagName('*');
    let descendentsHeight = 0;

    for (let i = 0; i < descendents.length; i++)
    {
        let eDescendents = descendents[i].getElementsByTagName('*');
        if (eDescendents.length === 0)
        {
            let style = getComputedStyle(descendents[i]);
            descendentsHeight = descendentsHeight + descendents[i].getBoundingClientRect().height + parseInt(style.marginTop) + parseInt(style.marginBottom);
        }
    }

    return descendentsHeight;
}

export default ChildHeight;