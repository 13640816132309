import React, {Fragment, useState} from "react";
import SubstrPost from "../Commun/SubstrPost";
import {useHistory} from "react-router";
import RequireAttachement from "../Commun/RequireAttachement";
import {useCookies} from "react-cookie";

const TableReportCols = props =>
{
    let rows = [];
    const history = useHistory();
    const [cookies] = useCookies();
    const [updateError, setUpdateError] = useState(null);
    const [messageStyle, setMessageStyle] = useState("hidden");

    const submitEdition = (event, id) =>
    {
        event.preventDefault();
        history.push('/ndmback/modifier-rapport/' + id);

    }

    props.dataItems.forEach(function (fd)
    {
        let idReport = fd.id;

        const buttonEditon = <button onClick={(e) => submitEdition(e, idReport)} className="button buttonSize" type="submit">Editer</button>;

        rows.push(
            <tr className="table2" key={fd.id}>
                <th className="thBackground">Titre</th>
                <td>{fd.title}</td>
                <th>Contenu</th>
                <td><SubstrPost contentPost={fd.content} subNumber="400"/></td>
                <th>Classe</th>
                <td>{fd.class}</td>
                <th>Trimestre</th>
                <td>{fd.term}</td>
                <th>Année</th>
                <td>{fd.year}</td>
                <th>Pièce-jointe</th>
                <td><RequireAttachement srcAttachement={fd.attachement} classnameAttachement="imgTablePosts" repAttachement="post"/></td>
                <th>Edition</th>
                <td>{buttonEditon}</td>
            </tr>
        )
    });

    return (
        <Fragment>
            <tbody className="tbodyflex">{rows}</tbody>
            <div className="flexFormColCenter flexFormColCenter" style={{visibility: messageStyle}}>
                <label className="messageError">Message: {updateError}</label>
            </div>
        </Fragment>
    );
}

export default TableReportCols