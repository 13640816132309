import React, {Fragment, useEffect, useState} from "react";
import NavBar from "../Commun/NavBar";
import {useCookies} from "react-cookie";
import FloatFooter from "../Commun/FloatFooter";
const {REACT_APP_API_URL} = process.env;

const PaiementSuccess = () =>
{
    const [information, setInformation] = useState('');
    const [useOnce, setUseOnce] = useState(0);
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [cookies] = useCookies(['reservationId']);

    useEffect(() =>
    {
        if (useOnce === 0)
        {
            const reservationIdCookie = cookies['reservationId'];
            let request = REACT_APP_API_URL + "payment-success/reservation?reservationId=" + encodeURIComponent(reservationIdCookie);

            const optionsAPI =
                {
                    method: 'GET'
                };

            fetch(request, optionsAPI)
                .then((res) => {
                    if(res.status === 201)
                    {
                        setInformation('Le paiement a été effectuée');
                    }
                    if(res.status === 204)
                    {
                        setInformation('Le paiement de la réservation a déjà été effectuée');
                    }
                })
                .then(
                    (res) => {
                        setIsLoaded(true);
                    },
                    (error) => { setError(error); }
                )

            setUseOnce(1);
        }
    })

    if (error)
    {
        return <div>Error: {error.message}</div>;
    }
    else if (!isLoaded)
    {
        return (
            <Fragment>
                <NavBar/>
                <section className="sectionNDM sectionCommissions section100">
                    <div className="containerNDM containerCommissions margTop">
                        En attente
                    </div>
                </section>
                <FloatFooter/>
            </Fragment>);
    }
    else
    {
        return (
            <Fragment>
                <NavBar/>
                <section className="sectionNDM sectionCommissions section100">
                    <div className="containerNDM containerCommissions margTop">
                        {information}
                    </div>
                </section>
                <FloatFooter/>
            </Fragment>)
    }
}

export default PaiementSuccess