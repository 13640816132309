import React, {Fragment, useEffect, useState} from 'react'
import NavBar from "../Commun/NavBar";
import FloatFooter from "../Commun/FloatFooter";
import { useParams } from 'react-router-dom';
import { useHistory } from "react-router";
import {useCookies} from "react-cookie";

const { REACT_APP_API_URL } = process.env;

const EditRole = () =>
{
    const { id } = useParams()
    const [cookies] = useCookies();
    const history = useHistory();

    const [useOnce1, setUseOnce1] = useState(0);
    const [useOnce2, setUseOnce2] = useState(0);
    const [items1, setItems1] = useState([]);
    const [isLoaded1, setIsLoaded1] = useState(false);
    const [error1, setError1] = useState(null);
    const [, setItems2] = useState([]);
    const [isLoaded2, setIsLoaded2] = useState(false);
    const [error2, setError2] = useState(null);
    const [updateError, setUpdateError] = useState(null);
    const [messageStyle, setMessageStyle] = useState("hidden");

    const [memberId, setMemberId] = useState(0);
    const [lastname, setLastname] = useState('');
    const [firstname, setFirstname] = useState('');
    const [pseudo, setPseudo] = useState('');
    const [roles, setRoles] = useState([]);

    useEffect(() =>
    {
        if (useOnce1 === 0)
        {
            const tokenMember = cookies['ndmToken'];

            if (tokenMember)
            {
                let request = REACT_APP_API_URL + "member/get-autorisation/" + tokenMember;

                const optionsAPI =
                    {
                        method: 'GET'
                    };

                fetch(request, optionsAPI)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            setIsLoaded1(true);
                            setItems1(result);
                        },

                        (error) => {
                            setIsLoaded1(true);
                            setError1(error);
                        }
                    )
            }
            setUseOnce1(1);
        }

        if (useOnce2 === 0)
        {
            let request = REACT_APP_API_URL + "member/edit/" + id;

            const optionsAPI =
                {
                    method: 'GET'
                };

            fetch(request, optionsAPI)
                .then(res => res.json())
                .then(
                    (result) => {
                        setMemberId(id);
                        setIsLoaded2(true);
                        setItems2(result);
                        setPseudo(result[0].pseudo);
                        setFirstname(result[0].firstname);
                        setLastname(result[0].lastname);
                        setRoles(result[0].roles);
                    },

                    (error) => {
                        setIsLoaded2(true);
                        setError2(error);
                    }
                )

            setUseOnce2(1);
        }
    })

    const handleSubmit = (event, id, droit, role) =>
    {
        event.preventDefault();

        const memberToken = cookies['ndmToken'];
        const { REACT_APP_API_URL } = process.env;

        let request = REACT_APP_API_URL + "update-role";
        let selectFileData = new FormData();

        selectFileData.append('memberId', id);
        selectFileData.append('droit', droit);
        selectFileData.append('role', role);
        selectFileData.append('memberToken', memberToken);

        const options =
            {
                method: 'POST',
                body: selectFileData,
            };
        fetch(request, options)
            .then(
                (result) => {
                    history.push('/ndmback/gerer-roles');
                },

                (error) => {
                    setMessageStyle("visible")
                    setUpdateError(error);
                }
            )
    }

    if (error2 || error1)
    {
        return <div>Error: {error2.message}</div>;
    }
    else if (!isLoaded2 || !isLoaded1)
    {
        return (
            <Fragment>
                <NavBar/>
                <section className="sectionNDM sectionAdmin section100">
                    <div className="containerNDM containerAdmin margTop">
                        En attente
                    </div>
                </section>
                <FloatFooter/>
            </Fragment>);
    }
    else
    {
        if (items1.roles.includes('ADMINISTRATOR'))
        {
            let mem = ""
            if (roles.includes('MEMBER'))
            {
                mem = <button onClick={(e) => handleSubmit(e, memberId, "N", 'MEMBER')} className="button buttonMember" type="submit">Supprimer droit membre</button>;
            }
            else
            {
                mem = <button onClick={(e) => handleSubmit(e, memberId, "O", 'MEMBER')} className="button buttonMember" type="submit">Accorder droit membre</button>;
            }

            let edi = ""
            if (roles.includes('EDITOR'))
            {
                edi = <button onClick={(e) => handleSubmit(e, memberId, "N", 'EDITOR')} className="button buttonMember" type="submit">Supprimer droit editeur</button>;
            }
            else
            {
                edi = <button onClick={(e) => handleSubmit(e, memberId, "O", 'EDITOR')} className="button buttonMember" type="submit">Accorder droit editeur</button>;
            }

            let adm = ""
            if (roles.includes('ADMINISTRATOR'))
            {
                adm = <button onClick={(e) => handleSubmit(e, memberId, "N", 'ADMINISTRATOR')} className="button buttonMember" type="submit">Supprimer droit administeur</button>;
            }
            else
            {
                adm = <button onClick={(e) => handleSubmit(e, memberId, "O", 'ADMINISTRATOR')} className="button buttonMember" type="submit">Accorder droit administeur</button>;
            }

            return (
                <Fragment>
                    <NavBar/>
                    <section className="sectionNDM sectionAdmin section100">
                        <div className="containerNDM containerAdmin margTop">
                            <div id="containerBackground">
                                <h1>Accorder ou supprimer des droits à un membre</h1>
                                <form onSubmit={handleSubmit} id="formBackendView" encType="multipart/form-data" className="formFlex" action="">
                                    <label className="formFont" htmlFor="category1">{pseudo} - {firstname} - {lastname}</label>

                                    {mem}
                                    {edi}
                                    {adm}

                                    <div className="flexFormColCenter flexFormColCenter" style={{visibility: messageStyle}}>
                                        <label className="formFont" htmlFor="eventSeatingPlan">Message: </label><div>{updateError}</div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </section>
                    <FloatFooter/>
                </Fragment>
            );
        }
        else
        {
            return (
                <Fragment>
                    <NavBar/>
                    <section className="sectionNDM sectionAdmin section100">
                        <div className="containerNDM containerAdmin margTop">
                            Vous n'êtes pas autorisé à afficher cette page
                        </div>
                    </section>
                    <FloatFooter/>
                </Fragment>);
        }
    }
}

export default EditRole