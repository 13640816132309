import React, { Fragment } from 'react'
import NavBar from "../Commun/NavBar";
import FloatFooter from "../Commun/FloatFooter";
import Post from "../Commun/Post";

const Investissements = () =>
{
    return (
        <Fragment>
            <NavBar />
            <section className="sectionNDM sectionAssociation section100">
                <div className="containerNDM containerCommissions margTop">
                    <Post index="2" name="investissements" quantity="3"/>
                </div>
            </section>
            <FloatFooter />
        </Fragment>
    );
}

export default Investissements