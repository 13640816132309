import React, {Fragment, useEffect, useState} from 'react'
import NavBar from "../Commun/NavBar";
import FloatFooter from "../Commun/FloatFooter";
import { useParams } from 'react-router-dom';
import { useHistory } from "react-router";
import CheckPostField from "../Commun/CheckPostField";
import {useCookies} from "react-cookie";

const { REACT_APP_API_URL } = process.env;

const EditBoard = () =>
{
    const { id } = useParams()
    const [cookies] = useCookies();
    const history = useHistory();

    const [useOnce1, setUseOnce1] = useState(0);
    const [useOnce2, setUseOnce2] = useState(0);
    const [items1, setItems1] = useState([]);
    const [isLoaded1, setIsLoaded1] = useState(false);
    const [error1, setError1] = useState(null);
    const [, setItems2] = useState([]);
    const [isLoaded2, setIsLoaded2] = useState(false);
    const [error2, setError2] = useState(null);
    const [updateError, setUpdateError] = useState(null);
    const [messageStyle, setMessageStyle] = useState("hidden");
    const [categoryId, setCategoryId] = useState(0);
    const [category, setCategory] = useState('');
    const [subCategory, setSubCategory] = useState('');
    const [sub2Category, setSub2Category] = useState('');

    useEffect(() =>
    {
        if (useOnce1 === 0)
        {
            const tokenMember = cookies['ndmToken'];

            if (tokenMember)
            {
                let request = REACT_APP_API_URL + "member/get-autorisation/" + tokenMember;

                const optionsAPI =
                    {
                        method: 'GET'
                    };

                fetch(request, optionsAPI)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            setIsLoaded1(true);
                            setItems1(result);
                        },

                        (error) => {
                            setIsLoaded1(true);
                            setError1(error);
                        }
                    )
            }
            setUseOnce1(1);
        }
    });

    useEffect(() =>
    {
        if (useOnce2 === 0)
        {
            let request = REACT_APP_API_URL + "category/edit/" + id;

            const optionsAPI =
                {
                    method: 'GET'
                };

            fetch(request, optionsAPI)
                .then(res => res.json())
                .then(
                    (result) => {
                        setCategoryId(id);
                        setIsLoaded2(true);
                        setItems2(result);
                        setCategory(result[0].categoryName);
                        setSubCategory(result[0].subCategoryName);
                        setSub2Category(result[0].sub2CategoryName);
                    },

                    (error) => {
                        setIsLoaded2(true);
                        setError2(error);
                    }
                )

            setUseOnce2(1);
        }
    })

    const handleSubmit = event =>
    {
        event.preventDefault();

        let formStatus = 1;
        if (category=== '') { alert("La catégorie n'est pas défini"); formStatus = 0 }
        if (subCategory === '') { alert("La sous-catégorie de niveau 1 n'est pas défini"); formStatus = 0 }
        if (sub2Category === '') { alert("La sous-catégorie de niveau 2 n'est pas définie"); formStatus = 0 }

        if (formStatus === 1)
        {
            const memberToken = cookies['ndmToken'];
            const { REACT_APP_API_URL } = process.env;

            let request = REACT_APP_API_URL + "update-category";
            let selectFileData = new FormData();

            selectFileData.append('categoryId', categoryId);
            selectFileData.append('category', category);
            selectFileData.append('subCategory', subCategory);
            selectFileData.append('sub2Category', sub2Category);
            selectFileData.append('memberToken', memberToken);

            const options =
                {
                    method: 'POST',
                    body: selectFileData,
                };
            fetch(request, options)
                .then(
                    (result) => {
                        setUpdateError('');
                        history.push('/ndmback');
                    },

                    (error) => {
                        setMessageStyle("visible")
                        setUpdateError(error);
                    }
                )
        }
    }

    const handleFieldChange = event =>
    {
        event.preventDefault();

        const { name, value } = event.target;

        if (name === 'category1')
        {
            const categoryCheck = CheckPostField(name, value);
            if (categoryCheck === 0)
            {
                setCategory(value);
            }
        }

        if (name === 'category2')
        {
            const subCategoryCheck = CheckPostField(name, value);
            if (subCategoryCheck === 0)
            {
                setSubCategory(value);
            }
        }

        if (name === 'category3')
        {
            const sub2CategoryCheck = CheckPostField(name, value);
            if (sub2CategoryCheck === 0)
            {
                setSub2Category(value);
            }
        }
    }

    if (error2 || error1)
    {
        return <div>Error: {error2.message}</div>;
    }
    else if (!isLoaded2 || !isLoaded1)
    {
        return (
            <Fragment>
                <NavBar/>
                <section className="sectionNDM sectionAdmin section100">
                    <div className="containerNDM containerAdmin margTop">
                        En attente
                    </div>
                </section>
                <FloatFooter/>
            </Fragment>);
    }
    else {
        if (items1.status === 0 && (items1.roles.includes('EDITOR') || items1.roles.includes('ADMINISTRATOR')))
        {
            return (
                <Fragment>
                    <NavBar/>
                    <section className="sectionNDM sectionAdmin section100">
                        <div className="containerNDM containerAdmin margTop">
                            <div id="containerBackground">
                                <h1>Modifier une catégorie d'article</h1>
                                <form onSubmit={handleSubmit} id="formBackendView" encType="multipart/form-data" className="formFlex" action="">
                                    <div className='postTitle flexFormColCenter'>
                                        <label className="formFont" htmlFor="category1">Catégorie</label>
                                        <input
                                            type='text'
                                            name='category1'
                                            className="postTitle"
                                            value={category}
                                            onChange={(e) => handleFieldChange(e)}
                                        />
                                    </div>
                                    <div className='postTitle flexFormColCenter'>
                                        <label className="formFont" htmlFor="category2">Sous-catégorie niveau 1</label>
                                        <input
                                            type='text'
                                            name='category2'
                                            className="postTitle"
                                            value={subCategory}
                                            onChange={(e) => handleFieldChange(e)}
                                        />
                                    </div>
                                    <div className='postTitle flexFormColCenter'>
                                        <label className="formFont" htmlFor="category3">Sous-catégorie niveau 2</label>
                                        <input
                                            type='text'
                                            name='category3'
                                            className="postTitle"
                                            value={sub2Category}
                                            onChange={(e) => handleFieldChange(e)}
                                        />
                                    </div>

                                    <button type="submit" className="button buttonSize">Envoyer</button>

                                    <div className="flexFormColCenter flexFormColCenter" style={{visibility: messageStyle}}>
                                        <label className="formFont" htmlFor="eventSeatingPlan">Message: </label><div>{updateError}</div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </section>
                    <FloatFooter/>
                </Fragment>
            );
        }
        else
        {
            return (
                <Fragment>
                    <NavBar/>
                    <section className="sectionNDM sectionAdmin section100">
                        <div className="containerNDM containerAdmin margTop">
                            Vous n'êtes pas autorisé à afficher cette page
                        </div>
                    </section>
                    <FloatFooter/>
                </Fragment>);
        }
    }
}

export default EditBoard