import React, {Fragment} from "react";
import NavBar from "./NavBar";
import FloatFooter from "./FloatFooter";

const Waiting = () =>
{
    return(
        <Fragment>
            <NavBar/>
            <section className="sectionNDM sectionCommissions section100">
                <div className="containerNDM containerAdmin margTop">En attente</div>
            </section>
            <FloatFooter/>
        </Fragment>
    );
}

export default Waiting