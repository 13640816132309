import React, { Fragment } from 'react'
import NavBar from "../Commun/NavBar";
import Post from "../Commun/Post";
import FloatFooter from "../Commun/FloatFooter";

const CommissionCommunication = () =>
{
    return (
        <Fragment>
            <NavBar/>
            <section className="sectionNDM sectionCommissions section100">
                <div className="containerNDM containerCommissions margTop">
                    <div className="presentationFetes">
                        La commission Communication est chargée d’animer et de réaliser tout au long de l’année les outils de communication de l’Apel pour communiquer auprès des parents et rendre les actions de l’Apel et de l’établissement visibles au plus grand nombre.
                        <li className="liFetes">Elle rédige le e-journal « Parentillages » avec toutes les actualités et la vie de l’école. Depuis 2021, ces actus sont dématérialisées via un lien adressé à tous les parents.</li>
                        <li className="liFetes">Elle réalise et met à jour le site internet apelndm.fr</li>
                        <li className="liFetes">Elle réalise des films, des affiches, des invitations lors des événements de Notre-Dame des Missions.</li>
                        <li className="liFetes">Elle organise avec les enseignants et les élèves des concours d’affiches ou d’illustrations pour le Parentillages</li>
                        <li className="liFetes">Elle communique avec toutes les commissions de l’Apel NDM ainsi qu’avec la direction de l’établissement</li>
                    </div>
                    <Post index="2" name="commission-communication" quantity="10"/>
                </div>
            </section>
            <FloatFooter/>
        </Fragment>
    );
}

export default CommissionCommunication