import React, {Fragment, useState} from "react";
import SubstrPost from "../Commun/SubstrPost";
import RequireImage from "../Commun/RequireImage";
import {useHistory} from "react-router";
import RequireAttachement from "../Commun/RequireAttachement";
import {useCookies} from "react-cookie";

const TablePostsCols = props =>
{
    let rows = [];
    const history = useHistory();
    const [cookies] = useCookies();
    const [updateError, setUpdateError] = useState(null);
    const [messageStyle, setMessageStyle] = useState("hidden");

    const submitVisibility = (event, id, action) =>
    {
        event.preventDefault();

        const memberToken = cookies['ndmToken'];
        const { REACT_APP_API_URL } = process.env;
        let request = REACT_APP_API_URL + "modify-visibility";

        let selectFileData = new FormData();
        selectFileData.append('id', id);
        selectFileData.append('action', action);
        selectFileData.append('memberToken', memberToken);

        const options =
            {
                method: 'POST',
                body: selectFileData,
            };
        fetch(request, options)
            .then(
                (result) =>
                {
                    history.go(0);
                },

                (error) =>
                {
                    setMessageStyle("visible")
                    setUpdateError(error);
                }
            )
    }

    const submitEdition = (event, id) =>
    {
        event.preventDefault();
        history.push('/ndmback/modifier-article/' + id);

    }

    props.dataItems.forEach(function (fd)
    {
        let messageVisibility = "";
        let buttonVisibility = "";
        let idPost = fd.id;

        if (fd.visibility === 1)
        {
            messageVisibility = "Publié";
            buttonVisibility = <button onClick={(e) => submitVisibility(e, idPost, 0)} className="button buttonSize" type="submit">Occulter</button>;
        }
        else
        {
            messageVisibility = "Brouillon";
            buttonVisibility = <button onClick={(e) => submitVisibility(e, idPost, 1)} className="button buttonSize" type="submit">Publier</button>;
        }

        const buttonEditon = <button onClick={(e) => submitEdition(e, idPost)} className="button buttonSize" type="submit">Editer</button>;

        rows.push(
            <tr className="table2" key={fd.slug}>
                <th className="thBackground">Titre</th>
                <td>{fd.title}</td>
                <th>Contenu</th>
                <td><SubstrPost contentPost={fd.content} subNumber="400"/></td>
                <th>Catégorie</th>
                <td>{fd.category_name}</td>
                <th>Image</th>
                <td className="tdTextAl"><RequireImage srcImage={fd.image} classnameImage="imgTablePosts" repImage="post"/></td>
                <th>Pièce-jointe</th>
                <td><RequireAttachement srcAttachement={fd.attachement} classnameAttachement="imgTablePosts" repAttachement="post"/></td>
                <th>Etat</th>
                <td>{messageVisibility}</td>
                <th>Publication</th>
                <td>{buttonVisibility}</td>
                <th>Edition</th>
                <td>{buttonEditon}</td>
            </tr>
        )
    });

    return (
        <Fragment>
            <tbody className="tbodyflex">{rows}</tbody>
            <div className="flexFormColCenter flexFormColCenter" style={{visibility: messageStyle}}>
                <label className="messageError">Message: {updateError}</label>
            </div>
        </Fragment>
    );
}

export default TablePostsCols