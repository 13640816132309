import React, { useState, Fragment } from "react";
import {Document, Page, pdfjs} from "react-pdf";
import NavBar from "./NavBar";
import FloatFooter from "./FloatFooter";
import {useParams} from "react-router";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const DisplayPDF = (props) =>
{
    const [numPages, setNumPages] = useState(null);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const { pdfName } = useParams();
    const pdf = require(`../../attachement/post/` + pdfName).default;
    /*const pdf = "https://www.imopsis.fr/projet5/front/src/attachement/post/" + pdfName;*/

    return (
        <Fragment>
            <NavBar/>
            <section className="sectionNDM sectionCommissions section100">
                <div className="containerNDM containerCommissions margTop">
                    <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
                        {Array.from(new Array(numPages), (el, index) => (
                            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                        ))}
                    </Document>
                </div>
            </section>
            <FloatFooter/>
        </Fragment>
    );
}

export default DisplayPDF