import React from 'react'

const ChangeDate = props =>
{
    const dateChanger = (dateToChange) =>
    {
        let dateChanged = "";
        const dateSplit = dateToChange.split(/[- : T]/);

        let dateMonth = "";

        switch(dateSplit[1]) {
            case '01': dateMonth = "janvier"; break;
            case '02': dateMonth = "février"; break;
            case '03': dateMonth = "mars"; break;
            case '04': dateMonth = "avril"; break;
            case '05': dateMonth = "mai"; break;
            case '06': dateMonth = "juin"; break;
            case '07': dateMonth = "juillet"; break;
            case '08': dateMonth = "août"; break;
            case '09': dateMonth = "septembre"; break;
            case '10': dateMonth = "octobre"; break;
            case '11': dateMonth = "novembre"; break;
            case '12': dateMonth = "décembre"; break;
            default: dateMonth = ""; break;
        }

        if (props.typeDate === "Event")
        {
            dateChanged = dateSplit[2] + " " + dateMonth + " " + dateSplit[0];
        }
        else
        {
            dateChanged = ", le " + dateSplit[2] + " " + dateMonth + " " + dateSplit[0] + " à " + dateSplit[3] + "h" + dateSplit[4] + "min";
        }

        return <span>{dateChanged}</span>;
    }

    return (dateChanger(props.dateToChange))

}

export default ChangeDate