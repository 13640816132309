import React, {Fragment, useState} from 'react'
import EmailValidation from "./Commun/EmailValidation";
import NavBar from "./Commun/NavBar";
import FloatFooter from "./Commun/FloatFooter";
import {useCookies} from "react-cookie";

const { REACT_APP_API_URL } = process.env;

const Contact = () =>
{
    const [firstnameContact, setFirstnameContact] = useState("");
    const [lastnameContact, setLastnameContact] = useState("");
    const [phoneContact, setPhoneContact] = useState("");
    const [emailContact, setEmailContact] = useState("");
    const [textareaContact, setTextareaContact] = useState("");
    const [messageForm, setMessageForm] = useState(null);
    const [cookies] = useCookies();
    const [errors, setErrors] = useState({ firstnameContact: '', lastnameContact: '', phoneContact: '', emailContact: '', textareaContact: ''});
    const [messageStyle, setMessageStyle] = useState("hidden");

    useState( () => { });

    const handleFileChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;

        switch (name)
        {
            case 'firstnameContact':
                setErrors['firstnameContact'] = value.length < 1 ? 'Full Name must be at least 1 characters long!' : '';
                setFirstnameContact(value);
                break;
            case 'textareaContact':
                setErrors['textareaContact'] = value.length < 1 ? 'Full Name must be at least 1 characters long!' : '';
                console.log('value:' + value);
                setTextareaContact(value);
                break;
            case 'lastnameContact':
                setErrors['lastnameContact'] = value.length < 1 ? 'Full Name must be at least 1 characters long!' : '';
                setLastnameContact(value);
                break;
            case 'phoneContact':
                setErrors['phoneContact'] = value.length < 10 ? 'Full Name must be at least 10 characters long!' : '';
                setPhoneContact(value);
                break;
            case 'emailContact':
                setErrors['emailContact'] = EmailValidation.test(value) ? '' : 'Email is not valid!';
                setEmailContact(value);
                break;
            default:
                break;
        }
    }

    const handleSubmit = (event) =>
    {
        event.preventDefault();

        let formStatus = 1;
        if (firstnameContact === '') { setMessageForm("Le champ nom n'est pas rempli"); formStatus = 0 }
        if (lastnameContact === '') { setMessageForm("Le champ prénom n'est pas rempli"); formStatus = 0 }
        if (phoneContact === '') { setMessageForm("Le champ téléphone n'est pas rempli"); formStatus = 0 }
        if (emailContact === '') { setMessageForm("Le champ email n'est pas rempli"); formStatus = 0 }
        if (textareaContact === '') { setMessageForm("Le champ message n'est pas rempli"); formStatus = 0 }

        if (formStatus === 1)
        {
            const memberToken = cookies['ndmToken'];

            let request = REACT_APP_API_URL + "contact-association";
            let selectFileData = new FormData();

            selectFileData.append('firstnameContact', firstnameContact);
            selectFileData.append('memberToken', memberToken);
            selectFileData.append('lastnameContact', lastnameContact);
            selectFileData.append('phoneContact', phoneContact);
            selectFileData.append('emailContact', emailContact);
            selectFileData.append('textareaContact', textareaContact);
            selectFileData.append('template', '2091408');

            const options =
                {
                    method: 'POST',
                    body: selectFileData,
                };
            fetch(request, options)
                .then(
                    (result) =>
                    {
                        if (result.status === 200)
                        {
                            setFirstnameContact("");
                            setTextareaContact("");
                            setLastnameContact("");
                            setPhoneContact("");
                            setEmailContact("");
                            setMessageStyle("visible");
                            setMessageForm("Le message a bien été envoyé");
                        }
                        else
                        {
                            setMessageForm("Le message n'a pas été envoyé. Merci de contacter l'APEL");
                            setMessageStyle("visible")
                            formStatus = 0
                        }},
                )
        }
        else
        {
            setMessageStyle("visible");
        }
    }

    return(
        <Fragment>
            <NavBar/>
            <section className="sectionNDM sectionCommissions section100">
                <div className="containerNDM containerCommissions wrapper margTop">
                    <div className='form-wrapper'>
                        <h2>Contacter l'association</h2>
                        <form onSubmit={handleSubmit} noValidate>
                            <div className='firstnameContact'>
                                <label htmlFor="firstnameContact">Prénom</label>
                                <input type='text' name='firstnameContact' onChange={(e) => handleFileChange(e)}/>
                                {errors.firstnameContact.length > 0 &&
                                <span className='error'>{errors.firstnameContact}</span>}
                            </div>
                            <div className='lastnameContact'>
                                <label htmlFor="lastnameContact">Nom</label>
                                <input type='text' name='lastnameContact' onChange={(e) => handleFileChange(e)}/>
                                {errors.lastnameContact.length > 0 &&
                                <span className='error'>{errors.lastnameContact}</span>}
                            </div>
                            <div className='phoneContact'>
                                <label htmlFor="phoneContact">Téléphone (portable de préférence)</label>
                                <input type='text' name='phoneContact' onChange={(e) => handleFileChange(e)}/>
                                {errors.phoneContact.length > 0 &&
                                <span className='error'>{errors.phoneContact}</span>}
                            </div>
                            <div className='emailContact'>
                                <label htmlFor="emailContact">Email</label>
                                <input type='email' name='emailContact' onChange={(e) => handleFileChange(e)}/>
                                {errors.emailContact.length > 0 &&
                                <span className='error'>{errors.emailContact}</span>}
                            </div>
                            <div className='textareaContact'>
                                <label htmlFor="textareaContact">Message</label>
                                <textarea name='textareaContact' onChange={(e) => handleFileChange(e)}/>
                                {errors.textareaContact.length > 0 &&
                                <span className='error'>{errors.textareaContact}</span>}
                            </div>
                            <div className='submit'>
                                <button>Envoyer</button>
                            </div>
                        </form>
                        <div className="messageError" style={{visibility: messageStyle}}>Message: {messageForm}</div>
                    </div>
                </div>
            </section>
            <FloatFooter/>
        </Fragment>
    )
}

export default Contact