import React, {Fragment, useState} from "react";
import EmailValidation from "../Commun/EmailValidation";
import NavBar from "../Commun/NavBar";
import FloatFooter from "../Commun/FloatFooter";

const { REACT_APP_API_URL } = process.env;

const Benevolat = () =>
{
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [messageForm, setMessageForm] = useState(null);
    const [errors, setErrors] = useState({ firstname: '', lastname: '', phone: '', email: ''});
    const [messageStyle, setMessageStyle] = useState("hidden");

    const handleFileChange = (event) =>
    {
        event.preventDefault();
        const { name, value } = event.target;

        switch (name) {
            case 'firstname':
                setErrors['firstname'] = value.length < 1 ? 'Full Name must be at least 1 characters long!' : '';
                setFirstname(value);
                break;
            case 'lastname':
                setErrors['lastname'] = value.length < 1 ? 'Full Name must be at least 1 characters long!' : '';
                setLastname(value);
                break;
            case 'phone':
                setErrors['phone'] = value.length < 10 ? 'Full Name must be at least 10 characters long!' : '';
                setPhone(value);
                break;
            case 'email':
                setErrors['email'] = EmailValidation.test(value) ? '' : 'Email is not valid!';
                setEmail(value);
                break;
            default:
                break;
        }
    }

    const handleSubmit = (event) =>
    {
        event.preventDefault();

        let formStatus = 1;
        if (firstname === '') { setMessageForm("Le champ nom n'est pas rempli"); formStatus = 0 }
        if (lastname === '') { setMessageForm("Le champ prénom n'est pas rempli"); formStatus = 0 }
        if (phone === '') { setMessageForm("Le champ téléphone n'est pas rempli"); formStatus = 0 }
        if (email === '') { setMessageForm("Le champ email n'est pas rempli"); formStatus = 0 }

        if (formStatus === 1)
        {
            let request = REACT_APP_API_URL + "become-volunteer";
            let selectFileData = new FormData();

            selectFileData.append('firstname', firstname);
            selectFileData.append('lastname', lastname);
            selectFileData.append('phone', phone);
            selectFileData.append('email', email);

            const options =
                {
                    method: 'POST',
                    body: selectFileData,
                };

            fetch(request, options)
                .then(
                    (result) =>
                    {
                        setFirstname("");
                        setLastname("");
                        setEmail("");
                        setPhone("");
                        setMessageStyle("visible");
                        setMessageForm("Le mail a bien été envoyé");
                    },

                    (error) =>
                    {
                        setMessageStyle("visible")
                        setMessageForm(error.message);
                    }
                )
        }
    }

    return(
        <Fragment>
            <NavBar/>
            <section className="sectionNDM sectionCommissions section100">
                <div className="containerNDM containerCommissions wrapper margTop">
                    <div className="presentationFetes">
                        La commission fêtes est chargée de l’organisation de la fête de l'école. L'organisation de cet événement nécessite la participation de nombreuses personnes:
                        <br/>
                        <li className="liFetes">Les enseignants, le personnel de l'école et les directeurs de l'école</li>
                        <li className="liFetes">Les membres du conseil d'administration</li>
                        <li className="liFetes">Les membre de la commission des fêtes</li>
                        <li className="liFetes">Tout parent désireux d'apporter son aide</li>
                        <br/>
                        Dans cette démarche si vous souhaitez, à cette occasion, participer à l'organisation de cette fêtes, merci de remplir le formulaire ci-dessous :
                    </div>
                    <div className='form-wrapper'>
                        <h2>Se proposer en tant que bénévole pour la fête de l'école</h2>
                        <form onSubmit={handleSubmit} noValidate>
                            <div className='firstname'>
                                <label htmlFor="firstname">Prénom</label>
                                <input type='text' name='firstname' onChange={(e) => handleFileChange(e)}/>
                                {errors.firstname.length > 0 &&
                                <span className='error'>{errors.firstname}</span>}
                            </div>
                            <div className='lastname'>
                                <label htmlFor="lastname">Nom</label>
                                <input type='text' name='lastname' onChange={(e) => handleFileChange(e)}/>
                                {errors.lastname.length > 0 &&
                                <span className='error'>{errors.lastname}</span>}
                            </div>
                            <div className='phone'>
                                <label htmlFor="phone">Téléphone (portable de préférence)</label>
                                <input type='text' name='phone' onChange={(e) => handleFileChange(e)}/>
                                {errors.phone.length > 0 &&
                                <span className='error'>{errors.phone}</span>}
                            </div>
                            <div className='email'>
                                <label htmlFor="email">Email</label>
                                <input type='email' name='email' onChange={(e) => handleFileChange(e)}/>
                                {errors.email.length > 0 &&
                                <span className='error'>{errors.email}</span>}
                            </div>
                            <div className='submit'>
                                <button>Envoyer</button>
                            </div>
                        </form>
                        <div className="messageError" style={{visibility: messageStyle}}>Message: {messageForm}</div>
                    </div>
                </div>
            </section>
            <FloatFooter/>
        </Fragment>
    )
}

export default Benevolat