import React, {useEffect, useState, Fragment} from 'react'
import {useCookies} from "react-cookie";
import {useHistory} from "react-router";
const {REACT_APP_API_URL} = process.env;

const Event = props =>
{
    const [error, setError] = useState(null);
    const [errorForCart] = useState(null);
    const [items, setItems] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isLoadedForCart, setIsLoadedForCart] = useState(false);
    const [useOnce, setUseOnce] = useState(0);
    const [chargeDB, setChargeDB] = useState(0);
    const [cookies, setCookie] = useCookies(['connectedCookie', 'connectedId', 'items', 'userEvent']);
    const [,setConnected] = useState(0);
    const [reservationMessage, setReservationMessage] = useState('');
    const [userId, setUserId] = useState(0);
    const history = useHistory();

    useEffect(() =>
    {
        if (useOnce === 0)
        {
            if (chargeDB === 0)
            {
                setConnected(cookies.connectedCookie);
                setUserId(cookies.connectedId);

                let request = REACT_APP_API_URL + "event-table/" + props.id;

                fetch(request, {"method": "GET"})
                    .then(res => res.json())
                    .then(
                        (result) => {
                            setIsLoaded(true);
                            setItems(result);
                        },

                        (error) => {
                            setIsLoaded(true);
                            setError(error);
                        }
                    )
                setChargeDB(1);
            }
            setUseOnce(1);
        }
    })

    const goCart = () =>
    {
        const request = REACT_APP_API_URL + "event-table/" + props.id;

        fetch(request, {"method": "GET"})
            .then(res => res.json())
            .then(
                (result) => {
                    setItems(result);
                    setIsLoadedForCart(true);
                },

                (error) => {
                    setIsLoadedForCart(true);
                    setError(error);
                }
            )
    }

    if (errorForCart)
    {
        return <div>Error: {errorForCart.message}</div>;
    }
    else if (isLoadedForCart)
    {
        setIsLoadedForCart(false);
        let nbReservation = 0;
        let toComplete = 0;

        items.forEach(function (item, index)
        {
            if (item.s_catchedId == userId && item.s_catched === 1)
            {
                nbReservation = 1;

                if (item.s_first_name === '' || item.s_first_name === 'A compléter' || !item.s_first_name)
                {
                    items[index]['s_first_name'] = 'A compléter';
                    toComplete = 1;
                }

                if (item.s_lastname === '' || item.s_lastname === 'A compléter' || !item.s_lastname)
                {
                    items[index]['s_lastname'] = 'A compléter';
                    toComplete = 1;
                }
            }
            if (item.s_catched === 0 && (item.s_lastname === 'A compléter' || item.s_first_name === 'A compléter'))
            {
                items[index]['s_first_name'] = '';
                items[index]['s_lastname'] = '';
            }
        });

        if (toComplete === 1)
        {
            setReservationMessage('Veuillez compléter la réservation.');
            setUseOnce(0);
        }

        if (nbReservation === 0)
        {
            setReservationMessage('Veuillez réserver au moins une place.');
            setUseOnce(0);
        }

        if (toComplete === 0 && nbReservation > 0 && userId > 0)
        {
            setCookie('userEvent', props.id, {path: "/"});
            setReservationMessage('');
            history.push('/ma-reservation');
        }
    }

    const handleFieldChange = (event, id, index, rid) =>
    {
        const { name, value} = event.target;
        let request = '';
        let options = '';

        switch (name)
        {

            case 'firstname':
                items[index]['s_first_name'] = value;
                setItems(items);
                setUseOnce(0);
                break;

            case 'lastname':

                items[index]['s_lastname'] = value;
                setItems(items);
                setUseOnce(0);
                break;

            case 'checkReservation':

                request = REACT_APP_API_URL + "check-event";

                let catchedValue = null;
                let catchedIdValue = null;

                if (event.target.checked)
                {
                    catchedValue = 1;
                    catchedIdValue = userId;
                }
                else
                {
                    catchedValue = 0;
                    catchedIdValue = 0;
                }

                options =
                    {
                        method: 'POST',

                        body: JSON.stringify({
                            catched: catchedValue,
                            catchedId: catchedIdValue,
                            id: id,
                            reservationId: rid
                        })
                    };

                fetch(request, options)
                    .then(response => { console.log('OK'); });
                setUseOnce(0);
                break;

            default:
                break;
        }
    }

    const handleFieldFocusOut = (event, id, index, rid) =>
    {
        const { name, value} = event.target;
        let request = '';
        let options = '';

        switch (name)
        {
            case 'firstname':

                request = REACT_APP_API_URL + "firstname-event";
                options =
                    {
                        method: 'POST',

                        body: JSON.stringify({
                            firstname: value,
                            id: id
                        })
                    };

                fetch(request, options)
                    .then(response => { console.log('OK'); });
                setUseOnce(0);
                break;

            case 'lastname':

                request = REACT_APP_API_URL + "lastname-event";
                options =
                    {
                        method: 'POST',

                        body: JSON.stringify({
                            lastname: value,
                            id: id
                        })
                    };

                fetch(request, options)
                    .then(response => { console.log('OK'); });
                setUseOnce(0);
                break;

            default:
                break;
        }
    }

    if (error)
    {
        return <div>Error: {error.message}</div>;
    }
    else if (!isLoaded)
    {
        return <div>Loading...</div>;
    }
    else
    {
        let rows = [];
        items.forEach(function(item, index)
        {
            if (item.s_catched  === 2)
            {
                rows.push(
                    <Fragment>
                        <tr className="tableReservation" key={item.s_id}>
                            <td>{item.s_table_number}</td>
                            <td>{item.s_seat_number}</td>
                            <td>{item.s_seat_category}</td>
                            <td>{item.s_seat_price}</td>
                            <td>{item.s_first_name}</td>
                            <td>{item.s_lastname}</td>
                            <td></td>
                        </tr>
                        <hr/>
                    </Fragment>)
            }
            else if (item.s_catched  === 0)
            {
                rows.push(
                    <Fragment>
                        <tr className="tableReservation" key={item.s_id}>
                            <td>{item.s_table_number}</td>
                            <td>{item.s_seat_number}</td>
                            <td>{item.s_seat_category}</td>
                            <td>{item.s_seat_price}</td>
                            <td>
                                <input
                                    className="form-control input-sm inputReservation"
                                    type="text"
                                    name="firstname"
                                    value={item.s_first_name}
                                    onChange={(e) => handleFieldChange(e, item.s_id, index, item.r_id)}
                                    onBlur={(e) => handleFieldFocusOut(e, item.s_id, index, item.r_id)}>
                                </input>
                            </td>
                            <td>
                                <input
                                    className="form-control input-sm inputReservation"
                                    type="text"
                                    name="lastname"
                                    value={item.s_lastname}
                                    onChange={(e) => handleFieldChange(e, item.s_id, index, item.r_id)}
                                    onBlur={(e) => handleFieldFocusOut(e, item.s_id, index, item.r_id)}>
                                </input>
                            </td>
                            <td>
                                <input
                                    type="checkbox"
                                    name="checkReservation"
                                    onChange={(e) => handleFieldChange(e, item.s_id, index, item.r_id)} />
                            </td>
                        </tr>
                        <hr/>
                    </Fragment>)
            }
            else if (item.s_catchedId == userId)
            {
                rows.push(
                    <Fragment>
                        <tr className="tableReservation" key={item.s_id}>
                            <td>{item.s_table_number}</td>
                            <td>{item.s_seat_number}</td>
                            <td>{item.s_seat_category}</td>
                            <td>{item.s_seat_price}</td>
                            <td>
                                <input
                                    className="form-control input-sm inputReservation"
                                    type="text"
                                    value={item.s_first_name}
                                    name="firstname"
                                    onChange={(e) => handleFieldChange(e, item.s_id, index, item.r_id)}
                                    onBlur={(e) => handleFieldFocusOut(e, item.s_id, index, item.r_id)}>
                                </input>
                            </td>
                            <td>
                                <input
                                    className="form-control input-sm inputReservation"
                                    type="text"
                                    value={item.s_lastname}
                                    name="lastname"
                                    onChange={(e) => handleFieldChange(e, item.s_id, index, item.r_id)}
                                    onBlur={(e) => handleFieldFocusOut(e, item.s_id, index, item.r_id)}>
                                </input>
                            </td>
                            <td>
                                <input
                                    type="checkbox"
                                    name="checkReservation"
                                    onChange={(e) => handleFieldChange(e, item.s_id, index, item.r_id)}
                                    defaultChecked={true}
                                />
                            </td>
                        </tr>
                        <hr/>
                    </Fragment>)
            }
            else
            {
                rows.push(
                    <Fragment>
                        <tr className="tableReservation" key={item.s_id}>
                            <td>{item.s_table_number}</td>
                            <td>{item.s_seat_number}</td>
                            <td>{item.s_seat_category}</td>
                            <td>{item.s_seat_price}</td>
                            <td>{item.s_first_name}</td>
                            <td>{item.s_lastname}</td>
                            <td>Réservation en cours</td>
                        </tr>
                        <hr/>
                    </Fragment>)
            }
        },this);

        return (
            <Fragment>
                <table className="table table-striped">
                    <thead>
                    <th>Numéro de table</th>
                    <th>Numéro de siège</th>
                    <th>Type de place</th>
                    <th>Prix (Euros)</th>
                    <th>Prénom</th>
                    <th>Nom</th>
                    <th>Réservation</th>
                    </thead>
                    <tbody>
                        {rows}
                    </tbody>
                </table>
                <div className="buttonOrder" onClick={goCart}>Ma réservation</div>
                <div className="reservationMessage">{reservationMessage}</div>
            </Fragment>
        );
    }
}

export default Event