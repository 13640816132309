import React, { Fragment } from 'react'
import NavBar from "../Commun/NavBar";
import FloatFooter from "../Commun/FloatFooter";

const InscriptionMessage = () =>
{
    return (
        <Fragment>
            <NavBar />
            <section className="sectionNDM sectionAssociation section100">
                <div className="containerNDM containerCommissions margTop">
                    <div className="inscription fullPost">
                        <h1>Inscription</h1>
                        <div>Votre demande d'inscription a été prise en compte, pour finaliser la demande merci de cliquer sur le lien dans le mail qui vous a été envoyé.</div>
                    </div>
                </div>
            </section>
            <FloatFooter />
        </Fragment>
    );
}

export default InscriptionMessage