import React, {useEffect, useRef, useState} from 'react'
import { Link } from 'react-router-dom'
import PanelConnection from "../Home/PanelConnection";
import { useCookies } from "react-cookie";
import RequireImage from "./RequireImage";

const NavBar = () =>
{
    const [cookies] = useCookies(['ndmConnected']);
    const [menu1, setMenu1] = useState(false);
    const [menu2, setMenu2] = useState(false);
    const [menu3, setMenu3] = useState(false);
    const [menu4, setMenu4] = useState(false);
    const [menuA, setMenuA] = useState(false);
    const [menuB, setMenuB] = useState(false);
    const [checked, setChecked] = useState(false);
    const [logo] = useState("logo06.png");

    const ref = useRef(null);

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event)
        {
            if (ref.current && !ref.current.contains(event.target))
            {
                setMenu1(false);
                setMenu2(false);
                setMenu3(false);
                setMenu4(false);
                setMenuA(false);
                setMenuB(false);
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    const handleFieldHeaderChange = (event) =>
    {
        if (event.target.id === "menuA")
        {
            (menuA) ? setMenuA(false) : setMenuA(true)
            setMenuB(false);
            setMenu1(false);
            setMenu2(false);
            setMenu3(false);
            setMenu4(false);
        }
        if (event.target.id === "menuB")
        {
            setMenuA(false);
            (menuB) ? setMenuB(false) : setMenuB(true)
            setMenu1(false);
            setMenu2(false);
            setMenu3(false);
            setMenu4(false);
        }
    }

    const handleClick = () =>
    {
        const ulMenu = document.getElementById("menu");
        const spanOne = document.getElementById("spanOne");
        const spanTwo = document.getElementById("spanTwo");
        const spanThree = document.getElementById("spanThree");

        if(checked === false)
        {
            ulMenu.style.transform = "none";
            ulMenu.style.zIndex = "15";

            spanOne.style.transform = "rotate(45deg) translate(2px, -2px)";
            spanOne.style.background = "black";

            spanThree.style.transform = "rotate(-45deg) translate(-2px, 2px)";
            spanThree.style.background = "black";

            spanTwo.style.opacity = 0;

            setChecked(true);
        }
        else
        {
            ulMenu.style.transform = "translate(100%, 0)";
            ulMenu.style.zIndex = "1";

            spanOne.style.transform = "rotate(0deg) translate(0px, 0px)";
            spanOne.style.background = "white";

            spanThree.style.transform = "rotate(0deg) translate(0px, 0px)";
            spanThree.style.background = "white";

            spanTwo.style.opacity = 1;

            setChecked(false);
        }
    }

    const handleFieldChange = (event) =>
    {
        if (event.target.id === "menu1")
        {
            (menu1) ? setMenu1(false) : setMenu1(true)
            setMenu2(false);
            setMenu3(false);
            setMenu4(false);
        }
        if (event.target.id === "menu2")
        {
            setMenu1(false);
            (menu2) ? setMenu2(false) : setMenu2(true)
            setMenu3(false);
            setMenu4(false);
        }
        if (event.target.id === "menu3")
        {
            setMenu1(false);
            setMenu2(false);
            (menu3) ? setMenu3(false) : setMenu3(true)
            setMenu4(false);
        }
        if (event.target.id === "menu4")
        {
            setMenu1(false);
            setMenu2(false);
            setMenu3(false);
            (menu4) ? setMenu4(false) : setMenu4(true)
        }
    }

    return (
        <header ref={ref} className="nav">
            <div className="logo">
                <Link className="aLink firstPartLogo" to="/">
                    <RequireImage srcImage={logo} classnameImage="imageApel" repImage="logo"/>
                    <div className="sloganApel">
                        <p className="sloganApelp">Notre-Dame des Missions,</p>
                        <p className="sloganApelp">une chance de plus pour nos enfants !</p>
                    </div>

                </Link>
            </div>
            <nav className="nav1">
                <div id="divLinks" className="divLinks">
                    <ul className="navlinks">
                        <li className="liNavlinks">
                            <Link className="aNavlinks aLink" to="/">Accueil</Link>
                        </li>
                        <li className="liNavlinks">
                            <label className="aNavlinks aLink" htmlFor="menuA">Association</label>
                            <input className="accordionHeaderInput" id="menuA" type="checkbox" name="menu" checked={menuA} onChange={(e) => handleFieldHeaderChange(e)}/>
                            <ul className="underLinks">
                                <li><Link className="aLink" to="/presentation">Présentation</Link></li>
                                <li><Link className="aLink" to="/conseil-administration">Conseil d'administration</Link></li>
                                <li><Link className="aLink" to="/comptes-rendus">Comptes rendus d'AG</Link></li>
                                <li><Link className="aLink" to="/investissements">Investissements</Link></li>
                                <li><Link className="aLink" to="/statuts">Statuts</Link></li>
                                <li><Link className="aLink" to="/histoire">Historique</Link></li>
                                <li><Link className="aLink" to="/charte-educative">Charte éducative de confiance</Link></li>
                            </ul>
                        </li>
                        <li className="liNavlinks">
                            <label className="aNavlinks aLink" htmlFor="menuB">Les commissions</label>
                            <input className="accordionHeaderInput" id="menuB" type="checkbox" name="menu" checked={menuB} onChange={(e) => handleFieldHeaderChange(e)}/>
                            <ul className="underLinks accordion">
                                <li className="accordionLi accordionLiChild">
                                    <label className="accordionLabel aLink" htmlFor="menu1">Commission PCC</label>
                                    <input className="accordionInput" id="menu1" type="checkbox" name="menu" checked={menu1} onChange={(e) => handleFieldChange(e)}/>
                                    <ul className="accordionUlChild">
                                        <li><Link className="aLink" to="/commissions/commission-pcc/actualites">Actualités</Link></li>
                                        <li><Link className="aLink" to="/commissions/commission-pcc/vos-referents-pcc">Vos référents PCC</Link></li>
                                        <li><Link className="aLink" to="/commissions/commission-pcc/conseils-de-classes">Conseils de classes</Link></li>
                                        <li><Link className="aLink" to="/commissions/commission-pcc/charte-pcc">Charte du PCC</Link></li>
                                        <li><Link className="aLink" to="/commissions/commission-pcc/guide-pratique">Guide pratique</Link></li>
                                        <li><Link className="aLink" to="/commissions/commission-pcc/donner-ses-coordonnees">Donner ses coordonnées aux PCC</Link></li>
                                    </ul>
                                </li>
                                <li className="accordionLi accordionLiChild">
                                    <label className="accordionLabel aLink" htmlFor="menu2">Commission des fêtes</label>
                                    <input className="accordionInput" id="menu2" type="checkbox" name="menu" checked={menu2} onChange={(e) => handleFieldChange(e)}/>
                                    <ul className="accordionUlChild">
                                        <li><Link className="aLink" to="/commissions/commission-fetes/actualites">Actualités</Link></li>
                                        <li><Link className="aLink" to="/commissions/commission-fetes/benevolat">Bénévolat</Link></li>
                                        <li><Link style={{visibility: "hidden", display : "none"}} className="aLink" to="/commissions/commission-fetes/photos">Photos</Link></li>
                                    </ul>
                                </li>
                                <li className="accordionLi accordionLiChild">
                                    <label className="accordionLabel aLink" htmlFor="menu3">Commission communication</label>
                                    <input className="accordionInput" id="menu3" type="checkbox" name="menu" checked={menu3} onChange={(e) => handleFieldChange(e)}/>
                                    <ul className="accordionUlChild">
                                        <li><Link className="aLink" to="/commissions/commission-communication/actualites">Actualités</Link></li>
                                        <li><Link className="aLink" to="/commissions/commission-communication/parentillage">Parentillage</Link></li>
                                    </ul>
                                </li>
                                <li className="accordionLi accordionLiChild">
                                    <label className="accordionLabel aLink" htmlFor="menu4">Autres commissions</label>
                                    <input className="accordionInput" id="menu4" type="checkbox" name="menu" checked={menu4} onChange={(e) => handleFieldChange(e)}/>
                                    <ul className="accordionUlChild">
                                        <li><Link className="aLink" to="/commissions/commission-developpement-durable">Commission développement durable</Link></li>
                                        <li><Link className="aLink" to="/commissions/commission-cantine">Commission cantine</Link></li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li className="liNavlinks">
                            <Link className="aNavlinks aLink" to="/contact">Contact</Link>
                        </li>
                        <PanelConnection connected={cookies.ndmConnected}/>
                    </ul>
                </div>
            </nav>
            <nav id="menuToggle" className="nav2">
                <div id="burger" onClick={handleClick}>
                    <span className="burger_bar" id="spanOne"></span>
                    <span className="burger_bar" id="spanTwo"></span>
                    <span className="burger_bar" id="spanThree"></span>
                </div>

                    <ul id="menu" className="navlinks">
                        <li className="liNavlinks">
                            <Link className="aNavlinks aLink" to="/">Accueil</Link>
                        </li>
                        <li className="liNavlinks">
                            <label className="aNavlinks aLink" htmlFor="menuA">Association</label>
                            <input className="accordionHeaderInput" id="menuA" type="checkbox" name="menu" checked={menuA} onChange={(e) => handleFieldHeaderChange(e)}/>
                            <ul className="underLinks">
                                <li><Link className="aLink" to="/presentation">Présentation</Link></li>
                                <li><Link className="aLink" to="/conseil-administration">Conseil d'administration</Link></li>
                                <li><Link className="aLink" to="/comptes-rendus">Comptes rendus d'AG</Link></li>
                                <li><Link className="aLink" to="/investissements">Investissements</Link></li>
                                <li><Link className="aLink" to="/statuts">Statuts</Link></li>
                                <li><Link className="aLink" to="/histoire">Historique</Link></li>
                                <li><Link className="aLink" to="/charte-educative">Charte éducative de confiance</Link></li>
                            </ul>
                        </li>
                        <li className="liNavlinks">
                            <label className="aNavlinks aLink" htmlFor="menuB">Les commissions</label>
                            <input className="accordionHeaderInput" id="menuB" type="checkbox" name="menu" checked={menuB} onChange={(e) => handleFieldHeaderChange(e)}/>
                            <ul className="underLinks accordion">
                                <li className="accordionLi accordionLiChild">
                                    <label className="accordionLabel aLink" htmlFor="menu1">Commission PCC</label>
                                    <input className="accordionInput" id="menu1" type="checkbox" name="menu" checked={menu1} onChange={(e) => handleFieldChange(e)}/>
                                    <ul className="accordionUlChild">
                                        <li><Link className="aLink" to="/commissions/commission-pcc/actualites">Actualités</Link></li>
                                        <li><Link className="aLink" to="/commissions/commission-pcc/vos-referents-pcc">Vos référents PCC</Link></li>
                                        <li><Link className="aLink" to="/commissions/commission-pcc/conseils-de-classes">Conseils de classes</Link></li>
                                        <li><Link className="aLink" to="/commissions/commission-pcc/charte-pcc">Charte du PCC</Link></li>
                                        <li><Link className="aLink" to="/commissions/commission-pcc/guide-pratique">Guide pratique</Link></li>
                                        <li><Link className="aLink" to="/commissions/commission-pcc/donner-ses-coordonnees">Donner ses coordonnées aux PCC</Link></li>
                                    </ul>
                                </li>
                                <li className="accordionLi accordionLiChild">
                                    <label className="accordionLabel aLink" htmlFor="menu2">Commission des fêtes</label>
                                    <input className="accordionInput" id="menu2" type="checkbox" name="menu" checked={menu2} onChange={(e) => handleFieldChange(e)}/>
                                    <ul className="accordionUlChild">
                                        <li><Link className="aLink" to="/commissions/commission-fetes/actualites">Actualités</Link></li>
                                        <li><Link className="aLink" to="/commissions/commission-fetes/benevolat">Bénévolat</Link></li>
                                        <li><Link className="aLink" style={{visibility: "hidden", display : "none"}} to="/commissions/commission-fetes/photos">Photos</Link></li>
                                    </ul>
                                </li>
                                <li className="accordionLi accordionLiChild">
                                    <label className="accordionLabel aLink" htmlFor="menu3">Commission communication</label>
                                    <input className="accordionInput" id="menu3" type="checkbox" name="menu" checked={menu3} onChange={(e) => handleFieldChange(e)}/>
                                    <ul className="accordionUlChild">
                                        <li><Link className="aLink" to="/commissions/commission-communication/actualites">Actualités</Link></li>
                                        <li><Link className="aLink" to="/commissions/commission-communication/parentillage">Parentillage</Link></li>
                                    </ul>
                                </li>
                                <li className="accordionLi accordionLiChild">
                                    <label className="accordionLabel aLink" htmlFor="menu4">Autres commissions</label>
                                    <input className="accordionInput" id="menu4" type="checkbox" name="menu" checked={menu4} onChange={(e) => handleFieldChange(e)}/>
                                    <ul className="accordionUlChild">
                                        <li><Link className="aLink" to="/commissions/commission-developpement-durable">Commission développement durable</Link></li>
                                        <li><Link className="aLink" to="/commissions/commission-cantine">Commission cantine</Link></li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li className="liNavlinks">
                            <Link className="aNavlinks aLink" to="/contact">Contact</Link>
                        </li>
                        <PanelConnection connected={cookies.ndmConnected}/>
                    </ul>

            </nav>
        </header>
    );
}

export default NavBar