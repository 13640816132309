import React, {Fragment, useEffect, useRef, useState} from 'react'
import { Editor } from '@tinymce/tinymce-react';
import NavBar from "../Commun/NavBar";
import FloatFooter from "../Commun/FloatFooter";
import CheckPostField from "../Commun/CheckPostField";
import {useCookies} from "react-cookie";
import {useHistory} from "react-router";
import Waiting from "../Commun/Waiting";
const {REACT_APP_API_URL} = process.env;

const CreateReport = () =>
{
    const history = useHistory();
    const [useOnce1, setUseOnce1] = useState(0);
    const [useOnce2, setUseOnce2] = useState(0);
    const [items1, setItems1] = useState([]);
    const [isLoaded1, setIsLoaded1] = useState(false);
    const [error1, setError1] = useState(null);
    const [items2, setItems2] = useState([]);
    const [isLoaded2, setIsLoaded2] = useState(false);
    const [error2, setError2] = useState(null);
    const [cookies] = useCookies();
    const [reportTitle, setReportTitle] = useState('');
    const [reportContent, setReportContent] = useState('');
    const [reportClass, setReportClass] = useState(0);
    const [reportTerm, setReportTerm] = useState(0);
    const [reportYear, setReportYear] = useState(0);
    const selectedFile = useRef();
    const [reportAttachement, setReportAttachement] = useState(null);
    const [term, setTerm] = useState([{"id": 1, "value":"1er"}, {"id": 2, "value":"2ème"}, {"id": 3, "value":"3ème"}]);
    const [termTitle, setTermTitle] = useState("Trimestre");
    const [updateError, setUpdateError] = useState(null);
    const [messageStyle, setMessageStyle] = useState("hidden");
    const editorRef = useRef(null);

    useEffect(() =>
    {
        if (useOnce1 === 0)
        {
            const tokenMember = cookies['ndmToken'];

            if (tokenMember)
            {
                let request = REACT_APP_API_URL + "member/get-autorisation/" + tokenMember;

                const optionsAPI =
                    {
                        method: 'GET'
                    };

                fetch(request, optionsAPI)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            setIsLoaded1(true);
                            setItems1(result);
                        },

                        (error) => {
                            setIsLoaded1(true);
                            setError1(error);
                        }
                    )
            }
            setUseOnce1(1);
        }
    });

    useEffect(() =>
    {
        if (useOnce2 === 0)
        {
            let request = REACT_APP_API_URL + "classcouncils/allclassescouncils";

            const optionsAPI =
                {
                    method: 'GET'
                };

            fetch(request, optionsAPI)
                .then(res => res.json())
                .then(
                    (result) => {
                        setIsLoaded2(true);
                        setItems2(result);
                    },

                    (error) => {
                        setIsLoaded2(true);
                        setError2(error);
                    }
                )

            setUseOnce2(1);
        }
    })

    const handleSubmit = event =>
    {
        event.preventDefault();

        let formStatus = 1;
        if (reportTitle === '') { alert("Le titre du rapport de classe n'est pas défini"); formStatus = 0 }
        if (reportAttachement === '' || !reportAttachement) { alert("La pièce jointe du rapport de classe n'est pas définie"); formStatus = 0 }
        if (reportContent === '') { alert("La description du rapport  de classe n'est pas définie"); formStatus = 0 }
        if (reportClass === '') { alert("La classe du rapport  de classe n'est pas définie"); formStatus = 0 }
        if (reportTerm === '') { alert("Le trimestre du rapport de classe n'est pas défini"); formStatus = 0 }
        if (reportYear === '') { alert("L'année du rapport de classe n'est pas définie"); formStatus = 0 }

        if (formStatus === 1)
        {
            const memberToken = cookies['ndmToken'];
            let request = REACT_APP_API_URL + "add-report";

            let selectFileData = new FormData();

            selectFileData.append('reportTitle', reportTitle);
            selectFileData.append('reportContent', reportContent);
            selectFileData.append('reportTerm', reportTerm);
            selectFileData.append('reportClass', reportClass);
            selectFileData.append('reportAttachement', reportAttachement);
            selectFileData.append('reportYear', reportYear);
            selectFileData.append('memberToken', memberToken);

            const options =
                {
                    method: 'POST',
                    body: selectFileData,
                };
            fetch(request, options)
                .then(
                    (result) => {
                        history.push('/ndmback/gerer-rapports');
                    },

                    (error) => {
                        setMessageStyle("visible")
                        setUpdateError(error);
                    }
                )
        }
    }

    const handleFileChange = event =>
    {
        event.preventDefault();

        const { name } = event.target;
        const file = event.target.files[0];

        if (name === 'reportAttachement')
        {
            const reportAttachementCheck = CheckPostField(name, file);
            if (reportAttachementCheck === 0)
            {
                setReportAttachement(file);
            }
        }
    }

    const handleFieldChange = event =>
    {
        event.preventDefault();

        const { name, value } = event.target;

        if (name === 'reportTitle')
        {
            const reportTitleCheck = CheckPostField(name, value);
            if (reportTitleCheck === 0)
            {
                setReportTitle(value);
            }
        }

        if (name === 'reportTerm')
        {
            const reportTermCheck = CheckPostField(name, value);
            if (reportTermCheck === 0)
            {
                setReportTerm(value);
            }
        }

        if (name === 'reportClass')
        {
            const reportClassCheck = CheckPostField(name, value);
            if (reportClassCheck === 0)
            {
                setReportClass(value);
            }
        }

        if (name === 'reportYear')
        {
            const reportYearCheck = CheckPostField(name, value);
            if (reportYearCheck === 0)
            {
                setReportYear(value);
            }
        }
    }

    const handleEditorChange = (content) =>
    {
        const contentCheck = CheckPostField('textarea', content);
        if (contentCheck === 0)
        {
            setReportContent(content);
        }
    }

    const handleChangeLevel = event =>
    {
        event.preventDefault();
        const { name, value } = event.target;
        let levelName = '';

        for (let i = 0; i < items2.length; i++)
        {
            if (parseInt(items2[i].id) === parseInt(value))
            {
                levelName = items2[i].name;
            }
        }

        if (name === 'reportClass')
        {
            const reportClassCheck = CheckPostField(name, value);
            if (reportClassCheck === 0)
            {
                setReportClass(value);
            }
        }

        if (levelName.substr(0, 1) === "T" || levelName.substr(0, 1) === "1")
        {
            setTerm([{"id": 1, "value":"1er"}, {"id": 2, "value":"2ème"}]);
            setTermTitle("Semestre");
        }
        else
        {
            setTerm([{"id": 1, "value":"1er"}, {"id": 2, "value":"2ème"}, {"id": 3, "value":"3ème"}]);
            setTermTitle("Trimestre");
        }
    }

    if (error2 || error1)
    {
        return <div>Error: {error2.message}</div>;
    }
    else if (!isLoaded2 || !isLoaded1)
    {
        return (<Waiting />);
    }
    else
    {
        if (items1.status === 0 && (items1.roles.includes('EDITOR') || items1.roles.includes('ADMINISTRATOR')))
        {
            return (
                <Fragment>
                    <NavBar/>
                    <section className="sectionNDM sectionAdmin section100">
                        <div className="containerNDM containerAdmin margTop">
                            <div id="containerBackground">
                                <h1>Ecrire un nouveau rapport de conseil de classe</h1>
                                <form onSubmit={handleSubmit} id="formBackendView" encType="multipart/form-data" className="formFlex" action="">
                                    <div className='postTitle flexFormColCenter'>
                                        <label className="formFont" htmlFor="reportTitle">Titre</label>
                                        <div>
                                            <input
                                                type='text'
                                                name='reportTitle'
                                                className="postTitle"
                                                onChange={(e) => handleFieldChange(e)}/>
                                        </div>
                                    </div>

                                    <div className='postCategory flexFormColCenter'>
                                        <label className="formFont" htmlFor="postCategory">Classe</label>
                                        <select
                                            className="form-control input-sm"
                                            name="reportClass"
                                            onChange={(e) => handleChangeLevel(e)}>
                                            <option selected> -- Sélectionner une option -- </option>
                                            {items2.map(item => (
                                                <option key={item.id} id={item.id} value={item.id}>{item.name}</option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className='postCategory flexFormColCenter'>
                                        <label className="formFont" htmlFor="postCategory">{termTitle}</label>
                                        <select
                                            className="form-control input-sm"
                                            name="reportTerm"
                                            onChange={(e) => handleFieldChange(e)}>
                                            <option selected> -- Sélectionner une option -- </option>
                                            {term.map(term => (
                                                <option key={term.id} value={term.id} >{term.value}</option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className='postTitle flexFormColCenter'>
                                        <label className="formFont" htmlFor="reportYear">Année</label>
                                        <div>
                                            <div>{reportYear - 1}</div>
                                            <input
                                                type='number'
                                                name='reportYear'
                                                className="postTitle"
                                                min="2020"
                                                onChange={(e) => handleFieldChange(e)}/>
                                        </div>
                                    </div>

                                    <div className="flexFormColCenter" id="uploadZone">
                                        <label className="formFont" htmlFor="reportAttachement">Pièce jointe</label>
                                        <input
                                            type="file"
                                            id="attachement"
                                            name="reportAttachement"
                                            onChange={(e) => handleFileChange(e)}/>
                                    </div>

                                    <div className="flexFormColCenter" id="postTextarea">
                                        <label className="formFont" htmlFor="postTextarea">Contenu</label>
                                        <Editor
                                            id="mytextarea"
                                            name="reportTextarea"
                                            onInit={(evt, editor) => editorRef.current = editor}
                                            value={reportContent}
                                            apiKey='yaysfwznq8jhd2wmq7ovzetookk8gjqqh4yls46m1fp7c8z3'
                                            init={{
                                                height: 500,
                                                menubar: false,
                                                plugins: [
                                                    'advlist autolink lists link image charmap print preview anchor',
                                                    'searchreplace visualblocks code fullscreen',
                                                    'insertdatetime media table paste code help wordcount'
                                                ],
                                                toolbar: 'undo redo | formatselect | ' +
                                                    'bold italic forecolor underline | alignleft aligncenter ' +
                                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                                    'removeformat | help',
                                                forced_root_block : false,
                                                force_br_newlines : false,
                                                force_p_newlines : false,
                                            }}
                                            onEditorChange={handleEditorChange}/>
                                    </div>
                                    <button type="submit" className="button buttonSize">Envoyer</button>
                                    <div className="flexFormColCenter flexFormColCenter" style={{visibility: messageStyle}}>
                                        <label className="messageError">Message: {updateError}</label>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </section>
                    <FloatFooter/>
                </Fragment>
            );
        }
        else
        {
            return (
                <Fragment>
                    <NavBar/>
                    <section className="sectionNDM sectionAdmin section100">
                        <div className="containerNDM containerAdmin margTop">
                            Vous n'êtes pas autorisé à afficher cette page
                        </div>
                    </section>
                    <FloatFooter/>
                </Fragment>);
        }
    }
}

export default CreateReport