import React, {Component, Fragment, useEffect, useState} from 'react'
import {Document, Page, pdfjs} from "react-pdf";
import Waiting from "../Commun/Waiting";
import RequireAttachement from "../Commun/RequireAttachement";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const ReportPDF = (props) =>
{
    const [numPages, setNumPages] = useState(null);
    const [items, setItems] = useState([]);
    const [oldData, setOldData] = useState([]);
    const [useOnce, setUseOnce] = useState(0);
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() =>
    {
        if (useOnce === 0)
        {
            const oldData = props.data;
            setOldData(oldData);
            setIsLoaded(true);
            setUseOnce(1);
        }

        if (oldData !== props.data)
        {
            const { REACT_APP_API_URL } = process.env;
            let request = REACT_APP_API_URL + "classcouncils/report/class/" + props.data.classSelected + "/year/" + props.data.yearSelected + "/term/" + props.data.termSelected;

            const newData = props.data;
            setOldData(newData);

            fetch(request, { "method": "GET"})
                .then(res => res.json())
                .then(
                    (result) => {
                        setIsLoaded(true);
                        setItems(result);
                    },

                    (error) => {
                        setIsLoaded(true);
                        setError(error);
                    }
                )
        }
    })

    function onDocumentLoadSuccess({ numPages })
    {
        setNumPages(numPages);
    }

    function attachementSrc(src)
    {
        const atta = src.attachement;
        return "/attachement/post/" + atta;
    }


    if (error)
    {
        return <div>Error: {error.message}</div>;
    }
    else if (!isLoaded)
    {
        return (<Waiting />);
    }
    else if (!props.data.classSelected)
    {
        return(
            <h1 className="h1CR">Veuillez faire un choix</h1>
        )
    }
    else
    {
        if (items !== "")
        {
            return(
                <Fragment>
                    {items.map(item => (
                        <div key={item.id}>
                            <div className="postAttachCouncilContainer">
                                <div className="titleAttachCouncil">Télécharger PDF</div>
                                <div className="postAttachCouncil">
                                    <RequireAttachement srcAttachement={item.attachement} classnameAttachement="imgTablePosts" repAttachement="post"/>
                                </div>
                            </div>
                            <Document file={attachementSrc(item)} onLoadSuccess={onDocumentLoadSuccess}>
                                {Array.from(new Array(numPages), (el, index) => (
                                    <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                                ))}
                            </Document>
                        </div>
                    ))}
                </Fragment>
            )
        }
        else
        {
            return(
                <Fragment>
                    <h1 className="h1CR">Pas de compte-rendu disponible</h1>
                </Fragment>
            )
        }
    }
}

export default ReportPDF