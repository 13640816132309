import React, {useEffect, useState} from 'react'
import GetSessionStorage from "../Commun/GetSessionStorage";
import SetSessionStorage from "../Commun/SetSessionStorage";

const TableRow = props =>
{
    const [selectedId, setSelectedId] = useState(0);
    const [selectedTop, setSelectedTop] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [instance] = useState({});
    const [data, setData] = useState({"dataField":[], "instances" : []});
    const [oldData, setOldData] = useState({"dataField":[], "instances" : []});
    const [oldInstance, setOldInstance] = useState([]);

    useEffect(() =>
    {
        if (oldInstance !== props.instanceProps)
        {
            data.dataField.forEach(function (fd) {
                instance[fd.name] = props.instanceProps[fd.name];
            });

            setOldInstance(props.instanceProps);
        }
        else if (oldData !== data)
        {
            setSelectedId(props.instanceProps.id - 1);

            let dataTemp = GetSessionStorage('apelNDMdata', {"dataField":[], "instances" : []});
            let instanceTemp = dataTemp.instances[props.instanceProps.id - 1];

            dataTemp.dataField.forEach(function (fd) {
                instance[fd.name] = instanceTemp[fd.name];
            });

            setOldInstance(props.instanceProps);
            setData(dataTemp);
            setOldData(dataTemp);
        }
    });

    const handleSelectRow = () =>
    {
        if (!selectedTop)
        {
            setSelectedTop(true );
        }
        else
        {
            setSelectedTop(false );
        }
    }

    const handleEditModeClick = () =>
    {
        setEditMode(true );
    }

    const handleExitEditModeClick = () =>
    {
        setEditMode(false );
    }

    const handleDeleteClick = () =>
    {
        setDeleted(true );
    }

    const handleConfirmEditClick = () =>
    {
        setEditMode(false );
    }

    const handleFieldChange = event =>
    {
        let dataTemp = GetSessionStorage('apelNDMdata', {"dataField":[], "instances" : []})
        dataTemp.instances[selectedId][event.target.id] = event.target.value;
        instance[event.target.id] = event.target.value;
        SetSessionStorage('apelNDMdata', dataTemp);
        setData(dataTemp);
    }

    let row = [];
    let buttons = null;

    if (deleted)
    {
        return <tr></tr>;
    }

    if (editMode)
    {
        let eventSeatTitleTemp = GetSessionStorage('apelNDMeventSeatTitle', []);

        // In edit mode - set buttons for edit
        buttons = [
            <button type="button" onClick={handleConfirmEditClick.bind(this)} className="edit btn btn-default btn-success" ><span className="glyphicon glyphicon-ok"></span></button>,
            <button type="button" onClick={handleExitEditModeClick.bind(this)} className="edit btn btn-default btn-danger" ><span className="glyphicon glyphicon-remove"></span></button>
        ];
        // In edit mode - set field values and placeholders
        data.dataField.forEach(function(fd) {
            if (fd.inputField)
            {
                if (fd.name === "tableType")
                {
                    row.push(
                        <td>
                            <select
                                key={fd.name}
                                id={fd.name}
                                className="form-control input-sm"
                                value={instance[fd.name]}
                                onChange={handleFieldChange.bind(this)}>
                                <option selected> -- Sélectionner une option -- </option>
                                {Object.keys(eventSeatTitleTemp).map( (index) => {
                                    return (<option value={eventSeatTitleTemp[index]}>{eventSeatTitleTemp[index]}</option>);
                                })}
                            </select>
                        </td>
                    );
                }
                else
                {
                    row.push(
                        <td>
                            <input
                                key={fd.name}
                                id={fd.name}
                                className="form-control input-sm"
                                type="text"
                                value={instance[fd.name]}
                                onChange={handleFieldChange.bind(this)}>
                            </input>
                        </td>
                    );
                }
            }
            else
            {
                row.push(<td key={fd.name}></td>)
            }
        }, this);
    }
    else
    {
        buttons = [
            <button type="button" onClick={handleEditModeClick.bind(this)} className="edit btn btn-default" ><span className="glyphicon glyphicon-pencil"></span></button>,
            <button type="button" onClick={handleDeleteClick.bind(this)} className="edit btn btn-default" ><span className="glyphicon glyphicon-trash"></span></button>
        ];

        data.dataField.forEach(function(fd)
        {
            row.push(<td onClick={handleSelectRow.bind(this)} key={instance[fd.name]}>{instance[fd.name]}</td>);
        });
    }

    if (instance.selected)
    {
        return (<tr className="success">{row}<div className="btn-group btn-group-sm"> {buttons}</div></tr>);
    }
    else
    {
        return (<tr>{row}<div className="btn-group btn-group-sm"> {buttons}</div></tr>);
    }
}

export default TableRow