import React, {Fragment, useEffect, useRef, useState} from 'react'
import NavBar from "../Commun/NavBar";
import FloatFooter from "../Commun/FloatFooter";
import {useCookies} from "react-cookie";
import { useParams } from 'react-router-dom';
import { useHistory } from "react-router";
import CheckPostField from "../Commun/CheckPostField";
import RequireImage from "../Commun/RequireImage";
import { Editor } from "@tinymce/tinymce-react";

const { REACT_APP_API_URL } = process.env;

const EditBoard = () =>
{
    const { id } = useParams()
    const [cookies] = useCookies();
    const editorRef = useRef(null);
    const history = useHistory();

    const [useOnce1, setUseOnce1] = useState(0);
    const [useOnce2, setUseOnce2] = useState(0);
    const [items1, setItems1] = useState([]);
    const [isLoaded1, setIsLoaded1] = useState(false);
    const [error1, setError1] = useState(null);
    const [, setItems2] = useState([]);
    const [isLoaded2, setIsLoaded2] = useState(false);
    const [error2, setError2] = useState(null);
    const [updateError, setUpdateError] = useState(null);
    const [messageStyle, setMessageStyle] = useState("hidden");

    const [boardId, setBoardId] = useState(0);
    const [boardFirstname, setBoardFirstname] = useState('');
    const [boardLastname, setBoardLastname] = useState('');
    const [boardDescription, setBoardDescription] = useState('');
    const [boardPicture, setBoardPicture] = useState('');
    const [boardYear, setBoardYear] = useState('');
    const selectedFile=useRef();

    useEffect(() =>
    {
        if (useOnce1 === 0)
        {
            const tokenMember = cookies['ndmToken'];

            if (tokenMember)
            {
                let request = REACT_APP_API_URL + "member/get-autorisation/" + tokenMember;

                const optionsAPI =
                    {
                        method: 'GET'
                    };

                fetch(request, optionsAPI)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            setIsLoaded1(true);
                            setItems1(result);
                        },

                        (error) => {
                            setIsLoaded1(true);
                            setError1(error);
                        }
                    )
            }
            setUseOnce1(1);
        }

        if (useOnce2 === 0)
        {
            let request = REACT_APP_API_URL + "board/edit/" + id;

            const optionsAPI =
                {
                    method: 'GET'
                };

            fetch(request, optionsAPI)
                .then(res => res.json())
                .then(
                    (result) => {
                        setBoardId(id);
                        setIsLoaded2(true);
                        setItems2(result);
                        setBoardFirstname(result[0].firstname);
                        setBoardLastname(result[0].lastname);
                        setBoardDescription(result[0].description);
                        setBoardPicture(result[0].image);
                        setBoardYear(result[0].year);
                    },

                    (error) => {
                        setIsLoaded2(true);
                        setError2(error);
                    }
                )

            setUseOnce2(1);
        }
    })

    const handleSubmit = event =>
    {
        event.preventDefault();

        let formStatus = 1;
        if (boardFirstname === '') { alert("Le prénom du membre n'est pas défini"); formStatus = 0 }
        if (boardLastname === '') { alert("Le nom du membre n'est pas défini"); formStatus = 0 }
        if (boardDescription === '') { alert("La description du membre n'est pas définie"); formStatus = 0 }

        if (formStatus === 1)
        {
            const memberToken = cookies['ndmToken'];
            const { REACT_APP_API_URL } = process.env;

            let request = REACT_APP_API_URL + "update-board";
            let selectFileData = new FormData();

            selectFileData.append('boardId', boardId);
            selectFileData.append('boardFirstname', boardFirstname);
            selectFileData.append('boardLastname', boardLastname);
            selectFileData.append('boardDescription', boardDescription);
            selectFileData.append('boardPicture', boardPicture);
            selectFileData.append('memberToken', memberToken);

            const options =
                {
                    method: 'POST',
                    body: selectFileData,
                };
            fetch(request, options)
                .then(
                    (result) => {
                        history.push('/ndmback/gerer-trombinoscopes');
                    },

                    (error) => {
                        setMessageStyle("visible")
                        setUpdateError(error);
                    }
                )
        }
    }

    const handleFileChange = event =>
    {
        event.preventDefault();

        const { name } = event.target;
        const file = event.target.files[0];

        if (name === 'boardPicture')
        {
            const pictureCheck = CheckPostField(name, file);
            if (pictureCheck === 0)
            {
                setBoardPicture(file);
            }
        }
    }

    const handleEditorChange = (content) =>
    {
        const contentCheck = CheckPostField('textarea', content);
        if (contentCheck === 0)
        {
            setBoardDescription(content);
        }
    }

    const handleFieldChange = event =>
    {
        event.preventDefault();

        const { name, value } = event.target;

        if (name === 'firstname')
        {
            const boardFirstnameCheck = CheckPostField(name, value);
            if (boardFirstnameCheck === 0)
            {
                setBoardFirstname(value);
            }
        }

        if (name === 'lastname')
        {
            const boardLastnameCheck = CheckPostField(name, value);
            if (boardLastnameCheck === 0)
            {
                setBoardLastname(value);
            }
        }
    }

    if (error2 || error1)
    {
        return <div>Error: {error2.message}</div>;
    }
    else if (!isLoaded2 || !isLoaded1)
    {
        return (
            <Fragment>
                <NavBar/>
                <section className="sectionNDM sectionAdmin section100">
                    <div className="containerNDM containerAdmin margTop">
                        En attente
                    </div>
                </section>
                <FloatFooter/>
            </Fragment>);
    }
    else {
        if (items1.status === 0 && (items1.roles.includes('EDITOR') || items1.roles.includes('ADMINISTRATOR')))
        {
            const directoryBoard = "board/" + boardYear;

            return (
                <Fragment>
                    <NavBar/>
                    <section className="sectionNDM sectionAdmin section100">
                        <div className="containerNDM containerAdmin margTop">
                            <div id="containerBackground">
                                <h1>Modifier un membre du conseil</h1>
                                <form onSubmit={handleSubmit} id="formBackendView" encType="multipart/form-data" className="formFlex" action="">
                                    <div className='postTitle flexFormColCenter'>
                                        <label className="formFont" htmlFor="firstname">Prénom du membre</label>
                                        <input
                                            type='text'
                                            name='firstname'
                                            className="postTitle"
                                            value={boardFirstname}
                                            onChange={(e) => handleFieldChange(e)}
                                        />
                                    </div>

                                    <div className='postTitle flexFormColCenter'>
                                        <label className="formFont" htmlFor="lastname">Nom du membre</label>
                                        <input
                                            type='text'
                                            name='lastname'
                                            className="postTitle"
                                            value={boardLastname}
                                            onChange={(e) => handleFieldChange(e)}
                                        />
                                    </div>

                                    <div className='postTitle flexFormColCenter'>
                                        <label className="formFont" htmlFor="boardTextarea">Description</label>
                                        <Editor
                                            id="mytextarea"
                                            name="boardTextarea"
                                            onInit={(evt, editor) => editorRef.current = editor}
                                            value={boardDescription}
                                            apiKey='yaysfwznq8jhd2wmq7ovzetookk8gjqqh4yls46m1fp7c8z3'
                                            init={{
                                                height: 500,
                                                menubar: false,
                                                plugins: [
                                                    'advlist autolink lists link image charmap print preview anchor',
                                                    'searchreplace visualblocks code fullscreen',
                                                    'insertdatetime media table paste code help wordcount'
                                                ],
                                                toolbar: 'undo redo | formatselect | ' +
                                                    'bold italic forecolor underline | alignleft aligncenter ' +
                                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                                    'removeformat | help',
                                                forced_root_block : false,
                                                force_br_newlines : true,
                                                force_p_newlines : false,
                                            }}
                                            onEditorChange={handleEditorChange}/>
                                    </div>

                                    <div className="flexFormColCenter" id="uploadZone">
                                        <label className="formFont" htmlFor="boardPicture">Image</label>
                                        <RequireImage srcImage={boardPicture} classnameImage="imgTableBoards" repImage={directoryBoard}/>
                                        <input
                                            type="file"
                                            id="photo"
                                            name="boardPicture"
                                            ref={selectedFile}
                                            onChange={(e) => handleFileChange(e)}
                                        />
                                    </div>

                                    <button type="submit" className="button buttonSize">Envoyer</button>

                                    <div className="flexFormColCenter flexFormColCenter" style={{visibility: messageStyle}}>
                                        <label className="formFont" htmlFor="eventSeatingPlan">Message: </label><div>{updateError}</div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </section>
                    <FloatFooter/>
                </Fragment>
            );
        }
        else
        {
            return (
                <Fragment>
                    <NavBar/>
                    <section className="sectionNDM sectionAdmin section100">
                        <div className="containerNDM containerAdmin margTop">
                            Vous n'êtes pas autorisé à afficher cette page
                        </div>
                    </section>
                    <FloatFooter/>
                </Fragment>);
        }
    }
}

export default EditBoard