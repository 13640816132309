import React, { Fragment } from 'react'
import NavBar from "../Commun/NavBar";
import FloatFooter from "../Commun/FloatFooter";

const Association = () =>
{
    return (
        <Fragment>
            <NavBar/>
            <section className="sectionNDM sectionAssociation section100">
                <div className="containerNDM containerCommissions margTop">
                    <div className="videoBlock">
                        <h1>Votre association</h1>
                        <video className="wp-video-shortcode" id="video-40-1" preload="metadata" controls="controls">
                            <source type="video/mp4" src="https://apelndm.fr/wp-content/uploads/2014/02/filmapelNDM2.mp4?_=1"/>
                        </video>
                    </div>
                </div>
            </section>
            <FloatFooter/>
        </Fragment>
    );
}

export default Association