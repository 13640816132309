import React, {Fragment, useEffect, useState} from 'react'
import NavBar from "../Commun/NavBar";
import FloatFooter from "../Commun/FloatFooter";
import {useParams} from "react-router";
import Waiting from "../Commun/Waiting";
import APIError from "../Commun/APIError";
const {REACT_APP_API_URL} = process.env;

const InscriptionValidation = () =>
{
    let { token } = useParams();

    const [useOnce, setUseOnce] = useState(0);
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() =>
    {
        if (useOnce === 0)
        {
            let request = REACT_APP_API_URL + "member/inscription-validation/" + token;

            const optionsAPI =
                {
                    method: 'GET'
                };

            fetch(request, optionsAPI)
                .then(res => res.json())
                .then(
                    (result) => {
                        setIsLoaded(true);
                    },

                    (error) => {
                        setIsLoaded(true);
                        setError(error);
                    }
                )
        }
        setUseOnce(1);
    });

    if (error)
    {
        return (<APIError message={error.message} />);
    }
    else
    {
        if (!isLoaded)
        {
            return (<Waiting />)
        }
        else
        {
            return (
                <Fragment>
                    <NavBar/>
                    <section className="sectionNDM sectionPost section100">
                        <div className="containerNDM containerAdmin margTop">
                            Votre inscription est définitivement validée. Vous pouvez vous connectez sur le site de l'APEL NDM.
                        </div>
                    </section>
                    <FloatFooter/>
                </Fragment>
            );
        }
    }
}

export default InscriptionValidation