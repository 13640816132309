import React, {Fragment, useEffect, useState} from 'react'
import NavBar from "../Commun/NavBar";
import FloatFooter from "../Commun/FloatFooter";
import {useCookies} from "react-cookie";
import TableMember from "./TableMember";
const { REACT_APP_API_URL } = process.env;

const ManageRoles = () =>
{
    const [useOnce1, setUseOnce1] = useState(0);
    const [useOnce2, setUseOnce2] = useState(0);
    const [items1, setItems1] = useState([]);
    const [isLoaded1, setIsLoaded1] = useState(false);
    const [error1, setError1] = useState(null);
    const [items2, setItems2] = useState([]);
    const [isLoaded2, setIsLoaded2] = useState(false);
    const [error2, setError2] = useState(null);
    const [cookies] = useCookies();


    useEffect(() =>
    {
        if (useOnce1 === 0)
        {
            const tokenMember = cookies['ndmToken'];

            if (tokenMember)
            {
                let request = REACT_APP_API_URL + "member/get-autorisation/" + tokenMember;

                const optionsAPI =
                    {
                        method: 'GET'
                    };

                fetch(request, optionsAPI)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            setIsLoaded1(true);
                            setItems1(result);
                        },

                        (error) => {
                            setIsLoaded1(true);
                            setError1(error);
                        }
                    )
            }
            setUseOnce1(1);
        }
    });

    useEffect(() =>
    {
        if (useOnce2 === 0)
        {
            let request = REACT_APP_API_URL + "member/get-all";

            const optionsAPI =
                {
                    method: 'GET'
                };

            fetch(request, optionsAPI)
                .then(res => res.json())
                .then(
                    (result) => {
                        setIsLoaded2(true);
                        setItems2(result);
                    },

                    (error) => {
                        setIsLoaded2(true);
                        setError2(error);
                    }
                )

            setUseOnce2(1);
        }
    })

    if (error2 || error1)
    {
        return <div>Error: {error2.message}</div>;
    }
    else if (!isLoaded2 || !isLoaded1)
    {
        return (
            <Fragment>
                <NavBar/>
                <section className="sectionNDM sectionAdmin section100">
                    <div className="containerNDM containerAdmin margTop">
                        <h1>En attente</h1>
                    </div>
                </section>
                <FloatFooter/>
            </Fragment>);
    }
    else
    {
        if (items1.status === 0 && items1.roles.includes('ADMINISTRATOR'))
        {
            return (
                <Fragment>
                    <NavBar/>
                    <section className="sectionNDM sectionAdmin section100">
                        <div className="containerNDM containerAdmin margTop">
                            <div id="tableContainerManagePosts">
                                <div id="tableManagePosts">
                                    <h1>Gestion des catégories</h1>
                                    <table className="tableManagePosts">
                                        <thead>
                                        <tr>
                                            <th>Pseudo</th>
                                            <th>Prénom</th>
                                            <th>Nom</th>
                                            <th>Membre</th>
                                            <th>Editeur</th>
                                            <th>Administrateur</th>
                                            <th>Editer</th>
                                        </tr>
                                        </thead>
                                        <TableMember dataItems={items2}/>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </section>
                    <FloatFooter/>
                </Fragment>
            );
        }
        else
        {
            return (
                <Fragment>
                    <NavBar/>
                    <section className="sectionNDM sectionAdmin section100">
                        <div className="containerNDM containerAdmin margTop">
                            <h1>Vous n'êtes pas autorisé à afficher cette page</h1>
                        </div>
                    </section>
                    <FloatFooter/>
                </Fragment>);
        }
    }
}

export default ManageRoles