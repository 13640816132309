import React, { Component, Fragment } from 'react'
import NavBar from "../Commun/NavBar";
import FloatFooter from "../Commun/FloatFooter";
import ChangeDate from "../Commun/ChangeDate";
import TableReservation from "./TableReservation";
import RequireImage from "../Commun/RequireImage";

class Event extends Component
{
    state =
        {
            error: null,
            items: [],
            isLoaded: false,
        };

    componentDidMount()
    {
        const slug = this.props.match.params.slug;
        const {REACT_APP_API_URL} = process.env;

        let request = REACT_APP_API_URL + "event/" + slug;

        fetch(request, {"method": "GET"})
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        items: result
                    });
                },

                (error) => {
                    this.setState({
                        isLoaded: true,
                        error: error
                    });
                }
            )
    }

    render()
    {
        const {error, items, isLoaded} = this.state;

        if (error)
        {
            return <div>Error: {error.message}</div>;
        }
        else if (!isLoaded)
        {
            return <div>Loading...</div>;
        }
        else
        {
            return (
                <Fragment>
                    <NavBar/>
                    <section className="sectionNDM sectionCommissions section100">
                        <div className="containerNDM containerCommissions margTop">
                            <div>
                                {items.map(item => (
                                    <div className="fullOneEvent" key={item.id}>
                                        <h2 className="titleOneEvent">{item.name}</h2>
                                        <div className="containerImageEvent">
                                            <RequireImage srcImage={item.image} classnameImage="imgOneEventHome" repImage="event"/>
                                        </div>
                                        <div>
                                            <div className="descriptionOneEvent">
                                                <p className="contentOneEvent" dangerouslySetInnerHTML={{ __html: item.description }} />
                                            </div>
                                            <div className="referenceOneEvent">
                                                APEL NDM<ChangeDate dateToChange={item.date}/>
                                            </div>
                                        </div>
                                        <div id="reservation">
                                            <h2>Réservation</h2>
                                            <RequireImage srcImage={item.seating_plan} classnameImage="imgOneEventHome" repImage="event"/>
                                            <TableReservation id={item.id}/>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>
                    <FloatFooter/>
                </Fragment>
            );
        }
    }
}

export default Event