import React, { Component } from 'react'
import Item from "./Item";
import ItemParentillages from "./ItemParentillages";
import { TransitionGroup } from 'react-transition-group';

class Carousel extends Component
{
    constructor(props)
    {
        super(props)
        this.state = {
            active: this.props.active,
            direction: '',
            error: null,
            isLoaded: false,
            items: [],
            oldYear: 0
        }
        this.rightClick = this.moveRight.bind(this)
        this.leftClick = this.moveLeft.bind(this)
    }

    generateItems()
    {
        let items = [];
        let level;

        for (let i = this.state.active - 3; i < this.state.active + 4; i++)
        {
            let index = i
            if (i < 0)
            {
                index = this.state.items.length + i
            }
            else if (i >= this.state.items.length)
            {
                index = i % this.state.items.length
            }
            level = this.state.active - i

            if (this.props.app === "board")
            {
                items.push(<Item key={index} id={this.state.items[index]} level={level} />)
            }
            else
            {
                items.push(<ItemParentillages key={index} id={this.state.items[index]} level={level} />)
            }

        }

        return items
    }

    moveLeft()
    {
        let newActive = this.state.active
        newActive--
        this.setState({
            active: newActive < 0 ? this.state.items.length - 1 : newActive,
            direction: 'left'
        })
    }

    moveRight()
    {
        let newActive = this.state.active
        this.setState({
            active: (newActive + 1) % this.state.items.length,
            direction: 'right'
        })
    }

    componentDidMount()
    {
        const { REACT_APP_API_URL } = process.env;
        let request = '';
        let oldYear = 0;
        if (this.props.app === "board")
        {
            request = REACT_APP_API_URL + "board/" + this.props.yearSelect;
            oldYear = this.props.yearSelect;
        }
        else
        {
            request = REACT_APP_API_URL + "posts/parentillages";
        }

        this.setState({oldYear});

        fetch(request, { "method": "GET"} )
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        items: result
                    });
                },

                (error) => {
                    this.setState({
                        isLoaded: true,
                        error : error
                    });
                }
            )
    };

    componentDidUpdate()
    {
        if (this.state.oldYear !== this.props.yearSelect && this.props.app === "board")
        {
            this.setState({oldYear:  this.props.yearSelect})

            const {REACT_APP_API_URL} = process.env;
            let request = REACT_APP_API_URL + "board/" + this.props.yearSelect;

            fetch(request, {"method": "GET"})
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            isLoaded: true,
                            items: result
                        });
                    },

                    (error) => {
                        this.setState({
                            isLoaded: true,
                            error: error
                        });
                    }
                )
        }
    };

    render()
    {
        const {error, isLoaded} = this.state;

        if (error)
        {
            return (
                <div className="containerNDM margTop">Erreur: {error.message}</div>);
        }
        else if (!isLoaded)
        {
            return (
                <div className="containerNDM margTop">En attente</div>);
        }
        else {
            console.log("this.state.items.length:" + this.state.items.length);
            return (
                <div id="carousel" className="noSelect">
                    <div className="arrow arrow-left" onClick={this.leftClick}>
                        <i className="fa fa-arrow-left"></i>
                    </div>
                    <TransitionGroup transitionName={this.state.direction}>
                        {this.generateItems()}
                    </TransitionGroup>
                    <div className="arrow arrow-right" onClick={this.rightClick}>
                        <i className="fa fa-arrow-right"></i>
                    </div>
                </div>
            )
        }
    }
}

export default Carousel