import React from "react";
import SubstrPost from "../Commun/SubstrPost";
import RequireImage from "../Commun/RequireImage";
import {useHistory} from "react-router";

const TableBoards = props =>
{
    let rows = [];
    const history = useHistory();

    const submitEdition = (event, id) =>
    {
        event.preventDefault();
        history.push('/ndmback/modifier-trombinoscope/' + id);
    }

    props.dataItems.forEach(function (fd)
    {
        let memberId= fd.id;
        let buttonEditon = <button onClick={(e) => submitEdition(e, memberId)} className="button buttonSize buttonManageEvent" type="submit">Editer</button>;

        const directoryBoard = "board";
        rows.push(
            <tr key={fd.id}>
                <td>{fd.firstname}</td>
                <td>{fd.lastname}</td>
                <td className="tdDescription"><SubstrPost contentPost={fd.description} subNumber="100"/></td>
                <td className="tdCenter "><RequireImage srcImage={fd.image} classnameImage="imgTableBoards" repImage={directoryBoard} /></td>
                <td>{fd.year}</td>
                <td className="tdCenter">{buttonEditon}</td>
            </tr>
        )
    });

    return (<tbody>{rows}</tbody>);
}

export default TableBoards