import React, {Fragment, useState} from 'react'
import NavBar from "../Commun/NavBar";
import FloatFooter from "../Commun/FloatFooter";
import {useHistory} from "react-router";
import EmailValidation from "../Commun/EmailValidation";
const {REACT_APP_API_URL} = process.env;

const ForgottenPassword = () =>
{
    const history = useHistory();
    const [emailReinit, setEmailReinit] = useState("");
    const [messageForm, setMessageForm] = useState(null);
    const [errors, setErrors] = useState({ emailReinit: ''});
    const [messageStyle, setMessageStyle] = useState("hidden");

    function generate_token(length)
    {
        //edit the token allowed characters
        const a = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890".split("");
        let b = [];
        for (let i=0; i<length; i++)
        {
            let j = (Math.random() * (a.length-1)).toFixed(0);
            b[i] = a[j];
        }
        return b.join("");
    }

    const handleFileChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;

        switch (name)
        {
            case 'emailReinit':
                setErrors['emailReinit'] = EmailValidation.test(value) ? '' : 'Email is not valid!';
                setEmailReinit(value);
                break;
            default:
                break;
        }
    }

    const handleSubmit = (event) =>
    {
        event.preventDefault();

        let formStatus = 1;
        if (emailReinit === '') { setMessageForm("Le champ email n'est pas rempli"); formStatus = 0 }

        if (formStatus === 1)
        {
            let tokenReinit = generate_token(32);

            let request = REACT_APP_API_URL + "reinit-password";
            let selectFileData = new FormData();

            selectFileData.append('emailReinit', emailReinit);
            selectFileData.append('tokenReinit', tokenReinit);

            const options =
                {
                    method: 'POST',
                    body: selectFileData,
                };
            fetch(request, options)
                .then(response =>
                    {
                        if (response.status === 200)
                        {
                            history.push('/message-reinitialisation-mot-de-passe')
                        }
                        else
                        {
                            setMessageForm("L'adresse email est inconue de l'APEL");
                            setMessageStyle("visible")
                            formStatus = 0
                        }
                    },

                    (error) => {
                        setMessageForm("Erreur lord de la réinitialisation du mot de passe");
                        setMessageStyle("visible")
                        formStatus = 0
                    });
        }
        else
        {
            setMessageStyle("visible");
        }
    }

    return (
        <Fragment>
            <NavBar/>
            <section className="sectionNDM sectionCommissions section100">
                <div className="containerNDM containerCommissions wrapper margTop">
                    <div className='form-wrapper'>
                        <h2>Contacter l'association</h2>
                        <form onSubmit={handleSubmit} noValidate>
                            <div className='emailContact'>
                                <label htmlFor="emailReinit">Email</label>
                                <input type='email' name='emailReinit' onChange={(e) => handleFileChange(e)}/>
                                {errors.emailReinit.length > 0 &&
                                <span className='error'>{errors.emailReinit}</span>}
                            </div>
                            <div className='submit'>
                                <button>Envoyer</button>
                            </div>
                        </form>
                        <div className="messageError" style={{visibility: messageStyle}}>Message: {messageForm}</div>
                    </div>
                </div>
            </section>
            <FloatFooter/>
        </Fragment>
    );
}

export default ForgottenPassword