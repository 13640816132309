import React, { Fragment } from 'react'
import NavBar from "../Commun/NavBar";
import FloatFooter from "../Commun/FloatFooter";
import Post from "../Commun/Post";
import Encart from "./Encart";

const CommissionFetes = () =>
{
    const encart = <Encart />;

    if (encart)
    {
        return (
            <Fragment>
                <NavBar/>
                <section className="sectionFetes section100">
                    <div className="containerFetes margTop">
                        <div className="presentationFetes">La commission fêtes est chargée de l’organisation des événements festifs de notre association ; son rôle est donc d’organiser, d’animer et d’accompagner  les diverses manifestations au sein de notre école. Les principales manifestations sont:
                            <li className="liFetes">Le marché de Noël</li>
                            <li className="liFetes">La Soirée des familles</li>
                            <li className="liFetes">Les chocolats de Pâques</li>
                            <li className="liFetes">La fête de l’école…</li>
                            <li className="liFetes">Accueil des parents etc….</li>
                        </div>
                        <div className="section2Cols">
                            <div className="containerNDM containerFetesLeft margFetes">
                                {encart}
                            </div>
                            <div className="containerNDM containerFetesRight margFetes">
                                <Post index="2" name="commission-fetes" quantity="10"/>
                            </div>
                        </div>
                    </div>
                </section>
                <FloatFooter/>
            </Fragment>
        );
    }
    else
    {
        return (
            <Fragment>
                <NavBar/>
                <section className="sectionNDM sectionCommissions row section section100">
                    <div className="containerNDM containerCommissions margTop">
                        <Post index="2" name="commission-fetes"/>
                    </div>
                </section>
                <FloatFooter/>
            </Fragment>
        );
    }

}

export default CommissionFetes