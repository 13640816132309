import {useCookies} from "react-cookie";
import {useHistory} from "react-router";
import {useEffect} from "react";


const Deconnection = () =>
{
    const [,,removeCookie] = useCookies();
    const history = useHistory();
    useEffect(() =>
    {
        removeCookie('ndmConnected');
        removeCookie('ndmToken');
        history.push('/');
    });

    return null;
}

export default Deconnection