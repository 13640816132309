import React from "react";
import SubstrPost from "../Commun/SubstrPost";
import RequireImage from "../Commun/RequireImage";
import {useHistory} from "react-router";

const TableEventsCols = props =>
{
    let rows = [];
    const history = useHistory();

    const submitEdition = (event, id) =>
    {
        event.preventDefault();
        history.push('/ndmback/modifier-evenement/' + id);
    }

    props.dataItems.forEach(function (fd)
    {
        let statusOnReact = "";
        let bookableOnReact = "";
        if (fd.bookable)
        {
            bookableOnReact = "Réservable";
        }
        else
        {
            bookableOnReact = "Non réservable";
        }

        if (fd.status)
        {
            statusOnReact = "Actif";
        }
        else
        {
            statusOnReact = "Passé";
        }

        let idEvent = fd.id;
        let buttonEditon = <button onClick={(e) => submitEdition(e, idEvent)} className="button buttonSize buttonManageEvent" type="submit">Editer</button>;

        rows.push(
            <tr className="table2" key={fd.slug}>
                <th className="thBackground">Titre</th>
                <td>{fd.name}</td>
                <th>Description</th>
                <td className="tdDescription"><SubstrPost contentPost={fd.description} subNumber="100"/></td>
                <th>Date</th>
                <td className="tdCenter">{new Date(fd.date).toLocaleDateString('fr-FR')}</td>
                <th>Image</th>
                <td className="tdCenter"><RequireImage srcImage={fd.image} classnameImage="imgTableEvents" repImage="event"/></td>
                <th>Plan</th>
                <td className="tdCenter"><RequireImage srcImage={fd.seatingPlan} classnameImage="imgTableEvents" repImage="event"/></td>
                <th>Lieu</th>
                <td>{fd.location}</td>
                <th>Heure</th>
                <td className="tdCenter">{new Date(fd.time).toLocaleTimeString()}</td>
                <th>Durée</th>
                <td className="tdCenter">{new Date(fd.duration).toLocaleTimeString()}</td>
                <th>Réservable</th>
                <td className="tdCenter">{bookableOnReact}</td>
                <th>Statut</th>
                <td className="tdCenter">{statusOnReact}</td>
                <th>Editer</th>
                <td className="tdCenter">{buttonEditon}</td>
            </tr>
        )
    });

    return (<tbody className="tbodyflex">{rows}</tbody>);
}

export default TableEventsCols