import React, {Fragment} from 'react'
import NavBar from "../Commun/NavBar";
import FloatFooter from "../Commun/FloatFooter";

const PasswordModified = () =>
{
    return (
        <Fragment>
            <NavBar/>
            <section className="sectionNDM sectionPost section100">
                <div className="containerNDM containerAdmin margTop">
                    <div className="inscription fullPost">
                        <h1>Mot de passe modifié</h1>
                        <div>Votre mot de passe a été bien été modifié, vous pouvez vous connecter dès maintenant.</div>
                    </div>
                </div>
            </section>
            <FloatFooter/>
        </Fragment>
    );
}

export default PasswordModified