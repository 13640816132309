const {REACT_APP_NB_CARACTERE_RESUME} = process.env;

const SubstrPost = props =>
{
    const newPost = (contentPost) =>
    {
        if (contentPost.length < REACT_APP_NB_CARACTERE_RESUME)
        {
            return contentPost;
        }
        else
        {
            const res0 = contentPost.substr(0, REACT_APP_NB_CARACTERE_RESUME);
            const res1 = res0.split(" ");
            const res2 = res1.length - 1;
            const res3 = res0.length - res1[res2].length;
            const res = res0.substr(0, res3);

            return res + "[...]";
        }
    }

    return(<p className="resumePost" dangerouslySetInnerHTML={{ __html: newPost(props.contentPost) }} />)
}

export default SubstrPost