import React, {useEffect, useState} from 'react'
import TableRow from "./TableRow";

const TableContainer = props =>
{
    const [selectedId] = useState(0);
    const [rows, setRows] = useState([]);
    const [oldRows, setOldRows] = useState([]);
    const [oldInstances, setOldInstances] = useState([]);

    useEffect(() =>
    {
        if (props.dataProps.instances)
        {
            if (oldInstances !== props.dataProps.instances || oldRows !== rows)
            {
                let rowsTemp = [];
                props.dataProps.instances.forEach(function (instance) {
                    (instance.id === {selectedId}) ? instance.selected = true : instance.selected = false;
                    rowsTemp.push(<TableRow instanceProps={instance} key={instance.id} id={instance.id}/>);
                }, this);
                setRows(rowsTemp);
                setOldRows(rowsTemp);
                setOldInstances(props.dataProps.instances);
            }
        }
    });

    return (
        <div>
            <div className="table-responsive">
                <table className="table table-striped tableCreateEvent">
                    <thead><th>Numéro de table</th><th>Type de place</th><th>Nombre de place</th><th className="buttoncolumn">Actions</th></thead>
                    <tbody>{rows}</tbody>
                </table>
            </div>
        </div>
    );
}

export default TableContainer