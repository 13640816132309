import React, {Fragment} from 'react'
import NavBar from "../Commun/NavBar";
import FloatFooter from "../Commun/FloatFooter";

const PasswordReinitMessage = () =>
{
    return (
        <Fragment>
            <NavBar/>
            <section className="sectionNDM sectionPost section100">
                <div className="containerNDM containerAdmin margTop">
                    <div className="inscription fullPost">
                        <h1>Mot de passe oublié</h1>
                        <div>Votre demande de réinitialisation du mot de passe a été prise en compte, pour finaliser la demande merci de cliquer sur le lien dans le mail qui vous a été envoyé.</div>
                    </div>
                </div>
            </section>
            <FloatFooter/>
        </Fragment>
    );
}

export default PasswordReinitMessage