import React from "react";

const TableCategories = props =>
{
    let rows = [];

    props.dataItems.forEach(function (fd)
    {
        rows.push(
            <tr key={fd.id}>
                <td>{fd.categoryName}</td>
                <td>{fd.subCategoryName}</td>
                <td>{fd.sub2CategoryName}</td>
            </tr>
        )
    });

    return (<tbody>{rows}</tbody>);
}

export default TableCategories