import React, { Component, Fragment } from 'react'

class Report extends Component
{
    constructor(props)
    {
        super(props);

        this.state =
            {
                error: null,
                isLoaded: false,
                oldData: [],
                items: []
            };
    }

    componentDidMount()
    {
        const oldData = this.props.data;

        this.setState({oldData});
        this.setState({isLoaded: true});
    }

    componentDidUpdate()
    {
        if (this.state.oldData !== this.props.data)
        {
            const { REACT_APP_API_URL } = process.env;
            let request = REACT_APP_API_URL + "classcouncils/report/class/" + this.props.data.classSelected + "/year/" + this.props.data.yearSelected + "/term/" + this.props.data.termSelected;

            const oldData = this.props.data;
            this.setState({oldData});

            fetch(request, { "method": "GET"} )
                .then(res => res.json())
                .then(
                    (result) => {this.setState({items: result});},
                    (error) => {this.setState({error});}
                )
        }
    }

    render()
    {
        const {error, isLoaded, items} = this.state;

        if (error)
        {
            return <div>Error: {error.message}</div>;
        }
        else if (!isLoaded)
        {
            return <div>Loading...</div>;
        }
        else if (!this.props.data.classSelected)
        {
            return(
                <h1 className="h1CR">Veuillez faire un choix</h1>
            )
        }
        else
        {
            if (items !== "")
            {
                return(
                    <Fragment>
                        {items.map(item => (
                            <div key={item.id}>
                                <div className="titleReport">{item.title}</div>
                                <div className="contentReport">{item.content}</div>
                                <div className="row footerReport">
                                    <div className="col-xs-12 col-sm-4 col-md-4">Classe : {item.ClassCategory.name}</div>
                                    <div className="col-xs-12 col-sm-4 col-md-4">Trimestre : {item.term}</div>
                                    <div className="col-xs-12 col-sm-4 col-md-4">Année : {item.year - 1}/{item.year}</div>
                                </div>
                            </div>
                        ))}
                    </Fragment>
                )
            }
            else
            {
                return(
                    <Fragment>
                        <h1 className="h1CR">Pas de compte-rendu disponible</h1>
                    </Fragment>
                )
            }
        }
    }
}

export default Report