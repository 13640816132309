import React, { Fragment } from 'react'
import NavBar from "../Commun/NavBar";
import Post from "../Commun/Post";
import FloatFooter from "../Commun/FloatFooter";

const CommissionDeveloppementDurable = () =>
{
    return (
        <Fragment>
            <NavBar/>
            <section className="sectionNDM sectionCommissions section100">
                <div className="containerNDM containerCommissions margTop">
                    <div className="presentationFetes">
                        La commission Développement Durable relaie auprès des parents les actions entreprises par les élèves sous l’impulsion des éco-délégués dans le cadre du projet « éco-école » de l'établissement.
                        Elle engage également des actions afin que les évènements organisés par l’Apel NDM (marché de Noël, fête de l’école...) soient plus « vertueux » en termes de développement durable : tri des déchets, utilisation de gobelets réutilisables, achats plus responsables.
                    </div>
                    <Post index="3" name="commission-developpement-durable" quantity="10"/>
                </div>
            </section>
            <FloatFooter/>
        </Fragment>
    );
}

export default CommissionDeveloppementDurable