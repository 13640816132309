import React from 'react'
import {Link} from "react-router-dom";

const FooterBlock = () =>
{
    return (
        <div className="mainFooter">
            <div className="containerFooter">
                <div className="row">
                    <div className="col-xs-12 col-sm-6 col-md-2">
                        <div className="widget">
                            <h5 className="footerTitle footerFont">APEL NDM</h5>
                            <hr/>
                            <p className="footerFont">Acceuillir Protéger Accompagner</p>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-2">
                        <div className="widget">
                            <h5 className="footerTitle footerFont">Site</h5>
                            <hr/>
                            <ul>
                                <li><div className="footerFont"><Link to="/">Accueil</Link></div></li>
                                <li><div className="footerFont"><Link to="/association/presentation">L'association</Link></div></li>
                                <li><div className="footerFont"><Link to="/commissions/commission-communication/actualites">Les commissions</Link></div></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-2">
                        <div className="widget">
                            <h5 className="footerTitle footerFont">Devenir membre</h5>
                            <hr/>
                            <Link className="btn btnFooter" to="/connexion">S'inscrire</Link>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-3">
                        <div className="widget">
                            <h5 className="footerTitle footerFont">Légal</h5>
                            <hr/>
                            <ul>
                                <li><div className="footerFont"><a href="#">Mentions légales</a></div></li>
                                <li><div className="footerFont"><a href="#">Politiques de protection des données personnelles</a></div></li>
                                <li><div className="footerFont"><Link className="aNavlinks aLink" to="contact">Nous contacter</Link></div></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-3">
                        <div className="widget">
                            <h5 className="footerTitle footerFont">Sites partenaires</h5>
                            <hr/>
                            <ul>
                                <li><div className="footerFont"><a href="https://www.notredamedesmissions.fr/">Notre-Dame des missions Saint-Pierre</a></div></li>
                                <li><div className="footerFont"><a href="http://www.rndm.org/">Les soeurs de Notre-Dame des missions</a></div></li>
                                <li><div className="footerFont"><a href="https://www.apel.fr/">L'APEL nationale</a></div></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FooterBlock