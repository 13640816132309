import React, { Component, Fragment } from 'react'
import NavBar from "../Commun/NavBar";
import FloatFooter from "../Commun/FloatFooter";
import Post from "../Commun/Post";

class Home extends Component
{
    render()
    {
        return (
            <Fragment>
                <NavBar/>
                <section className="sectionNDM sectionHome section100">
                    <div className="home">
                        <div className="homeOverlay"></div>
                    </div>
                    <div className="containerNDM margTop">
                        <div >
                            <Post index="0" quantity="10"/>
                        </div>
                    </div>
                </section>
                <FloatFooter/>
            </Fragment>
        );
    }
}

export default Home
