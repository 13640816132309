import React, {Fragment, useState} from 'react'
import NavBar from "../Commun/NavBar";
import FloatFooter from "../Commun/FloatFooter";
import {useHistory, useParams} from "react-router";
const {REACT_APP_API_URL} = process.env;

const PasswordToChange = () =>
{
    let { token } = useParams();
    const history = useHistory();
    const [passwordToChange, setPasswordToChange] = useState(null);
    const [passwordToChange2, setPasswordToChange2] = useState(null)
    const [messageForm, setMessageForm] = useState(null);
    const [errors, setErrors] = useState({ passwordToChange: ''});
    const [messageStyle, setMessageStyle] = useState("hidden");

    const handleFileChange = (event) =>
    {
        event.preventDefault();
        const { name, value } = event.target;

        switch (name)
        {
            case 'passwordToChange':
                setErrors['passwordToChange'] = value.length < 1 ? 'Full Name must be at least 1 characters long!' : '';
                setPasswordToChange(value);
                break;
            case 'passwordToChange2':
                setPasswordToChange2(value);
                break;
            default:
                break;
        }
    }

    const handleSubmit = (event) =>
    {
        event.preventDefault();

        let formStatus = 1;
        if (passwordToChange === '') { setMessageForm("Le champ password n'est pas rempli"); formStatus = 0 }
        if (passwordToChange2 === '') { setMessageForm("Le champ password à resaisir n'est pas rempli"); formStatus = 0 }
        if (passwordToChange2 !== passwordToChange) { setMessageForm("Les 2 mots de passe saisis ne sont pas identiques"); formStatus = 0 }

        if (formStatus === 1)
        {
            let request = REACT_APP_API_URL + "change-password";
            let selectFileData = new FormData();

            selectFileData.append('passwordToChange', passwordToChange);
            selectFileData.append('token', token);

            const options =
                {
                    method: 'POST',
                    body: selectFileData,
                };
            fetch(request, options)
                .then(response =>
                    {
                        if (response.status === 200)
                        {
                            history.push('/mot-de-passe-modifie')
                        }
                        else
                        {
                            setMessageForm("L'adresse email est inconue de l'APEL");
                            setMessageStyle("visible")
                            formStatus = 0
                        }
                    },

                    (error) => {
                        setMessageForm("Le mot de passe n'a pas été modifié");
                        setMessageStyle("visible")
                        formStatus = 0
                    });
        }
        else
        {
            setMessageStyle("visible");
        }
    }


    return (
        <Fragment>
            <NavBar/>
            <section className="sectionNDM sectionCommissions section100">
                <div className="containerNDM containerCommissions wrapper margTop">
                    <div className='form-wrapper'>
                        <h2>Modifier le mot de passe</h2>
                        <form onSubmit={handleSubmit} noValidate>
                            <div className='emailContact'>
                                <label htmlFor="passwordToChange">Email</label>
                                <input type='password' name='passwordToChange' placeholder="Mot de passe" onChange={(e) => handleFileChange(e)}/>
                                {errors.passwordToChange.length > 0 &&
                                <span className='error'>{errors.passwordToChange}</span>}
                            </div>
                            <div className='emailContact'>
                                <label htmlFor="passwordToChange2">Email</label>
                                <input type='password' name='passwordToChange2' placeholder="Resaisir le mot de passe" onChange={(e) => handleFileChange(e)}/>
                            </div>
                            <div className='submit'>
                                <button>Envoyer</button>
                            </div>
                        </form>
                        <div className="messageError" style={{visibility: messageStyle}}>Message: {messageForm}</div>
                    </div>
                </div>
            </section>
            <FloatFooter/>
        </Fragment>
    );
}

export default PasswordToChange