import React from "react";
import {useHistory} from "react-router";

const TableMember = props =>
{
    let rows = [];
    const history = useHistory();

    const submitEdition = (event, id) =>
    {
        event.preventDefault();
        history.push('/ndmback/modifier-role/' + id);
    }

    props.dataItems.forEach(function (fd)
    {
        let categoryId= fd.id;
        let buttonEditon = <button onClick={(e) => submitEdition(e, categoryId)} className="button buttonSize buttonManageEvent" type="submit">Editer</button>;

        let adm = ""
        if (fd.roles.includes('ADMINISTRATOR'))
        {
            adm = "OUI"
        }
        else
        {
            adm = "NON"
        }

        let edi = ""
        if (fd.roles.includes('EDITOR'))
        {
            edi = "OUI"
        }
        else
        {
            edi = "NON"
        }

        let mem = ""
        if (fd.roles.includes('ADMINISTRATOR'))
        {
            mem = "OUI"
        }
        else
        {
            mem = "NON"
        }

        rows.push(
            <tr key={fd.id}>
                <td>{fd.pseudo}</td>
                <td>{fd.firstname}</td>
                <td>{fd.lastname}</td>
                <td>{mem}</td>
                <td>{edi}</td>
                <td>{adm}</td>
                <td className="tdCenter">{buttonEditon}</td>
            </tr>
        )
    });

    return (<tbody>{rows}</tbody>);
}

export default TableMember