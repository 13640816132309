import React from "react";
import SubstrPost from "../Commun/SubstrPost";
import RequireImage from "../Commun/RequireImage";
import {useHistory} from "react-router";

const TableEvents = props =>
{
    let rows = [];
    const history = useHistory();

    const submitEdition = (event, id) =>
    {
        event.preventDefault();
        history.push('/ndmback/modifier-evenement/' + id);
    }

    props.dataItems.forEach(function (fd)
    {
        let statusOnReact = "";
        let bookableOnReact = "";
        if (fd.bookable)
        {
            bookableOnReact = "Réservable";
        }
        else
        {
            bookableOnReact = "Non réservable";
        }

        if (fd.status)
        {
            statusOnReact = "Actif";
        }
        else
        {
            statusOnReact = "Passé";
        }

        let idEvent = fd.id;
        let buttonEditon = <button onClick={(e) => submitEdition(e, idEvent)} className="button buttonSize buttonManageEvent" type="submit">Editer</button>;

        rows.push(
            <tr key={fd.id}>
                <td>{fd.name}</td>
                <td className="tdDescription"><SubstrPost contentPost={fd.description} subNumber="100"/></td>
                <td className="tdCenter">{new Date(fd.date).toLocaleDateString('fr-FR')}</td>
                <td className="tdCenter"><RequireImage srcImage={fd.image} classnameImage="imgTableEvents" repImage="event"/></td>
                <td className="tdCenter"><RequireImage srcImage={fd.seatingPlan} classnameImage="imgTableEvents" repImage="event"/></td>
                <td>{fd.location}</td>
                <td className="tdCenter">{new Date(fd.time).toLocaleTimeString()}</td>
                <td className="tdCenter">{new Date(fd.duration).toLocaleTimeString()}</td>
                <td className="tdCenter">{bookableOnReact}</td>
                <td className="tdCenter">{statusOnReact}</td>
                <td className="tdCenter">{buttonEditon}</td>
            </tr>
        )
    });

    return (<tbody>{rows}</tbody>);
}

export default TableEvents