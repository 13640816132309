import React, { Fragment } from 'react'
import NavBar from "../Commun/NavBar";
import FloatFooter from "../Commun/FloatFooter";
import Post from "../Commun/Post";

const ComptesRendus = () =>
{
    return (
        <Fragment>
            <NavBar/>
            <section className="sectionNDM sectionAssociation section100">
                <div className="containerNDM containerCommissions margTop">
                    <Post index="2" name="comptes-rendus" quantity="1"/>
                </div>
            </section>
            <FloatFooter/>
        </Fragment>
    );
}

export default ComptesRendus