const GetSessionStorage = (sessionStorageKey, defaultValue = null) =>
{
    const stored = sessionStorage.getItem(sessionStorageKey);
    if (!stored)
    {
        return defaultValue;
    }
    else
    {
        return JSON.parse(stored);
    }
};

export default GetSessionStorage