import React, { Fragment } from 'react'
import NavBar from "../Commun/NavBar";
import FloatFooter from "./FloatFooter";

const NotFound = () =>
{
    return (
        <Fragment>
            <NavBar/>
            <section className="sectionCommissions row section100">
                <div className="containerNDM containerAdmin">
                    <h1>Page non trouvée</h1>
                </div>
            </section>
            <FloatFooter/>
        </Fragment>
    );
}

export default NotFound