import React, {Fragment} from "react";
import NavBar from "./NavBar";
import FloatFooter from "./FloatFooter";

const APIError = props =>
{
    return(
        <Fragment>
            <NavBar/>
            <section className="sectionNDM sectionCommissions section100">
                <div className="containerNDM containerCommissions">Erreur: {props.message}</div>
            </section>
            <FloatFooter/>
        </Fragment>
    );
}

export default APIError