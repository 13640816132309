import React, {Component, Fragment} from 'react'
import SubstrPost from "./SubstrPost";
import ChangeDate from "./ChangeDate";
import RequireImage from "./RequireImage";
import { Link } from "react-router-dom";
import Waiting from "./Waiting";
import APIError from "./APIError";
import NavBar from "./NavBar";
import FloatFooter from "./FloatFooter";

class Post extends Component
{
    constructor(props)
    {
        super(props);

        this.state =
            {
                error: null,
                isLoaded: false,
                items: []
            };
    }

    componentDidMount()
    {
        const { REACT_APP_API_URL } = process.env;
        let request = REACT_APP_API_URL + "posts/category";
        let categoryType = '';
        const categoryName =  this.props.name;
        const quantity = this.props.quantity;

        switch(this.props.index)
        {
            case '0': categoryType = "all"; break;
            case '1': categoryType = "category"; break;
            case '2': categoryType = "subCategory"; break;
            case '3': categoryType = "sub2Category"; break;
            default: break;
        }

        let selectFileData = new FormData();

        selectFileData.append('categoryType', categoryType);
        selectFileData.append('categoryName', categoryName);
        selectFileData.append('quantity', quantity);

        const options =
            {
                method: 'POST',
                body: selectFileData,
            };

        fetch(request, options)
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({
                    isLoaded: true,
                    items: result
                });
            },

            (error) => {
                this.setState({
                    isLoaded: true,
                    error
                });
            }
        )
    }

    render()
    {
        const {error, isLoaded, items} = this.state;

        if (error)
        {
            return (<div>Error: {error.message}</div>);
        }
        else if (!isLoaded)
        {
            return (<div>En attente</div>);
        }
        else {
            return(
                <ul className="ulPost">
                    {items.map(item => (

                        <li key={item.id}>
                            <div className="headerPost"></div>
                            <div>
                                <RequireImage srcImage={item.image} classnameImage="imgPostHome" repImage="post"/>
                            </div>
                            <div className="fullPost">
                                <div className="postHeader">
                                    <p className="categoryPost">
                                        {item.category.subCategoryName}
                                    </p>
                                    <h2 className="titlePost">
                                        {item.title}
                                    </h2>
                                </div>
                                <div className="descriptionPost">
                                    <SubstrPost className="resumePost" contentPost={item.content} subNumber="400"/>
                                    <div className="referencePost">
                                        Ecrit par {item.author.pseudo}<ChangeDate dateToChange={item.createAt}/>
                                    </div>
                                </div>
                            </div>
                            <div className="footerPost">
                                <Link to={`/article/${item.slug}`}>LIRE LA SUITE</Link>
                            </div>
                        </li>
                    ))}
                </ul>
            )
        }
    }
}

export default Post