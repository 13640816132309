import React, { Component } from 'react'
import { Document, Page, pdfjs } from "react-pdf";
import {Link} from "react-router-dom";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

class ItemParentillages extends Component
{
    constructor(props)
    {
        super(props)
        this.state = {
            level: this.props.level
        }
    }

    render()
    {

        const className = 'item levelPdf level' + this.props.level;
        const pageNumber = 1;
        const pdf = `/attachement/post/` + this.props.id.attachement;
        const pdfName = this.props.id.attachement;

        return(
            <Link to={`/commissions/commission-communication/affiche-parentillage/${pdfName}`}>
                <div className={className}>
                    <Document file={pdf} >
                        <Page pageNumber={pageNumber} />
                    </Document>
                </div>
            </Link>
        )
    }
}

export default ItemParentillages