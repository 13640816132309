import React, { Fragment } from 'react'
import NavBar from "./NavBar";
import FloatFooter from "./FloatFooter";
import OnePost from "./OnePost";
import {useParams} from "react-router";

function ContainerOnePostMatch()
{
    let { slug } = useParams();

    return (
        <Fragment>
            <NavBar/>
            <section className="sectionNDM sectionPost section100">
                <div className="containerNDM containerAdmin margTop">
                    <OnePost slug={slug} />
                </div>
            </section>
            <FloatFooter/>
        </Fragment>
    );
}

export default ContainerOnePostMatch