import React, { Component } from 'react'
import ChangeDate from "./ChangeDate";
import RequireImage from "./RequireImage";
import RequireAttachement from "./RequireAttachement";

class OnePost extends Component
{
    constructor(props)
    {
        super(props);

        this.state =
            {
                error: null,
                isLoaded: false,
                items: []
            };
    }

    componentDidMount()
    {
        const { REACT_APP_API_URL } = process.env;
        let request = REACT_APP_API_URL + "post/" + this.props.slug;
        
        fetch(request, { "method": "GET"} )
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        items: result
                    });
                },

                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    render()
    {
        const {error, isLoaded, items} = this.state;

        if (error)
        {
            return <div>Error: {error.message}</div>;
        }
        else if (!isLoaded)
        {
            return <div>Loading...</div>;
        }
        else {

            let attachementPart = '';
            if (items[0].attachement)
            {
                attachementPart =   <div className="postAttachPlaceContainer">
                                        <div className="titleAttachPlace">Télécharger PDF</div>
                                        <div className="postAttachPlace">
                                            <RequireAttachement srcAttachement={items[0].attachement} classnameAttachement="imgTablePosts" repAttachement="post"/>
                                        </div>
                                    </div>
            }

            return(
                <div className="OnePostContainer">
                    {items.map(item => (
                        <div  className="fullOnePost" key={item.id}>
                            {attachementPart}
                            <h2 className="titleOnePost">{item.title}</h2>
                            <div className="containerImageCOP">
                                <RequireImage srcImage={item.image} classnameImage="imgOnePostHome" repImage="post"/>
                            </div>
                            <div>
                                <div className="descriptionOnePost">
                                    <p className="contentOnePost" dangerouslySetInnerHTML={{ __html: item.content }} />
                                </div>
                                <div className="referenceOnePost">
                                    Ecrit par {item.author.pseudo}<ChangeDate dateToChange={item.createAt}/>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )
        }
    }
}

export default OnePost