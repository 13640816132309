import React, { Component, Fragment } from 'react'
import NavBar from "../Commun/NavBar";
import FloatFooter from "../Commun/FloatFooter";
import Carousel from "../Commun/Carousel";
import Post from "../Commun/Post";

class Board extends Component
{
    state = {
        error: null,
        isLoaded: false,
        items: [],
        year:0
    };

    componentDidMount()
    {
        const { REACT_APP_API_URL } = process.env;
        let request = REACT_APP_API_URL + "allyear";

        fetch(request, { "method": "GET"} )
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: false,
                        items: result
                    });
                    const year = this.state.items[0].year;

                    this.setState({
                        year: year,
                        isLoaded: true
                    });
                },

                (error) => {
                    this.setState({
                        isLoaded: true,
                        error : error
                    });
                }
            )
    }

    handleChange = event =>
    {
        const year = event.target.value
        this.setState({ year })
    }

    render()
    {
        const {error, isLoaded, items} = this.state;

        if (error)
        {
            return <div>Error: {error.message}</div>;
        }
        else if (!isLoaded)
        {
            return (
                <Fragment>
                    <NavBar/>
                    <section className="sectionNDM sectionAdmin section100">
                        <div className="containerNDM margTop">
                            En attente
                        </div>
                    </section>
                    <FloatFooter/>
                </Fragment>);
        }
        else
        {
            return (
                <Fragment>
                    <NavBar/>
                    <section className="sectionNDM sectionAssociation section100">
                        <div className="containerNDM margTop">
                            <div>
                                <div><h1 className="h1Board" >Votre conseil d'administration</h1></div>
                                <div className="containerChoice">
                                    <div className="input-group input-groupBoard mb-3">
                                        <div className="input-group-prepend">
                                            <label className="input-group-text" htmlFor="inputGroupSelect01">Options</label>
                                        </div>
                                        <select
                                            onChange={this.handleChange}
                                            className="custom-select"
                                            id="inputGroupSelect01">
                                            {items.map(item => (
                                                <option key={item.year}  value={item.year} >{item.year}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <Carousel active={0} yearSelect={this.state.year} app="board"/>
                            </div>
                        </div>
                        <div className="containerPostBoard">
                            <Post index="2" name="conseil-administration" quantity="10"/>
                        </div>
                    </section>
                    <FloatFooter/>
                </Fragment>
            );
        }
    }
}

export default Board