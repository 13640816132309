import React from 'react';
import ReactDOM from 'react-dom';
import './css/Commun/Commun.scss';
import reportWebVitals from './reportWebVitals';

import Home from './Component/Home/Home';
import Connection from './Component/Home/Connection';
import Deconnection from './Component/Home/Deconnection';
import ForgottenPassword from './Component/Home/ForgottenPassword';
import PasswordReinitMessage from './Component/Home/PasswordReinitMessage';
import PasswordToChange from './Component/Home/PasswordToChange';
import PasswordModified from './Component/Home/PasswordModified';

import Admin from "./Component/Admin/Admin";
import CreatePost from "./Component/Admin/CreatePost";
import CreateReport from "./Component/Admin/CreateReport";
import ManagePosts from "./Component/Admin/ManagePosts";
import ManageReports from "./Component/Admin/ManageReports";
import EditPost from "./Component/Admin/EditPost";
import EditReport from "./Component/Admin/EditReport";
import CreateEvent from "./Component/Admin/CreateEvent";
import ManageEvents from "./Component/Admin/ManageEvents";
import EditEvent from "./Component/Admin/EditEvent";
import CreateBoard from "./Component/Admin/CreateBoard";
import ManageBoards from "./Component/Admin/ManageBoards";
import EditBoard from "./Component/Admin/EditBoard";
import CreateCategory from "./Component/Admin/CreateCategory";
import ManageCategory from "./Component/Admin/ManageCategory";
import EditCategory from "./Component/Admin/EditCategory";
import ManageRoles from "./Component/Admin/ManageRoles";
import EditRole from "./Component/Admin/EditRole";

import Association from './Component/Association/Association'
import Board from './Component/Association/Board';
import Statuts from "./Component/Association/Statuts";
import Historique from "./Component/Association/Historique";
import Charte from "./Component/Association/Charte";
import ComptesRendus from './Component/Association/ComptesRendus';
import Investissements from './Component/Association/Investissements';

import Parentillage from './Component/Commissions/Parentillages';
import DisplayPDF from "./Component/Commun/DisplayPDF";
import CommissionPCC from './Component/Commissions/CommissionPCC';
import CommissionFetes from './Component/Commissions/CommissionFetes';
import CommissionCommunication from './Component/Commissions/CommissionCommunication';
import ChartePCC from "./Component/Commissions/ChartePCC";
import CommissionCantine from "./Component/Commissions/CommissionCantine";
import CommissionDeveloppementDurable from "./Component/Commissions/CommissionDeveloppementDurable";
import Benevolat from "./Component/Commissions/Benevolat";
import Photos from "./Component/Commissions/Photos";
import Guide from "./Component/Commissions/Guide";
import Event from "./Component/Commissions/Event";
import VosPCC from "./Component/Commissions/VosPCC";
import Coordonnees from "./Component/Commissions/Coordonnees";
import ClassCouncils from "./Component/Commissions/ClassCouncils";
import Reservation from './Component/Commissions/Reservation';
import Paiement from './Component/Commissions/Paiement';
import PaiementSuccess from './Component/Commissions/PaiementSuccess';

import Contact from './Component/Contact';
import InscriptionMessage from './Component/Home/InscriptionMessage';
import InscriptionValidation from './Component/Home/InscriptionValidation';

import NotFound from './Component/Commun/NotFound';
import ContainerOnePostMatch from './Component/Commun/ContainerOnePostMatch';

import {BrowserRouter, HashRouter, Route, Switch} from "react-router-dom";
import {CookiesProvider} from "react-cookie";

import { createBrowserHistory } from "history";
const hist = createBrowserHistory();

const Root = () => (
    //<HashRouter basename="/apelndm" hashType="hashbang">
    //<BrowserRouter history={hist}>
    <HashRouter hashType="noslash">
        <Switch>
            <Route exact path='/' component={ Home }/>
            <Route exact path='/Connexion' component={ Connection }/>
            <Route exact path='/Deconnexion' component={ Deconnection }/>
            <Route exact path='/mot-de-passe-oublie' component={ ForgottenPassword }/>
            <Route exact path='/modifier-mot-de-passe/:token' component={ PasswordToChange }/>
            <Route exact path='/mot-de-passe-modifie' component={ PasswordModified}/>

            <Route exact path='/ndmback' component={ Admin }/>
            <Route exact path='/ndmback/creer-article' component={ CreatePost }/>
            <Route exact path='/ndmback/gerer-articles' component={ ManagePosts }/>
            <Route exact path='/ndmback/modifier-article/:id' component={ EditPost }/>
            <Route exact path='/ndmback/creer-rapport' component={ CreateReport }/>
            <Route exact path='/ndmback/gerer-rapports' component={ ManageReports }/>
            <Route exact path='/ndmback/modifier-rapport/:id' component={ EditReport }/>
            <Route exact path='/ndmback/creer-evenement' component={ CreateEvent }/>
            <Route exact path='/ndmback/gerer-evenements' component={ ManageEvents }/>
            <Route exact path='/ndmback/modifier-evenement/:id' component={ EditEvent }/>
            <Route exact path='/ndmback/creer-trombinoscope' component={ CreateBoard }/>
            <Route exact path='/ndmback/gerer-trombinoscopes' component={ ManageBoards }/>
            <Route exact path='/ndmback/modifier-trombinoscope/:id' component={ EditBoard }/>
            <Route exact path='/ndmback/creer-categorie' component={ CreateCategory }/>
            <Route exact path='/ndmback/gerer-categories' component={ ManageCategory }/>
            <Route exact path='/ndmback/modifier-categorie/:id' component={ EditCategory }/>
            <Route exact path='/ndmback/gerer-roles' component={ ManageRoles }/>
            <Route exact path='/ndmback/modifier-role/:id' component={ EditRole }/>

            <Route exact path='/presentation' component={ Association }/>

            <Route exact path='/commissions/commission-pcc/actualites' component={ CommissionPCC }/>
            <Route exact path='/commissions/commission-pcc/donner-ses-coordonnees' component={ Coordonnees }/>
            <Route exact path='/commissions/commission-pcc/vos-referents-pcc' component={ VosPCC }/>
            <Route exact path='/commissions/commission-pcc/conseils-de-classes' component={ ClassCouncils }/>
            <Route exact path='/commissions/commission-pcc/charte-pcc' component={ ChartePCC }/>
            <Route exact path='/commissions/commission-pcc/guide-pratique' component={ Guide }/>

            <Route exact path='/commissions/commission-fetes/actualites' component={ CommissionFetes }/>
            <Route exact path='/commissions/commission-fetes/benevolat' component={ Benevolat }/>
            <Route exact path='/commissions/commission-fetes/photos' component={ Photos }/>

            <Route exact path='/commissions/commission-communication/actualites' component={ CommissionCommunication }/>
            <Route exact path='/commissions/commission-communication/affiche-parentillage/:pdfName' component={ DisplayPDF }/>
            <Route exact path='/commissions/commission-communication/parentillage' component={ Parentillage }/>

            <Route exact path='/commissions/commission-cantine' component={ CommissionCantine }/>
            <Route exact path='/commissions/commission-developpement-durable' component={ CommissionDeveloppementDurable }/>

            <Route exact path='/contact' component={ Contact }/>

            <Route exact path='/comptes-rendus' component={ ComptesRendus }/>
            <Route exact path='/investissements' component={ Investissements }/>
            <Route exact path='/conseil-administration' component={ Board }/>
            <Route exact path='/statuts' component={ Statuts }/>
            <Route exact path='/histoire' component={ Historique }/>
            <Route exact path='/charte-educative' component={ Charte }/>
            <Route exact path='/article/:slug' component={ContainerOnePostMatch} />


            <Route exact path='/event/:slug' component={ Event }/>
            <Route exact path='/ma-reservation' component={ Reservation }/>
            <Route exact path='/paiement' component={ Paiement }/>
            <Route exact path='/paiement-success' component={ PaiementSuccess }/>
            <Route exact path='/message-inscription' component={ InscriptionMessage }/>
            <Route exact path='/validation-inscription/:token' component={ InscriptionValidation }/>
            <Route exact path='/message-reinitialisation-mot-de-passe' component={PasswordReinitMessage }/>

            <Route component={ NotFound }/>
        </Switch>
    </HashRouter>
)

ReactDOM.render(
    <CookiesProvider>
        <Root />
    </CookiesProvider>,
    document.getElementById('root'))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
