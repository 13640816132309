import React, {Fragment, useEffect, useRef, useState} from 'react'
import { Editor } from '@tinymce/tinymce-react';
import slug from 'limax';
import NavBar from "../Commun/NavBar";
import FloatFooter from "../Commun/FloatFooter";
import CheckPostField from "../Commun/CheckPostField";
import {useCookies} from "react-cookie";
import {useHistory} from "react-router";
import Waiting from "../Commun/Waiting";
const {REACT_APP_API_URL} = process.env;

const CreatePost = () =>
{
    const history = useHistory();
    const [useOnce1, setUseOnce1] = useState(0);
    const [useOnce2, setUseOnce2] = useState(0);
    const [items1, setItems1] = useState([]);
    const [isLoaded1, setIsLoaded1] = useState(false);
    const [error1, setError1] = useState(null);
    const [items2, setItems2] = useState([]);
    const [isLoaded2, setIsLoaded2] = useState(false);
    const [error2, setError2] = useState(null);
    const [cookies] = useCookies();
    const [postTitle, setPostTitle] = useState('');
    const [postContent, setPostContent] = useState('');
    const [postPicture, setPostPicture] = useState(null);
    const selectedFile=useRef();
    const [postAttachement, setPostAttachement] = useState(null);
    const [postCategory, setPostCategory] = useState(0);
    const [updateError, setUpdateError] = useState(null);
    const [messageStyle, setMessageStyle] = useState("hidden");
    const editorRef = useRef(null);

    useEffect(() =>
    {
        if (useOnce1 === 0)
        {
            const tokenMember = cookies['ndmToken'];

            if (tokenMember)
            {
                let request = REACT_APP_API_URL + "member/get-autorisation/" + tokenMember;

                const optionsAPI =
                    {
                        method: 'GET'
                    };

                fetch(request, optionsAPI)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            setIsLoaded1(true);
                            setItems1(result);
                        },

                        (error) => {
                            setIsLoaded1(true);
                            setError1(error);
                        }
                    )
            }
            setUseOnce1(1);
        }
    });

    useEffect(() =>
    {
        if (useOnce2 === 0)
        {
            let request = REACT_APP_API_URL + "posts/get-category";

            const optionsAPI =
                {
                    method: 'GET'
                };

            fetch(request, optionsAPI)
                .then(res => res.json())
                .then(
                    (result) => {
                        setIsLoaded2(true);
                        setItems2(result);
                    },

                    (error) => {
                        setIsLoaded2(true);
                        setError2(error);
                    }
                )

            setUseOnce2(1);
        }
    })

    const handleSubmit = event =>
    {
        event.preventDefault();

        let formStatus = 1;
        if (postTitle === '') { alert("Le titre de l'article n'est pas défini"); formStatus = 0 }
        if (postContent === '') { alert("La description de l'article n'est pas définie"); formStatus = 0 }
        if (postCategory === 0) { alert("La catégorie de l'article n'est pas défini"); formStatus = 0 }

        const postSlug = slug(postTitle);

        if (formStatus === 1)
        {
            const memberToken = cookies['ndmToken'];
            let request = REACT_APP_API_URL + "add-post";

            let selectFileData = new FormData();

            selectFileData.append('postTitle', postTitle);
            selectFileData.append('postContent', postContent);
            selectFileData.append('postCategory', postCategory);
            selectFileData.append('postPicture', postPicture);
            selectFileData.append('postAttachement', postAttachement);
            selectFileData.append('postSlug', postSlug);
            selectFileData.append('memberToken', memberToken);

            const options =
                {
                    method: 'POST',
                    body: selectFileData,
                };
            fetch(request, options)
                .then(
                    (result) => {
                        history.push('/ndmback/gerer-articles');
                    },

                    (error) => {
                        setMessageStyle("visible")
                        setUpdateError(error);
                    }
                )
        }
    }

    const handleFileChange = event =>
    {
        event.preventDefault();

        const { name } = event.target;
        const file = event.target.files[0];

        if (name === 'postPicture')
        {
            const postPictureCheck = CheckPostField(name, file);
            if (postPictureCheck === 0)
            {
                setPostPicture(file);
            }
        }

        if (name === 'postAttachement')
        {
            const postAttachementCheck = CheckPostField(name, file);
            if (postAttachementCheck === 0)
            {
                setPostAttachement(file);
            }
        }
    }

    const handleFieldChange = event =>
    {
        event.preventDefault();

        const { name, value } = event.target;

        if (name === 'postTitle')
        {
            const postTitleCheck = CheckPostField(name, value);
            if (postTitleCheck === 0)
            {
                setPostTitle(value);
            }
        }

        if (name === 'postCategory')
        {
            const postCategoryCheck = CheckPostField(name, value);
            if (postCategoryCheck === 0)
            {
                setPostCategory(value);
            }
        }
    }

    const handleEditorChange = (content) =>
    {
        const contentCheck = CheckPostField('textarea', content);
        if (contentCheck === 0)
        {
            setPostContent(content);
        }
    }

    if (error2 || error1)
    {
        return <div>Error: {error2.message}</div>;
    }
    else if (!isLoaded2 || !isLoaded1)
    {
        return (<Waiting />);
    }
    else {
        if (items1.status === 0 && (items1.roles.includes('EDITOR') || items1.roles.includes('ADMINISTRATOR')))
        {
            return (
                <Fragment>
                    <NavBar/>
                    <section className="sectionNDM sectionAdmin section100">
                        <div className="containerNDM containerAdmin margTop">
                            <div id="containerBackground">
                                <h1>Ecrire un nouvel article</h1>
                                <form onSubmit={handleSubmit} id="formBackendView" encType="multipart/form-data" className="formFlex" action="">
                                    <div className='postTitle flexFormColCenter'>
                                        <label className="formFont" htmlFor="postTitle">Titre</label>
                                        <div>
                                            <input
                                                type='text'
                                                name='postTitle'
                                                className="postTitle"
                                                onChange={(e) => handleFieldChange(e)}/>
                                        </div>
                                    </div>

                                    <div className='postCategory flexFormColCenter'>
                                        <label className="formFont" htmlFor="postCategory">Catégorie</label>
                                        <select
                                            className="form-control input-sm"
                                            name="postCategory"
                                            onChange={(e) => handleFieldChange(e)}>
                                            <option selected> -- Sélectionner une option -- </option>
                                            {items2.map(item => (
                                                <option key={item.id} value={item.id}>{item.categoryName} - {item.subCategoryName} - {item.sub2CategoryName}</option>
                                                ))}
                                        </select>
                                    </div>

                                    <div className="flexFormColCenter" id="uploadZone">
                                        <label className="formFont" htmlFor="postPicture">Photo</label>
                                        <input
                                            type="file"
                                            id="photo"
                                            name="postPicture"
                                            ref={selectedFile}
                                            onChange={(e) => handleFileChange(e)}/>
                                    </div>

                                    <div className="flexFormColCenter" id="uploadZone">
                                        <label className="formFont" htmlFor="postAttachement">Pièce jointe</label>
                                        <input
                                            type="file"
                                            id="attachement"
                                            name="postAttachement"
                                            onChange={(e) => handleFileChange(e)}/>
                                    </div>

                                    <div className="flexFormColCenter" id="postTextarea">
                                        <label className="formFont" htmlFor="postTextarea">Contenu</label>
                                        <Editor
                                            id="mytextarea"
                                            name="postTextarea"
                                            onInit={(evt, editor) => editorRef.current = editor}
                                            value={postContent}
                                            apiKey='yaysfwznq8jhd2wmq7ovzetookk8gjqqh4yls46m1fp7c8z3'
                                            init={{
                                                height: 500,
                                                menubar: false,
                                                plugins: [
                                                    'advlist autolink lists link image charmap print preview anchor',
                                                    'searchreplace visualblocks code fullscreen',
                                                    'insertdatetime media table paste code help wordcount'
                                                ],
                                                toolbar: 'undo redo | formatselect | ' +
                                                    'bold italic forecolor underline | alignleft aligncenter ' +
                                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                                    'removeformat | help',
                                                forced_root_block : false,
                                                force_br_newlines : false,
                                                force_p_newlines : false,
                                            }}
                                            onEditorChange={handleEditorChange}/>
                                    </div>
                                    <button type="submit" className="button buttonSize">Envoyer</button>
                                    <div className="flexFormColCenter flexFormColCenter" style={{visibility: messageStyle}}>
                                        <label className="messageError">Message: {updateError}</label>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </section>
                    <FloatFooter/>
                </Fragment>
            );
        }
        else
        {
            return (
                <Fragment>
                    <NavBar/>
                    <section className="sectionNDM sectionAdmin section100">
                        <div className="containerNDM containerAdmin margTop">
                            Vous n'êtes pas autorisé à afficher cette page
                        </div>
                    </section>
                    <FloatFooter/>
                </Fragment>);
        }
    }
}

export default CreatePost