import React, { Fragment } from 'react'

const RequireImage = props =>
{
        if (props.srcImage)
        {
            const imageSrc = "/images/" + props.repImage + "/" + props.srcImage;
            const imageBoard = "/images/board/default.jpg";
            const imagePost = "images/post/default.jpg";

            try
            {
                /*return <img className={props.classnameImage} src={require(`../../images/` + props.repImage + `/${props.srcImage}`).default} alt={props.srcImage}/>;*/
                return <img className={props.classnameImage} src={imageSrc} alt={props.srcImage}/>
            }
            catch (err)
            {
                if (props.repImage.substring(0,5) === "board")
                {
                    /*return <img className={props.classnameImage} src={require(`../../images/board/default.jpg`).default} alt="default.jpg"/>;*/
                    return <img className={props.classnameImage} src={imageBoard} alt={props.srcImage}/>
                }
                else
                {
                    /*return <img className={props.classnameImage} src={require(`../../images/` + props.repImage + `/default.jpg`).default} alt="default.jpg"/>;*/
                    return <img className={props.classnameImage} src={imagePost} alt={props.srcImage}/>
                }
            }

        }
        else
        {
            return <Fragment></Fragment>;
        }
}

export default RequireImage