import React, {Fragment, useEffect, useState} from 'react'
import NavBar from "../Commun/NavBar";
import FloatFooter from "../Commun/FloatFooter";
import Report from "./Report";
import ReportPDF from "./ReportPDF";
import RequireAttachement from "../Commun/RequireAttachement";
const { REACT_APP_API_URL } = process.env;

const ClassCouncils = () =>
{
    const [useOnce1, setUseOnce1] = useState(0);
    const [useOnce2, setUseOnce2] = useState(0);

    const [itemsYear, setItemsYear] = useState([]);
    const [itemsClasses, setItemsClasses] = useState([]);

    const [itemsLevel, setItemsLevel] = useState([]);
    const [itemsTerm, setItemsTerm] = useState(["1er", "2ème", "3ème"]);
    const [itemsTermTitle, setItemsTermTitle] = useState("Trimestre");

    const [itemsSelectedClasses, setItemsSelectedClasses] = useState([]);

    const [isLoadedYear, setIsLoadedYear] = useState(false);
    const [isLoadedClasses, setIsLoadedClasses] = useState(false);

    const [errorYear, setErrorYear] = useState(null);
    const [errorClasses, setErrorClasses] = useState(null);

    const [yearSelected, setYearSelected] = useState(0);
    const [termSelected, setTermSelected] = useState(null);
    const [classSelected, setClassSelected] = useState(null);
    const [data, setData] = useState({});
    const [,setDataOk] = useState(false);

    useEffect(() =>
    {
        if (useOnce1 === 0)
        {
            let request1 = REACT_APP_API_URL + "classcouncils/allyears";

            const optionsAPI =
                {
                    method: 'GET'
                };

            fetch(request1, optionsAPI)
                .then(res => res.json())
                .then(
                    (result) =>
                    {
                        setItemsYear(result);
                        setIsLoadedYear(true);
                    },

                    (error) =>
                    {
                        setIsLoadedYear(true);
                        setErrorYear(error);
                    }
                )
            setUseOnce1(1);
        }

        if (useOnce2 === 0)
        {
            let request2 = REACT_APP_API_URL + "classcouncils/allclassescouncils/";

            const optionsAPI =
                {
                    method: 'GET'
                };

            fetch(request2, optionsAPI)
                .then(res => res.json())
                .then(
                    (result) =>
                    {
                        setItemsClasses(result);
                        setIsLoadedClasses(true);
                    },

                    (error) =>
                    {
                        setIsLoadedClasses(true);
                        setErrorClasses(error);
                    }
                )
            setUseOnce2(1);
        }
    })

    const handleChangeLevel = event =>
    {
        const level = event.target.value

        let copyItemsClassesName = [];

        for (let i = 0; i < itemsClasses.length; i++)
        {
            if (itemsClasses[i].level === level)
            {
                copyItemsClassesName.push(itemsClasses[i].name);
            }
        }

        setItemsSelectedClasses(copyItemsClassesName);
        setClassSelected(copyItemsClassesName[0]);

        if (event.target.value.substr(0, 1) === "T" || event.target.value.substr(0, 1) === "1")
        {
            setItemsTerm(["1er", "2ème"]);
            setItemsTermTitle("Semestre");
        }
        else
        {
            setItemsTerm(["1er", "2ème", "3ème"]);
            setItemsTermTitle("Trimestre");
        }
    }

    const handleChangeTerm = event =>
    {
        setTermSelected(event.target.value.substr(0, 1));
    }

    const handleChangeClass = event =>
    {
        setClassSelected(event.target.value);
    }

    const handleChangeYear = event =>
    {
        setYearSelected(event.target.value);
    }

    const handleClick = () =>
    {
        setData({'classSelected' :  classSelected, 'yearSelected' : yearSelected, 'termSelected' : termSelected})
        setDataOk(true);
    }

    if (errorYear)
    {
        return <div>Error: {errorYear.message}</div>;
    }
    else if (errorClasses)
    {
        return <div>Error: {errorClasses.message}</div>;
    }
    else if (!isLoadedYear || !isLoadedClasses)
    {
        return (
            <Fragment>
                <NavBar/>
                <section className="sectionNDM sectionCommissions section100">
                    <div className="containerNDM containerCommissions margTop">
                        En attente
                    </div>
                </section>
                <FloatFooter/>
            </Fragment>);
    }
    else
    {
        if (useOnce1 === 1 && useOnce2 === 1)
        {
            setYearSelected(itemsYear[0].year);
            setTermSelected(1);

            let copyItemsClassesLevel = [];
            let copyItemsClassesName = [];

            for (let i = 0; i < itemsClasses.length; i++)
            {
                copyItemsClassesLevel.push(itemsClasses[i].level);

                if (itemsClasses[i].level === "6ème")
                {
                    copyItemsClassesName.push(itemsClasses[i].name);
                }
            }

            const level = [ ...new Set(copyItemsClassesLevel)];

            setItemsLevel(level);
            setClassSelected(itemsClasses[0].name);
            setItemsSelectedClasses(copyItemsClassesName);

            setUseOnce1(2);
            setUseOnce2(2);
        }

        return (
            <Fragment>
                <NavBar/>
                <section className="sectionCR sectionCommissions section100 section2Cols">
                    <div className="containerNDM containerCouncilLeft margTop">
                        <div><h1>Sélectionnez un compte-rendu</h1></div>
                        <div className="containerChoice">
                            <div className="input-group input-groupClasses mb-3">
                                <div className="input-group-prepend">
                                    <label className="input-group-text input-group-textClasses" htmlFor="inputGroupSelect01">Niveau</label>
                                </div>
                                <select
                                    onChange={handleChangeLevel}
                                    className="custom-select"
                                    id="inputGroupSelect01">
                                    {itemsLevel.map(itemLevel => (
                                        <option key={itemLevel}  value={itemLevel} >{itemLevel}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="input-group input-groupClasses mb-3">
                                <div className="input-group-prepend">
                                    <label className="input-group-text input-group-textClasses" htmlFor="inputGroupSelect01">Classe</label>
                                </div>
                                <select
                                    onChange={handleChangeClass}
                                    className="custom-select"
                                    id="inputGroupSelect02">
                                    {itemsSelectedClasses.map(itemSelectedClass => (
                                        <option key={itemSelectedClass}  value={itemSelectedClass} >{itemSelectedClass}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="input-group input-groupClasses mb-3">
                                <div className="input-group-prepend">
                                    <label className="input-group-text input-group-textClasses" htmlFor="inputGroupSelect01">Année scolaire</label>
                                </div>
                                <select
                                    onChange={handleChangeYear}
                                    className="custom-select"
                                    id="inputGroupSelect03">
                                    {itemsYear.map(itemYear => (
                                        <option key={itemYear.year}  value={itemYear.year} >{itemYear.year - 1}/{itemYear.year}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="input-group input-groupClasses mb-3">
                                <div className="input-group-prepend">
                                    <label className="input-group-text input-group-textClasses" htmlFor="inputGroupSelect01">{itemsTermTitle}</label>
                                </div>
                                <select
                                    onChange={handleChangeTerm}
                                    className="custom-select"
                                    id="inputGroupSelect04">
                                    {itemsTerm.map(itemTerm => (
                                        <option key={itemTerm}  value={itemTerm} >{itemTerm}</option>
                                    ))}
                                </select>
                            </div>
                            <button className="boutonClasses" onClick={handleClick}>Rechercher</button>
                        </div>
                    </div>
                    <div className="containerNDM containerCouncilRight margTopCRR">
                        <ReportPDF data={data} />
                    </div>
                </section>
                <FloatFooter/>
            </Fragment>
        );
    }
}

export default ClassCouncils