import React, {Fragment, useState} from 'react'
import EmailValidation from "../Commun/EmailValidation";
import NavBar from "../Commun/NavBar";
import FloatFooter from "../Commun/FloatFooter";
import {useCookies} from "react-cookie";
import Waiting from "../Commun/Waiting";

const { REACT_APP_API_URL } = process.env;

const Coordonnees = () =>
{
    const [items, setItems] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [useOnce, setUseOnce] = useState(0);
    const [firstname, setFirstname] = useState("");
    const [firstnameChild, setFirstnameChild] = useState("");
    const [lastname, setLastname] = useState("");
    const [lastnameChild, setLastnameChild] = useState("");
    const [classChild, setClassChild] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [messageForm, setMessageForm] = useState(null);
    const [cookies] = useCookies();
    const [errors, setErrors] = useState({ firstname: '', firstnameChild: '', lastname: '', lastnameChild: '', phone: '', email: ''});
    const [messageStyle, setMessageStyle] = useState("hidden");

    useState( () =>
    {
        if (useOnce === 0)
        {
            const { REACT_APP_API_URL } = process.env;
            let request = REACT_APP_API_URL + "classcouncils/allclasses";

            const optionsAPI =
                {
                    method: 'GET'
                };

            fetch(request, optionsAPI)
                .then(res => res.json())
                .then(
                    (result) =>
                    {
                        setIsLoaded(true);
                        setMessageStyle("hidden")
                        setItems(result);
                    },

                    (error) =>
                    {
                        setIsLoaded(true);
                        setMessageStyle("visible")
                        setError(error);
                    }
                )
            setUseOnce(1);
        }
    });

    const handleFileChange = (event) =>
    {
        event.preventDefault();
        const { name, value } = event.target;

        switch (name) {
            case 'firstname':
                setErrors['firstname'] = value.length < 1 ? 'Full Name must be at least 1 characters long!' : '';
                setFirstname(value);
                break;
            case 'firstnameChild':
                setErrors['firstnameChild'] = value.length < 1 ? 'Full Name must be at least 1 characters long!' : '';
                setFirstnameChild(value);
                break;
            case 'lastname':
                setErrors['lastname'] = value.length < 1 ? 'Full Name must be at least 1 characters long!' : '';
                setLastname(value);
                break;
            case 'lastnameChild':
                setErrors['lastnameChild'] = value.length < 1 ? 'Full Name must be at least 1 characters long!' : '';
                setLastnameChild(value);
                break;
            case 'classChild':
                setClassChild(value);
                break;
            case 'phone':
                setErrors['phone'] = value.length < 10 ? 'Full Name must be at least 10 characters long!' : '';
                setPhone(value);
                break;
            case 'email':
                setErrors['email'] = EmailValidation.test(value) ? '' : 'Email is not valid!';
                setEmail(value);
                break;
            default:
                break;
        }
    }

    const handleSubmit = (event) =>
    {
        event.preventDefault();

        let formStatus = 1;
        if (firstname === '') { setMessageForm("Le champ nom n'est pas rempli"); formStatus = 0 }
        if (lastname === '') { setMessageForm("Le champ prénom n'est pas rempli"); formStatus = 0 }
        if (firstnameChild === '') { setMessageForm("Le champ nom de l'enfant n'est pas rempli"); formStatus = 0 }
        if (lastnameChild === '') { setMessageForm("Le champ prénom de l'enfant n'est pas rempli"); formStatus = 0 }
        if (classChild === '') { setClassChild(items[0].name); }
        if (phone === '') { setMessageForm("Le champ téléphone n'est pas rempli"); formStatus = 0 }
        if (email === '') { setMessageForm("Le champ email n'est pas rempli"); formStatus = 0 }

        if (formStatus === 1)
        {
            const memberToken = cookies['ndmToken'];

            let request = REACT_APP_API_URL + "send-mail-contact";
            let selectFileData = new FormData();

            selectFileData.append('firstname', firstname);
            selectFileData.append('memberToken', memberToken);
            selectFileData.append('lastname', lastname);
            selectFileData.append('firstnameChild', firstnameChild);
            selectFileData.append('lastnameChild', lastnameChild);
            selectFileData.append('phone', phone);
            selectFileData.append('email', email);
            selectFileData.append('classChild', classChild);
            selectFileData.append('template', '2091408');

            const options =
                {
                    method: 'POST',
                    body: selectFileData,
                };
            fetch(request, options)
                .then(result =>
                    {
                        if (result.status === 200)
                        {
                            setFirstname("");
                            setLastname("");
                            setFirstnameChild("");
                            setLastnameChild("");
                            setEmail("");
                            setPhone("");
                            setClassChild("");
                            setMessageStyle("visible");
                            setMessageForm("Le message a bien été envoyé");
                        }
                        else
                        {
                            setMessageForm("Le message n'a pas été envoyé. Merci de contacter l'APEL");
                            setMessageStyle("visible")
                            formStatus = 0
                        }},
                    (error) =>
                    {
                        setMessageStyle("visible");
                        setMessageForm(error.message);
                    });
        }
        else
        {
            setMessageStyle("visible");
        }
    }

    if (error)
    {
        return <div>Error: {error.message}</div>;
    }
    else if (!isLoaded)
    {
        return (<Waiting />);
    }
    else
    {
        return(
            <Fragment>
                <NavBar/>
                <section className="sectionNDM sectionCommissions section100">
                    <div className="containerNDM containerCommissions wrapper margTop">
                            <div className="descriptionCoordonnees">
                                <p>Donner ses coordonnées aux PCC</p>

                                <p>Vous êtes parent d’un enfant scolarisé à NDM et vous souhaitez recevoir toutes les informations transmises par les Parents Correspondants de sa Classe :</p>
                                <ul>
                                    <li>Compte-rendu des réunions,</li>
                                    <li>Informations sur la classe,</li>
                                    <li>Compte-rendu des conseils de classe,</li>
                                    <li>etc…</li>
                                </ul>
                                <p>Laissez-nous vos coordonnées afin qu’elles soient transmises aux PCC de la classe.</p>

                                <p>Si votre enfant est en Maternelle ou Primaire vous devez indiquer : <strong>le nom de son professeur des écoles,</strong></p>
                                <p>s’il est en Collège ou en Lycée indiquez <strong>le nom du professeur principal.</strong></p>

                                <p>Vous recevrez un mail vous informant de la prise en compte de vos coordonnées.</p>

                                <p>Si vous avez des enfants dans plusieurs classes vous devez remplir ce formulaire pour chacun de vos enfants.</p>
                            </div>
                            <div className='form-wrapper'>
                                <h2>Donner ses coordonnées</h2>
                                <form onSubmit={handleSubmit} noValidate>
                                    <div className='firstname'>
                                        <label htmlFor="firstname">Prénom</label>
                                        <input type='text' name='firstname' onChange={(e) => handleFileChange(e)}/>
                                        {errors.firstname.length > 0 &&
                                        <span className='error'>{errors.firstname}</span>}
                                    </div>
                                    <div className='lastname'>
                                        <label htmlFor="lastname">Nom</label>
                                        <input type='text' name='lastname' onChange={(e) => handleFileChange(e)}/>
                                        {errors.lastname.length > 0 &&
                                        <span className='error'>{errors.lastname}</span>}
                                    </div>
                                    <div className='phone'>
                                        <label htmlFor="phone">Téléphone (portable de préférence)</label>
                                        <input type='text' name='phone' onChange={(e) => handleFileChange(e)}/>
                                        {errors.phone.length > 0 &&
                                        <span className='error'>{errors.phone}</span>}
                                    </div>
                                    <div className='email'>
                                        <label htmlFor="email">Email</label>
                                        <input type='email' name='email' onChange={(e) => handleFileChange(e)}/>
                                        {errors.email.length > 0 &&
                                        <span className='error'>{errors.email}</span>}
                                    </div>
                                    <div className='firstname'>
                                        <label htmlFor="firstnameChild">Prénom de l'enfant</label>
                                        <input type='text' name='firstnameChild' onChange={(e) => handleFileChange(e)}/>
                                        {errors.firstnameChild.length > 0 &&
                                        <span className='error'>{errors.firstnameChild}</span>}
                                    </div>
                                    <div className='lastname'>
                                        <label htmlFor="lastnameChild">Nom de l'enfant</label>
                                        <input type='text' name='lastnameChild' onChange={(e) => handleFileChange(e)}/>
                                        {errors.lastnameChild.length > 0 &&
                                        <span className='error'>{errors.lastnameChild}</span>}
                                    </div>
                                    <div className='classChild'>
                                        <label htmlFor="classChild">Classe de l'enfant</label>
                                        <select name='classChild' onChange={(e) => handleFileChange(e)}>
                                            {items.map(item => (
                                                <option key={item.id} value={item.name}>{item.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='submit'>
                                        <button>Envoyer</button>
                                    </div>
                                </form>
                                <div className="messageError" style={{visibility: messageStyle}}>Information: {messageForm}</div>
                            </div>
                        </div>
                    </section>
                <FloatFooter/>
            </Fragment>
        )
    }
}

export default Coordonnees