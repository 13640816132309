import React, { useEffect, useState } from 'react'
import SubstrPost from "../Commun/SubstrPost";
import ChangeDate from "../Commun/ChangeDate";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import RequireImage from "../Commun/RequireImage";

const Encart = () =>
{
    const [useOnce, setUseOnce] = useState(0);
    const [items, setItems] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() =>
    {
        if (useOnce === 0)
        {
            const { REACT_APP_API_URL } = process.env;
            let request = REACT_APP_API_URL + "events/open";

            const optionsAPI =
                {
                    method: 'GET'
                };

            fetch(request, optionsAPI)
                .then(res => res.json())
                .then(
                    (result) => {
                        setIsLoaded(true);
                        setItems(result);
                    },

                    (error) => {
                        setIsLoaded(true);
                        setError(error);
                    }
                )
            setUseOnce(1);
        }
    })

    if (error)
    {
        return <div>Error: {error.message}</div>;
    }
    else if (!isLoaded)
    {
        return <div>Loading...</div>;
    }
    else {
        if (items)
        {
            return (
                <ul className="ulEncart">
                    {items.map(item => (

                        <li key={item.id}>
                            <div className="topEncart"></div>
                            <RequireImage srcImage={item.image} classnameImage="imgEncart" repImage="event"/>
                            <div className="fullPost">
                                <div className="postHeader">
                                    <h2 className="titlePost">
                                        {item.name}
                                    </h2>
                                </div>
                                <div className="dateEventContainerhigh">
                                    <div className="dateEventContainer">
                                        <div className="dateEvent">
                                            <ChangeDate dateToChange={item.date} typeDate="Event"/>
                                        </div>
                                        <div className="dateEventBottom">
                                            <div className="dateEventWhite"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="descriptionPostEncart">
                                    <p className="resumePost">
                                        <SubstrPost className="resumePost" contentPost={item.description}
                                                    subNumber="400"/>
                                    </p>
                                </div>
                            </div>
                            <div className="footerEncart">
                                <Link to={`/event/${item.slug}`}>LIRE LA SUITE</Link><HashLink
                                to={`/event/${item.slug}#reservation`}>RESERVER</HashLink>
                            </div>
                        </li>
                    ))}
                </ul>
            )
        }
        else
        {
            return null;
        }
    }
}

export default Encart