import React, {Fragment, useEffect, useRef, useState} from 'react'
import NavBar from "../Commun/NavBar";
import FloatFooter from "../Commun/FloatFooter";
import validator from "validator";
import { useCookies } from 'react-cookie'
import { useHistory } from "react-router";
import ReCAPTCHA from "react-google-recaptcha";
import {Link} from "react-router-dom";

const {REACT_APP_KEY_CAPTCHA_PUBLIC} = process.env;
const {REACT_APP_API_URL} = process.env;

const Connection = () =>
{
    const [,setCookie] = useCookies();
    const history = useHistory();
    const captchaRef = useRef(null)

    const [signIn, setSignIn] = useState(true);
    const [valid, setValid] = useState(false);
    const [messageStyleI, setMessageStyleI] = useState("hidden")
    const [messageStyleC, setMessageStyleC] = useState("hidden")
    const [messageFormInscription, setMessageFormInscription] = useState(null);
    const [messageFormConnection, setMessageFormConnection] = useState(null);
    const [inscriptionEmail, setInscriptionEmail] = useState(null);
    const [inscriptionPseudo, setInscriptionPseudo] = useState(null);
    const [inscriptionPassword, setInscriptionPassword] = useState(null);
    const [inscriptionPassword2, setInscriptionPassword2] = useState(null);
    const [inscriptionLastname, setInscriptionLastname] = useState(null);
    const [inscriptionFirstname, setInscriptionFirstname] = useState(null);

    useEffect( () => { });

    function generate_token(length)
    {
        //edit the token allowed characters
        const a = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890".split("");
        let b = [];
        for (let i=0; i<length; i++)
        {
            let j = (Math.random() * (a.length-1)).toFixed(0);
            b[i] = a[j];
        }
        return b.join("");
    }

    const handleChangeEmail = event =>
    {
        const data  = event.target.value;

        if (validator.isEmail(data))
        {
            setInscriptionEmail(event.target.value);
            setValid(true);
        }
        else
        {
            setValid(false);
        }
    }

    const handleSubmitConnection = event =>
    {
        event.preventDefault();

        const connectionEmail = event.target.connectionEmail.value;
        const connectionPassword = event.target.connectionPassword.value;

        let formStatus = 1;

        if (connectionEmail === '') { setMessageFormConnection("L'email est vide"); formStatus = 0 }
        if (connectionPassword === '') { setMessageFormConnection("Le mot de passe est vide"); formStatus = 0 }

        if (formStatus === 1)
        {
            let request = REACT_APP_API_URL + "connexion";

            const options =
                {
                    method: 'POST',
                    body: JSON.stringify({
                        email: connectionEmail,
                        password: connectionPassword,
                    }),
                    headers: { 'Content-Type': 'application/json' }
                };

            fetch(request, options)

                .then(res => res.json())
                .then(
                    (result) => {

                        switch (result.status)
                        {
                            case 0 :
                                setMessageStyleC("hidden");
                                setMessageFormConnection("");
                                break;
                            case 1 :
                                setCookie('ndmConnected', 1, {path: "/"});
                                setCookie('ndmToken', result.token,{path: "/"});
                                history.push('/');
                                break;
                            case 2 :
                                setMessageStyleC("visible");
                                setMessageFormConnection("l'identifiant ou le mot de passe renseigné n'est pas correct. Merci de réessayer.");
                                break;
                            default:
                                setMessageStyleC("visible");
                                setMessageFormConnection("l'identifiant ou le mot de passe renseigné n'est pas correct. Merci de réessayer.");
                                break;
                        }
                    },
                );
        }
        else
        {
            setMessageStyleC("visible");
        }
    }

    const handleSignIn = () =>
    {
        setSignIn(false);
    }

    const handleSignUp = () =>
    {
        setSignIn(true);
    }

    const handleChangeField = (event) =>
    {
        event.preventDefault();
        const { name, value } = event.target;

        switch (name) {
            case 'inscriptionFirstname':
                setInscriptionFirstname(value);
                break;
            case 'inscriptionLastname':
                setInscriptionLastname(value);
                break;
            case 'inscriptionEmail':
                setInscriptionEmail(value);
                break;
            case 'inscriptionPseudo':
                setInscriptionPseudo(value);
                break;
            case 'inscriptionPassword':
                setInscriptionPassword(value);
                break;
            case 'inscriptionPassword2':
                setInscriptionPassword2(value);
                break;
            default:
                break;
        }
    }

    const handleSubmitInscription = event =>
    {
        event.preventDefault();
        const tokenCaptcha = captchaRef.current.getValue();
        captchaRef.current.reset();

        let formStatus = 1;

        if (inscriptionEmail === '') { setMessageFormInscription("L'email est vide"); formStatus = 0 }
        if (inscriptionPassword === '') { setMessageFormInscription("Le mot de passe est vide"); formStatus = 0 }
        if (inscriptionPassword !== inscriptionPassword2) { setMessageFormInscription("Les mots de passe ne sont pas identiques"); formStatus = 0 }
        if (inscriptionLastname === '') { setMessageFormInscription("Le nom est vide"); formStatus = 0 }
        if (inscriptionFirstname === '') { setMessageFormInscription("Le prénom est vide"); formStatus = 0 }
        if (!valid) { setMessageFormInscription("L'email n'est pas valide'"); formStatus = 0 }

        if (formStatus === 1)
        {
            let requestCaptcha = REACT_APP_API_URL + "captcha";
            let selectFileData = new FormData();

            selectFileData.append('tokenCaptcha', tokenCaptcha);

            const optionsCaptcha =
                {
                    method: 'POST',
                    body: selectFileData,
                };

            fetch(requestCaptcha, optionsCaptcha)
                .then(response =>
                    {
                        if (response.status === 200)
                        {
                            handleSubmitAfterCaptcha()
                        }
                        else
                        {
                            setMessageFormInscription("Erreur captcha");
                            setMessageStyleI("visible")
                            formStatus = 0
                            console.log(response.status)
                        }

                    },

                    (error) => {
                        setMessageFormInscription("Erreur captcha");
                        setMessageStyleI("visible")
                        formStatus = 0
                        console.log(error)
                    });
        }
        else
        {
            setMessageStyleI("visible");
        }
    }

    const handleSubmitAfterCaptcha = () =>
    {
        let request = REACT_APP_API_URL + "inscription";

        let token = generate_token(32);
        const options =
            {
                method: 'POST',

                body: JSON.stringify({
                    email: inscriptionEmail,
                    password: inscriptionPassword,
                    lastname: inscriptionLastname,
                    firstname: inscriptionFirstname,
                    pseudo: inscriptionPseudo,
                    token: token,
                    template: 2091408,
                })
            };
        fetch(request, options)
            .then(response =>
                {
                    if (response.status === 201)
                    {
                        history.push('/message-inscription');
                    }
                    else
                    {
                        setMessageStyleI("visible")
                        setMessageFormInscription(response.content);
                    }
                },

                (error) => {
                    setMessageStyleI("visible")
                    setMessageFormInscription(error);
                });
    }

    return (
        <Fragment>
            <NavBar/>
            <section className="sectionNDM sectionAdmin section100 zama-form">
                <div className="containerNDM containerAdmin margTop">
                    <div className={`container ${!signIn ? 'right-panel-active' : ''}`} id="container">
                        <div className="form-container sign-up-container">
                            <form onSubmit={handleSubmitInscription}>
                                <h1>Créer un compte</h1>
                                <input type="text" name="inscriptionLastname" placeholder="Nom" onChange={handleChangeField} />
                                <input type="text" name="inscriptionFirstname" placeholder="Prénom" onChange={handleChangeField} />
                                <input type="text" name="inscriptionPseudo" placeholder="Pseudo" onChange={handleChangeField} />
                                <input type="email" name="inscriptionEmail" placeholder="Email"  onChange={handleChangeEmail} />
                                <input type="password" name="inscriptionPassword" placeholder="Mot de passe" onChange={handleChangeField} />
                                <input type="password" name="inscriptionPassword2" placeholder="Resaisir le mot de passe" onChange={handleChangeField} />
                                <ReCAPTCHA sitekey={REACT_APP_KEY_CAPTCHA_PUBLIC} ref={captchaRef} />
                                <button>Inscription</button>
                                <div className="messageError" style={{visibility: messageStyleI}}>Message: {messageFormInscription}</div>
                            </form>
                        </div>
                        <div className="form-container sign-in-container">
                            <form onSubmit={handleSubmitConnection}>
                                <h1>Se connecter</h1>
                                <input type="email" name="connectionEmail" placeholder="Email" />
                                <input type="password" name="connectionPassword" placeholder="Mot de passe" />

                                <Link to="/mot-de-passe-oublie">Vous avez oublié votre mot de passe ?</Link>
                                <button>Connexion</button>
                                <div className="messageError" style={{visibility: messageStyleC}}>Message: {messageFormConnection}</div>
                            </form>
                        </div>
                        <div className="overlay-container">
                            <div className="overlay">
                                <div className="overlay-panel overlay-left">
                                    <h1>Content de vous revoir!</h1>
                                    <p>Pour rester connecté avec nous, veuillez vous connecter avec vos informations personnelles</p>
                                    <button className="ghost" onClick={handleSignUp}>Connexion</button>
                                </div>
                                <div className="overlay-panel overlay-right">
                                    <h1>Bonjour !</h1>
                                    <p>Entrez vos données personnelles et commencez votre voyage avec nous</p>
                                    <button className="ghost" onClick={handleSignIn}>Inscription</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <FloatFooter/>
        </Fragment>
    );
}

export default Connection