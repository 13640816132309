const CheckPostField = (name, value) =>
{
    if (value)
    {
        switch (name)
        {
            case 'postCategory':
            case 'reportYear':
            case 'reportClass':
            case 'number':
                if(isNaN(value) === true)
                {
                    return 1;
                }
                else if(value > 0)
                {
                    return 0;
                }
                else
                {
                    return 1;
                }

            case 'textarea':
            case 'category1':
            case 'category2':
            case 'category3':
            case 'reportCategory':
            case 'firstname':
            case 'lastname':
            case 'name':
            case 'reportTerm':
            case 'postTitle':
            case 'reportTitle':
            case 'eventTime':
            case 'eventDuration':
            case 'eventDate':
            case 'eventDescription':
            case 'eventName':
            case 'eventLocation':
                if (value)
                {
                    return 0;
                }
                else
                {
                    return 1;
                }

            case 'postPicture':
            case 'boardPicture':
            case 'picture':
            case 'postAttachement':
            case 'reportAttachement':
            case 'eventSeatingPlan':
            case 'eventPicture':
                if (value)
                {
                    return 0;
                }
                else
                {
                    return 1;
                }

            default:
                return 1;
        }
    }
    else
    {
        return 1;
    }
}

export default CheckPostField