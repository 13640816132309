import React, { Fragment } from 'react'
import NavBar from "../Commun/NavBar";
import Post from "../Commun/Post";
import FloatFooter from "../Commun/FloatFooter";

const CommissionCantine = () =>
{
    return (
        <Fragment>
            <NavBar/>
            <section className="sectionNDM sectionCommissions section100">
                <div className="containerNDM containerCommissions margTop">
                    <Post index="3" name="commission-cantine" quantity="10"/>
                </div>
            </section>
            <FloatFooter/>
        </Fragment>
    );
}

export default CommissionCantine