import React, {Fragment, useEffect, useState} from 'react'
import NavBar from "../Commun/NavBar";
import { Link } from 'react-router-dom'
import {useCookies} from "react-cookie";
import FloatFooter from "../Commun/FloatFooter";
const {REACT_APP_API_URL} = process.env;

const Admin = () =>
{
    const directory = 'admin'
    const imagePost = 'article.jpg'
    const imageEvenement = 'evenement.jpg'
    const imageTrombinoscope = 'trombinoscope.png'
    const imageCategorie = 'categorie.png'
    const imageRole = 'role.png'
    const imageReport = 'report.png'

    const [useOnce, setUseOnce] = useState(0);
    const [items, setItems] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [cookies] = useCookies();

    useEffect(() =>
    {
        if (useOnce === 0)
        {
            const tokenMember = cookies['ndmToken'];

            if (tokenMember)
            {
                let request = REACT_APP_API_URL + "member/get-autorisation/" + tokenMember;

                const optionsAPI =
                    {
                        method: 'GET'
                    };

                fetch(request, optionsAPI)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            setIsLoaded(true);
                            setItems(result);
                        },

                        (error) => {
                            setIsLoaded(true);
                            setError(error);
                        }
                    )
            }
            setUseOnce(1);
        }
    });

    if (error)
    {
        return <div>Error: {error.message}</div>;
    }
    else
    {
        if (!isLoaded)
        {
            return (
                <Fragment>
                    <NavBar/>
                    <section className="sectionNDM sectionAdmin row section100">
                        <div className="containerNDM containerAdmin margTop">
                            En attente
                        </div>
                    </section>
                    <FloatFooter/>
                </Fragment>
            )
        }
        else
        {
            if (items.status === 0 && (items.roles.includes('EDITOR') || items.roles.includes('ADMINISTRATOR')))
            {
                return (
                    <Fragment>
                        <NavBar/>
                        <section className="sectionNDM sectionAdmin row section100">
                            <div className="containerNDM containerAdmin margTop">
                                <div className="adminCreation">
                                    <h1>Création</h1>
                                    <div className="adminMenuLink">
                                        <Link className="cardAdmin" to="/ndmback/creer-article">
                                            <img className="adminImage" alt=""
                                                 src={require(`../../images/` + directory + `/${imagePost}`).default}/>
                                            <div className="divAdminImage">Ecrire un nouvel article</div>
                                        </Link>
                                        <Link className="cardAdmin" to="/ndmback/creer-rapport">
                                            <img className="adminImage" alt=""
                                                 src={require(`../../images/` + directory + `/${imageReport}`).default}/>
                                            <div className="divAdminImage">Ecrire un nouveau rapport de conseil</div>
                                        </Link>
                                        <Link className="cardAdmin" to="/ndmback/creer-evenement">
                                            <img className="adminImage" alt=""
                                                 src={require(`../../images/` + directory + `/${imageEvenement}`).default}/>
                                            <div className="divAdminImage">Créer un nouvel événement</div>
                                        </Link>
                                        <Link className="cardAdmin" to="/ndmback/creer-trombinoscope">
                                            <img className="adminImage" alt=""
                                                 src={require(`../../images/` + directory + `/${imageTrombinoscope}`).default}/>
                                            <div className="divAdminImage">Créer un nouveau trombinoscope</div>
                                        </Link>
                                        <Link className="cardAdmin" to="/ndmback/creer-categorie">
                                            <img className="adminImage" alt=""
                                                 src={require(`../../images/` + directory + `/${imageCategorie}`).default}/>
                                            <div className="divAdminImage">Créer une nouvelle categorie</div>
                                        </Link>
                                    </div>
                                </div>
                                <div className="adminManagement">
                                    <h1>Gestion</h1>
                                    <div className="adminMenuLink">
                                        <Link className="cardAdmin" to="/ndmback/gerer-articles">
                                            <img className="adminImage" alt=""
                                                 src={require(`../../images/` + directory + `/${imagePost}`).default}/>
                                            <div className="divAdminImage">Gérer les articles</div>
                                        </Link>
                                        <Link className="cardAdmin" to="/ndmback/gerer-rapports">
                                            <img className="adminImage" alt=""
                                                 src={require(`../../images/` + directory + `/${imageReport}`).default}/>
                                            <div className="divAdminImage">Gérer les rapports de conseil</div>
                                        </Link>
                                        <Link className="cardAdmin" to="/ndmback/gerer-evenements">
                                            <img className="adminImage" alt=""
                                                 src={require(`../../images/` + directory + `/${imageEvenement}`).default}/>
                                            <div className="divAdminImage">Gérer les événements</div>
                                        </Link>
                                        <Link className="cardAdmin" to="/ndmback/gerer-trombinoscopes">
                                            <img className="adminImage" alt=""
                                                 src={require(`../../images/` + directory + `/${imageTrombinoscope}`).default}/>
                                            <div className="divAdminImage">Gérer les trombinoscopes</div>
                                        </Link>
                                        <Link className="cardAdmin" to="/ndmback/gerer-categories">
                                            <img className="adminImage" alt=""
                                                 src={require(`../../images/` + directory + `/${imageCategorie}`).default}/>
                                            <div className="divAdminImage">Gérer les catégories</div>
                                        </Link>
                                        <div>
                                            <Link className="cardAdmin" to="/ndmback/gerer-roles">
                                                <img className="adminImage" alt=""
                                                     src={require(`../../images/` + directory + `/${imageRole}`).default}/>
                                                <div className="divAdminImage">Gérer les rôles</div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <FloatFooter/>
                    </Fragment>
                );
            }
            else
            {
                return (
                    <Fragment>
                        <NavBar/>
                        <section className="sectionNDM sectionAdmin row section100">
                            <div className="containerNDM containerAdmin margTop">
                                Vous n'êtes pas autorisé à afficher cette page
                            </div>
                        </section>
                        <FloatFooter/>
                    </Fragment>);
            }
        }
    }
}

export default Admin