import React, { Fragment } from 'react'

const RequireAttachement = props =>
{
    if (props.srcAttachement)
    {
        const attachementSrc = "/attachement/" + props.repAttachement + "/" + props.srcAttachement;
        const imageAttachement = "/images/fichier.png";

        try
        {
            return <a className="aLink firstPartLogo" href={attachementSrc} target="_blank" download><img className="imgAttachement" alt="piece jointe" src={imageAttachement}/></a>
        }
        catch (err)
        {
            return <Fragment></Fragment>;
        }

    }
    else
    {
        return <Fragment></Fragment>;
    }
}

export default RequireAttachement