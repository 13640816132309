import React from 'react'
import FooterBlock from "./FooterBlock";

const FloatFooter = () =>
{
    return (
        <footer id="footer">
            <FooterBlock />
        </footer>
    );
}

export default FloatFooter