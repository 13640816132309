import React, {Fragment, useEffect, useRef, useState} from 'react'
import NavBar from "../Commun/NavBar";
import FloatFooter from "../Commun/FloatFooter";
import { useCookies } from "react-cookie";
import { useParams } from 'react-router-dom';
import { useHistory } from "react-router";
import CheckPostField from "../Commun/CheckPostField";
import { Editor } from "@tinymce/tinymce-react";
import RequireImage from "../Commun/RequireImage";

const { REACT_APP_API_URL } = process.env;

const EditEvent = () =>
{
    const { id } = useParams()
    const [cookies] = useCookies();
    const editorRef = useRef(null);
    const history = useHistory();

    const [useOnce1, setUseOnce1] = useState(0);
    const [useOnce2, setUseOnce2] = useState(0);
    const [items1, setItems1] = useState([]);
    const [isLoaded1, setIsLoaded1] = useState(false);
    const [error1, setError1] = useState(null);
    const [, setItems2] = useState([]);
    const [isLoaded2, setIsLoaded2] = useState(false);
    const [error2, setError2] = useState(null);
    const [updateError, setUpdateError] = useState(null);
    const [messageStyle, setMessageStyle] = useState("hidden");

    const [eventId, setEventId] = useState(0);
    const [eventName, setEventName] = useState('');
    const [eventDescription, setEventDescription] = useState('');
    const [eventPicture, setEventPicture] = useState('');
    const selectedFile=useRef();
    const [eventSeatingPlan, setEventSeatingPlan] = useState('');
    const [eventDate, setEventDate] = useState(null);
    const [eventLocation, setEventLocation] = useState('');
    const [eventTime, setEventTime] = useState(null);
    const [eventDuration, setEventDuration] = useState(null);

    useEffect(() =>
    {
        if (useOnce1 === 0)
        {
            const tokenMember = cookies['ndmToken'];

            if (tokenMember)
            {
                let request = REACT_APP_API_URL + "member/get-autorisation/" + tokenMember;

                const optionsAPI =
                    {
                        method: 'GET'
                    };

                fetch(request, optionsAPI)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            setIsLoaded1(true);
                            setItems1(result);
                        },

                        (error) => {
                            setIsLoaded1(true);
                            setError1(error);
                        }
                    )
            }
            setUseOnce1(1);
        }
    });

    useEffect(() =>
    {
        if (useOnce2 === 0)
        {
            let request = REACT_APP_API_URL + "event/edit/" + id;

            const optionsAPI =
                {
                    method: 'GET'
                };

            fetch(request, optionsAPI)
                .then(res => res.json())
                .then(
                    (result) => {
                        setEventId(id);
                        setIsLoaded2(true);
                        setItems2(result);
                        setEventName(result[0].name);
                        setEventDescription(result[0].description);
                        setEventDate(result[0].date);
                        setEventLocation(result[0].location);
                        setEventTime(result[0].time);
                        setEventDuration(result[0].duration);
                        setEventPicture(result[0].image);
                        setEventSeatingPlan(result[0].seatingPlan);
                    },

                    (error) => {
                        setIsLoaded2(true);
                        setError2(error);
                    }
                )

            setUseOnce2(1);
        }
    })

    const handleSubmit = event =>
    {
        event.preventDefault();

        let formStatus = 1;
        if (eventName === '') { alert("Le titre de l'article n'est pas défini"); formStatus = 0 }
        if (eventDescription === '') { alert("La description de l'article n'est pas définie"); formStatus = 0 }

        if (formStatus === 1)
        {
            const memberToken = cookies['ndmToken'];
            const { REACT_APP_API_URL } = process.env;
            let request = REACT_APP_API_URL + "update-event";
            let selectFileData = new FormData();

            selectFileData.append('eventId', eventId);
            selectFileData.append('eventName', eventName);
            selectFileData.append('eventDate', eventDate);
            selectFileData.append('eventDescription', eventDescription);
            selectFileData.append('eventLocation', eventLocation);
            selectFileData.append('eventDuration', eventDuration);
            selectFileData.append('eventTime', eventTime);
            selectFileData.append('eventPicture', eventPicture);
            selectFileData.append('eventPlan', eventSeatingPlan);
            selectFileData.append('memberToken', memberToken);

            const options =
                {
                    method: 'POST',
                    body: selectFileData,
                };
            fetch(request, options)
                .then(
                    (result) => {
                        setUpdateError('');
                        history.push('/ndmback');
                    },

                    (error) => {
                        setMessageStyle("visible")
                        setUpdateError(error);
                    }
                )
        }
    }

    const handleFileChange = event =>
    {
        event.preventDefault();

        const { name } = event.target;
        const file = event.target.files[0];

        if (name === 'eventPicture')
        {
            const eventPictureCheck = CheckPostField(name, file);
            if (eventPictureCheck === 0)
            {
                setEventPicture(file);
            }
        }

        if (name === 'eventSeatingPlan')
        {
            const eventSeatingPlanCheck = CheckPostField(name, file);
            if (eventSeatingPlanCheck === 0)
            {
                setEventSeatingPlan(file);
            }
        }
    }

    const handleEditorChange = (content) =>
    {
        const contentCheck = CheckPostField('textarea', content);
        if (contentCheck === 0)
        {
            setEventDescription(content);
        }
    }

    const handleFieldChange = event =>
    {
        event.preventDefault();

        const { name, value } = event.target;

        if (name === 'eventName')
        {
            const eventNameCheck = CheckPostField(name, value);
            if (eventNameCheck === 0)
            {
                setEventName(value);
            }
        }

        if (name === 'eventLocation')
        {
            const eventLocationCheck = CheckPostField(name, value);
            if (eventLocationCheck === 0)
            {
                setEventLocation(value);
            }
        }

        if (name === 'eventDate')
        {
            const eventDateCheck = CheckPostField(name, value);
            if (eventDateCheck === 0)
            {
                setEventDate(value);
            }
        }

        if (name === 'eventTime')
        {
            const eventTimeCheck = CheckPostField(name, value);
            if (eventTimeCheck === 0)
            {
                setEventTime(value);
            }
        }

        if (name === 'eventDuration')
        {
            const eventDurationCheck = CheckPostField(name, value);
            if (eventDurationCheck === 0)
            {
                setEventLocation(value);
            }
        }
    }

    if (error2 || error1)
    {
        return <div>Error: {error2.message}</div>;
    }
    else if (!isLoaded2 || !isLoaded1)
    {
        return (
            <Fragment>
                <NavBar/>
                <section className="sectionNDM sectionAdmin section100">
                    <div className="containerNDM containerAdmin margTop">
                        En attente
                    </div>
                </section>
                <FloatFooter/>
            </Fragment>);
    }
    else {
        if (items1.status === 0 && (items1.roles.includes('EDITOR') || items1.roles.includes('ADMINISTRATOR')))
        {
            return (
                <Fragment>
                    <NavBar/>
                    <section className="sectionNDM sectionAdmin section100">
                        <div className="containerNDM containerAdmin margTop">
                            <div id="containerBackground">
                                <h1>Modifier un événement</h1>
                                <form onSubmit={handleSubmit} id="formBackendView" encType="multipart/form-data" className="formFlex" action="">
                                    <div className='postTitle flexFormColCenter'>
                                        <label className="formFont" htmlFor="eventName">Nom de l'événement</label>
                                            <input
                                                type='text'
                                                name='eventName'
                                                className="postTitle"
                                                value={eventName}
                                                onChange={(e) => handleFieldChange(e)}
                                            />
                                    </div>

                                    <div className='postTitle flexFormColCenter'>
                                        <label className="formFont" htmlFor="eventDescription">Description</label>
                                        <Editor
                                            id="mytextarea"
                                            name="postTextarea"
                                            onInit={(evt, editor) => editorRef.current = editor}
                                            initialValue={eventDescription}
                                            apiKey='yaysfwznq8jhd2wmq7ovzetookk8gjqqh4yls46m1fp7c8z3'
                                            init={{
                                                height: 500,
                                                menubar: false,
                                                plugins: [
                                                    'advlist autolink lists link image charmap print preview anchor',
                                                    'searchreplace visualblocks code fullscreen',
                                                    'insertdatetime media table paste code help wordcount'
                                                ],
                                                toolbar: 'undo redo | formatselect | ' +
                                                    'bold italic forecolor underline | alignleft aligncenter ' +
                                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                                    'removeformat | help',
                                                forced_root_block : false,
                                                force_br_newlines : true,
                                                force_p_newlines : false,
                                            }}
                                            onEditorChange={handleEditorChange}/>
                                    </div>

                                    <div className='postTitle flexFormColCenter'>
                                        <label className="formFont" htmlFor="eventDate">Date</label>
                                        <input
                                            type='date'
                                            name='eventDate'
                                            className="postTitle"
                                            value={eventDate}
                                            onChange={(e) => handleFieldChange(e)}
                                        />
                                    </div>

                                    <div className='postTitle flexFormColCenter'>
                                        <label className="formFont" htmlFor="eventLocation">Lieu de l'événement</label>
                                        <input
                                            type='text'
                                            name='eventLocation'
                                            className="postTitle"
                                            value={eventLocation}
                                            onChange={(e) => handleFieldChange(e)}
                                        />
                                    </div>

                                    <div className='postTitle flexFormColCenter'>
                                        <label className="formFont" htmlFor="eventTime">Heure de l'événement</label>
                                        <input
                                            type='time'
                                            name='eventTime'
                                            className="postTitle"
                                            value={eventTime}
                                            onChange={(e) => handleFieldChange(e)}
                                        />
                                    </div>

                                    <div className='postTitle flexFormColCenter'>
                                        <label className="formFont" htmlFor="eventDuration">Durée de l'événement</label>
                                        <input
                                            type='time'
                                            name='eventDuration'
                                            className="postTitle"
                                            value={eventDuration}
                                            onChange={(e) => handleFieldChange(e)}
                                        />
                                    </div>

                                    <div className="flexFormColCenter" id="uploadZone">
                                        <label className="formFont" htmlFor="eventPicture">Image</label>
                                        <RequireImage srcImage={eventPicture} classnameImage="imgTableEvents" repImage="event"/>
                                        <input
                                            type="file"
                                            id="photo"
                                            name="eventPicture"
                                            ref={selectedFile}
                                            onChange={(e) => handleFileChange(e)}
                                        />
                                    </div>

                                    <div className="flexFormColCenter" id="uploadZone">
                                        <label className="formFont" htmlFor="eventSeatingPlan">Plan des tables</label>
                                        <RequireImage srcImage={eventSeatingPlan} classnameImage="imgTableEvents" repImage="event"/>
                                        <input
                                            type="file"
                                            id="photo"
                                            name="eventSeatingPlan"
                                            ref={selectedFile}
                                            onChange={(e) => handleFileChange(e)}
                                        />
                                    </div>

                                    <button type="submit" className="button buttonSize">Envoyer</button>

                                    <div className="flexFormColCenter flexFormColCenter" style={{visibility: messageStyle}}>
                                        <label className="formFont" htmlFor="eventSeatingPlan">Message: </label><div>{updateError}</div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </section>
                    <FloatFooter/>
                </Fragment>
            );
        }
        else
        {
            return (
                <Fragment>
                    <NavBar/>
                    <section className="sectionNDM sectionAdmin section100">
                        <div className="containerNDM containerAdmin margTop">
                            Vous n'êtes pas autorisé à afficher cette page
                        </div>
                    </section>
                    <FloatFooter/>
                </Fragment>);
        }
    }
}

export default EditEvent