import React, {Fragment, useEffect, useState} from 'react'
import { useHistory } from "react-router";
import slug from 'limax';
import NavBar from "../Commun/NavBar";
import FloatFooter from "../Commun/FloatFooter";
import CheckPostField from "../Commun/CheckPostField";
import {useCookies} from "react-cookie";
import TableCategories from "./TableCategories";
const {REACT_APP_API_URL} = process.env;


const CreateCategory = () =>
{
    const history = useHistory();
    const [useOnce1, setUseOnce1] = useState(0);
    const [useOnce2, setUseOnce2] = useState(0);
    const [items1, setItems1] = useState([]);
    const [isLoaded1, setIsLoaded1] = useState(false);
    const [error1, setError1] = useState(null);
    const [items2, setItems2] = useState([]);
    const [isLoaded2, setIsLoaded2] = useState(false);
    const [error2, setError2] = useState(null);
    const [cookies] = useCookies();
    const [category1, setCategory1] = useState('');
    const [category2, setCategory2] = useState('');
    const [category3, setCategory3] = useState('');
    const [updateError, setUpdateError] = useState(null);
    const [messageStyle, setMessageStyle] = useState("hidden");

    useEffect(() =>
    {
        if (useOnce1 === 0)
        {
            const tokenMember = cookies['ndmToken'];

            if (tokenMember)
            {
                let request = REACT_APP_API_URL + "member/get-autorisation/" + tokenMember;

                const optionsAPI =
                    {
                        method: 'GET'
                    };

                fetch(request, optionsAPI)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            setIsLoaded1(true);
                            setItems1(result);
                        },

                        (error) => {
                            setIsLoaded1(true);
                            setError1(error);
                        }
                    )
            }
            setUseOnce1(1);
        }
    });

    useEffect(() =>
    {
        if (useOnce2 === 0)
        {
            let request = REACT_APP_API_URL + "posts/get-category";

            const optionsAPI =
                {
                    method: 'GET'
                };

            fetch(request, optionsAPI)
                .then(res => res.json())
                .then(
                    (result) => {
                        setIsLoaded2(true);
                        setItems2(result);
                    },

                    (error) => {
                        setIsLoaded2(true);
                        setError2(error);
                    }
                )

            setUseOnce2(1);
        }
    })

    const handleSubmit = event =>
    {
        event.preventDefault();

        let formStatus = 1;
        if (category1 === '') { alert("La catégorie de l'article n'est pas défini"); formStatus = 0 }
        if (category2 === '') { alert("La sous-catégorie de niveau 1 de l'article n'est pas définie"); formStatus = 0 }
        if (category3 === '') { alert("La sous-catégorie de niveau 1 de l'article n'est pas défini"); formStatus = 0 }

        const category1Slug = slug(category1);
        const category2Slug = slug(category2);
        const category3Slug = slug(category3);

        if (formStatus === 1)
        {
            const memberToken = cookies['ndmToken'];
            const { REACT_APP_API_URL } = process.env;
            let request = REACT_APP_API_URL + "add-category";


            let selectFileData = new FormData();


            selectFileData.append('category1', category1);
            selectFileData.append('category2', category2);
            selectFileData.append('category3', category3);
            selectFileData.append('category1Slug', category1Slug);
            selectFileData.append('category2Slug', category2Slug);
            selectFileData.append('category3Slug', category3Slug);
            selectFileData.append('memberToken', memberToken);

            const options =
                {
                    method: 'POST',
                    body: selectFileData,
                };
            fetch(request, options)
                .then(
                    (result) => {
                        setUpdateError('');
                        history.push('/ndmback');
                    },

                    (error) => {
                        setMessageStyle("visible")
                        setUpdateError(error);
                    }
                )
        }
    }

    const handleFieldChange = event =>
    {
        event.preventDefault();

        const { name, value } = event.target;

        if (name === 'category1')
        {
            const category1Check = CheckPostField(name, value);
            if (category1Check === 0)
            {
                setCategory1(value);
            }
        }

        if (name === 'category2')
        {
            const category2Check = CheckPostField(name, value);
            if (category2Check === 0)
            {
                setCategory2(value);
            }
        }

        if (name === 'category3')
        {
            const category3Check = CheckPostField(name, value);
            if (category3Check === 0)
            {
                setCategory3(value);
            }
        }
    }

    if (error2 | error1)
    {
        return <div>Error: {error2.message}</div>;
    }
    else if (!isLoaded2 | !isLoaded1)
    {
        return (
            <Fragment>
                <NavBar/>
                <section className="sectionNDM sectionAdmin section100">
                    <div className="containerNDM containerAdmin margTop">
                        En attente
                    </div>
                </section>
                <FloatFooter/>
            </Fragment>);
    }
    else
    {
        if (items1.status === 0 && (items1.roles.includes('EDITOR') || items1.roles.includes('ADMINISTRATOR')))
        {
            return (
                <Fragment>
                    <NavBar/>
                    <section className="sectionNDM sectionAdmin section100">
                        <div className="containerNDM containerAdmin margTop">
                            <div id="containerBackground">
                                <h1>Créer une catégorie d'article</h1>

                                <h2>Exemple de catégorie: </h2>
                                <table className="tableExample">
                                    <thead>
                                    <tr>
                                        <th>Catégorie</th>
                                        <th>Sous-catégorie niveau 1</th>
                                        <th>Sous-catégorie niveau 2</th>
                                    </tr>
                                    </thead>
                                    <TableCategories dataItems={items2}/>
                                </table>

                                <form onSubmit={handleSubmit} id="formBackendView" className="formFlex" action="">
                                    <div className='postTitle flexFormColCenter'>
                                        <label className="formFont" htmlFor="category1">Catégorie</label>
                                        <div>
                                            <input
                                                type='text'
                                                name='category1'
                                                className="postTitle"
                                                onChange={(e) => handleFieldChange(e)}/>
                                        </div>
                                    </div>
                                    <div className='postTitle flexFormColCenter'>
                                        <label className="formFont" htmlFor="category2">Sous-catégorie niveau 1</label>
                                        <div>
                                            <input
                                                type='text'
                                                name='category2'
                                                className="postTitle"
                                                onChange={(e) => handleFieldChange(e)}/>
                                        </div>
                                    </div>
                                    <div className='postTitle flexFormColCenter'>
                                        <label className="formFont" htmlFor="category3">Sous-catégorie niveau 2</label>
                                        <div>
                                            <input
                                                type='text'
                                                name='category3'
                                                className="postTitle"
                                                onChange={(e) => handleFieldChange(e)}/>
                                        </div>
                                    </div>
                                    <button type="submit" className="button buttonSize">Envoyer</button>
                                    <div className="flexFormColCenter flexFormColCenter" style={{visibility: messageStyle}}>
                                        <label className="messageError">Message: {updateError}</label>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </section>
                    <FloatFooter/>
                </Fragment>
            );
        }
        else
        {
            return (
                <Fragment>
                    <NavBar/>
                    <section className="sectionNDM sectionAdmin section100">
                        <div className="containerNDM containerAdmin margTop">
                            Vous n'êtes pas autorisé à afficher cette page
                        </div>
                    </section>
                    <FloatFooter/>
                </Fragment>);
        }
    }
}

export default CreateCategory