import React from "react";
import {useHistory} from "react-router";

const TableCategory2 = props =>
{
    let rows = [];
    const history = useHistory();

    const submitEdition = (event, id) =>
    {
        event.preventDefault();
        history.push('/ndmback/modifier-categorie/' + id);
    }

    props.dataItems.forEach(function (fd)
    {
        let categoryId= fd.id;
        let buttonEditon = <button onClick={(e) => submitEdition(e, categoryId)} className="button buttonSize buttonManageEvent" type="submit">Editer</button>;

        rows.push(
            <tr key={fd.id}>
                <td>{fd.categoryName}</td>
                <td>{fd.subCategoryName}</td>
                <td>{fd.sub2CategoryName}</td>
                <td className="tdCenter">{buttonEditon}</td>
            </tr>
        )
    });

    return (<tbody>{rows}</tbody>);
}

export default TableCategory2