import {Link} from "react-router-dom";
import React, {Fragment, useEffect, useState} from "react";
import {useCookies} from "react-cookie";
const {REACT_APP_API_URL} = process.env;

const PanelConnection = props =>
{
    const [connected, setConnected] = useState('');
    const [pathConnected, setPathConnected] = useState('');
    const [useOnce, setUseOnce] = useState(0);
    const [items, setItems] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [cookies] = useCookies();
    const [menuC, setMenuC] = useState(false);

    useEffect(() =>
    {
        let cancel = false;

        if (useOnce === 0)
        {
            const tokenMember = cookies['ndmToken'];

            if (props.connected === '1')
            {
                setConnected('Déconnexion');
                setPathConnected('/deconnexion');
            }
            else
            {
                setConnected('Se connecter');
                setPathConnected('/connexion');
            }

            if (tokenMember)
            {
                let request = REACT_APP_API_URL + "member/get-autorisation/" + tokenMember;

                const optionsAPI =
                    {
                        method: 'GET'
                    };

                fetch(request, optionsAPI)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            if (cancel) return;
                            setIsLoaded(true);
                            setItems(result);
                        },

                        (error) => {
                            if (cancel) return;
                            setIsLoaded(true);
                            setError(error);
                        }
                    )
            }
            setUseOnce(1);
        }

        return () => {
            cancel = true;
        }
    },[]);

    const handleFieldHeaderChange = (event) =>
    {
        if (event.target.id === "menuC")
        {
            (menuC) ? setMenuC(false) : setMenuC(true)
        }
    }

    if (error)
    {
        return <div>Error: {error.message}</div>;
    }
    else
    {
        if (!isLoaded)
        {
            return (
                <li className="liNavlinks">
                    <Link className="aLink linkConnect" to={pathConnected}>{connected}</Link>
                </li>
            )
        }
        else
        {
            if (items.status === 0 && (items.roles.includes('EDITOR') || items.roles.includes('ADMINISTRATOR')))
            {
                return (
                    <Fragment>
                        <li className="navAdmin1 liNavlinks">
                            <label className="aNavlinks aLink" htmlFor="menuC">Admin</label>
                            <input className="accordionHeaderInput" id="menuC" type="checkbox" name="menu" checked={menuC} onChange={(e) => handleFieldHeaderChange(e)}/>

                            <ul className="underLinks">
                                <li><Link className="aLink" to="/ndmback">Accueil</Link></li>
                                <li><Link className="aLink" to="/ndmback/creer-article">Ecrire un article</Link></li>
                                <li><Link className="aLink" to="/ndmback/creer-rapport">Ecrire un rapport de conseil</Link></li>
                                <li><Link className="aLink" to="/ndmback/creer-trombinoscope">Créer un trombinoscope</Link></li>
                                <li><Link className="aLink" to="/ndmback/creer-evenement">Créer un événement</Link></li>
                                <li><Link className="aLink" to="/ndmback/creer-categorie">Créer une catégorie</Link></li>

                                <li><Link className="aLink" to="/ndmback/gerer-articles">Gérer les articles</Link></li>
                                <li><Link className="aLink" to="/ndmback/gerer-rapports">Gérer les rapports de conseil</Link></li>
                                <li><Link className="aLink" to="/ndmback/gerer-trombinoscopes">Gérer les trombinoscopes</Link></li>
                                <li><Link className="aLink" to="/ndmback/gerer-evenements">Gérer les événements</Link></li>
                                <li><Link className="aLink" to="/ndmback/gerer-categories">Gérer les catégories</Link></li>
                                <li><Link className="aLink" to="/ndmback/gerer-roles">Gérer les roles</Link></li>
                            </ul>
                        </li>
                        <li className="navAdmin2 liNavlinks">
                            <Link className="aNavlinks aLink" to="/ndmback">Admin</Link>
                        </li>
                        <li className="liNavlinks">
                            <Link className="aLink linkConnect" to={pathConnected}>{connected}</Link>
                        </li>
                    </Fragment>
                )
            }
            else
            {
                return (
                    <li className="liNavlinks">
                        <Link className="aLink linkConnect" to={pathConnected}>{connected}</Link>
                    </li>
                )
            }
        }
    }
}

export default PanelConnection