import React, {useEffect, Fragment, useState} from 'react';
import slug from 'limax';
import NavBar from "../Commun/NavBar";
import FloatFooter from "../Commun/FloatFooter";
import GetSessionStorage from "../Commun/GetSessionStorage";
import SetSessionStorage from "../Commun/SetSessionStorage";
import ChildHeight from "../Commun/ChildHeight";
import TableContainer from "./TableContainer";
import { Editor } from '@tinymce/tinymce-react';

const CreateEvent = () =>
{
    const [eventTitle, setEventTitle] = useState('');
    const [eventContent, setEventContent] = useState('');
    const [eventDate, setEventDate] = useState();
    const [eventStartTime, setEventStartTime] = useState();
    const [eventDuration, setEventDuration] = useState();
    const [eventLocation, setEventLocation] = useState('');
    const [eventNumberTable, setEventNumberTable] = useState(0);
    const [activeSlide, setActiveSlide] = useState(1);
    const [heights, setHeights] = useState([]);
    const [nbSlides, setNbSlides] = useState(0);
    const [useOnce, setUseOnce] = useState(0);
    const [eventSeatTitle, setEventSeatTitle] = useState([]);
    const [eventSeatPrice, setEventSeatPrice] = useState([]);
    const [leftSlides, setLeftSlides] = useState(0);
    const [heightSlides] = useState(110);
    const [widthSlides, setWidthSlides] = useState(0);
    const [oneSlide, setOneSlide] = useState(100);
    const [data, setData] = useState({"dataField":[], "instances" : []})

    const myRef = React.createRef();
    const dataFieldObj = [
        {
            header : "Numéro de table",
            inputField : true,
            length : 10,
            name: "tableNumber",
        },
        {
            header : "Type de table",
            inputField : true,
            length : 10,
            name: "tableType",
        },
        {
            header : "Nombre de place",
            inputField : true,
            length : 10,
            name: "nbSeat"
        }
    ];

    useEffect(() =>
    {
        if (useOnce < 1)
        {
            const slides = document.querySelectorAll("div.slide");
            const nbSlidesUseOnce = slides.length;
            const heightsUseOnce = [];

            setNbSlides(nbSlidesUseOnce);
            setWidthSlides(nbSlidesUseOnce * 100);

            for (let i = 1; i <= nbSlidesUseOnce; i++)
            {
                heightsUseOnce.push(ChildHeight(slides[i - 1]));
            }

            const oneSlideTemp = 100 / nbSlidesUseOnce;
            setHeights(heightsUseOnce);
            setUseOnce( 1);
            setOneSlide(oneSlideTemp);
        }
// AJOUTER CE QUI EST FAIT DANS LEFT ET RIGTH POUR LE CALCUL DE A PREMIERE FOIS/

    });

    const addElement = () =>
    {
        let seatTitle = document.getElementById('seatTitle').value;
        let seatPrice = document.getElementById('seatPrice').value;

        if (seatTitle && seatPrice)
        {
            let eventSeatTitleTemp = eventSeatTitle;
            eventSeatTitleTemp.push(seatTitle);
            setEventSeatTitle(eventSeatTitleTemp);
            SetSessionStorage('apelNDMeventSeatTitle', eventSeatTitleTemp);

            let eventSeatPriceTemp = eventSeatPrice;
            eventSeatPriceTemp.push(seatPrice);
            setEventSeatPrice(eventSeatPriceTemp);
        }

        document.getElementById('seatTitle').value = "";
        document.getElementById('seatPrice').value = 0;

        setUseOnce( 0);
    }

    const leftClick = () =>
    {
        setUseOnce( 0);
        let active = activeSlide;

        if (active > 1)
        {
            const slides = document.querySelectorAll("div.slide");
            const sliderWidth = myRef.current.clientWidth
            const leftTranslate = leftSlides + (sliderWidth / nbSlides);

            active--;
            setActiveSlide(active);
            setLeftSlides(leftTranslate);

            for (let i = 1; i <= nbSlides; i++)
            {
                if(i === active)
                {
                    setHeights(heights[i - 1]);
                    slides[i - 1].style.height = heights[i - 1] + "px";
                }
                else
                {
                    slides[i - 1].style.height = "10px";
                }
            }
        }
    }

    const rightClick = () =>
    {
        setUseOnce( 0);
        let active = activeSlide;

        if (active <= nbSlides - 1)
        {
            const slides = document.querySelectorAll("div.slide");
            const sliderWidth = myRef.current.clientWidth
            const leftTranslate = leftSlides - (sliderWidth / nbSlides);

            active++;
            setActiveSlide(active);
            setLeftSlides(leftTranslate);

            for (let i = 1; i <= nbSlides; i++)
            {
                if (i === active)
                {
                    setHeights(heights[i - 1]);
                    slides[i - 1].style.height = heights[i - 1] + "px";
                }
                else
                {
                    slides[i - 1].style.height = "10px";
                }
            }
        }
    }

    const handleChange = event =>
    {
        event.preventDefault();
        const { name, value } = event.target;

        switch (name) {
            case 'eventTitle':
                setEventTitle(value);
                break;
            case 'eventLocation':
                setEventLocation(value);
                break;
            case 'eventDate':
                setEventDate(value);
                break;
            case 'eventStartTime':
                setEventStartTime(value);
                break;
            case 'eventDuration':
                setEventDuration(value);
                break;
            case 'eventNumberTable':
                setEventNumberTable(value);
                let instancesObj = [];

                for (let i = 1; i <= value; i++)
                {
                    instancesObj.push({id: i, tableNumber: "Table numéro: " + i, tableType: "", nbSeat: 0});
                }
                const dataObj = { dataField: dataFieldObj, instances: instancesObj };
                SetSessionStorage('apelNDMdata', dataObj);
                setData(dataObj);
                break;

            default:
                break;
        }
    }

    const handleEditorChange = (content) =>
    {
        setEventContent(content);
    }

    const handleSubmit = event =>
    {
        event.preventDefault();
        let formStatus = 1;
        if (eventTitle === '') { alert("Le titre de l'événement n'est pas défini"); formStatus = 0 }
        if (eventContent === '') { alert("La description de l'événement n'est pas définie"); formStatus = 0 }
        if (eventDate === '') { alert("La date de l'événement n'est pas définie"); formStatus = 0 }
        if (eventStartTime === '') { alert("L'heure de début de l'événement n'est pas définie"); formStatus = 0 }
        if (eventDuration === '') { alert("La durée de l'événement n'est pas définie"); formStatus = 0 }
        if (eventLocation === '') { alert("Le lieu de l'événement n'est pas défini"); formStatus = 0 }
        if (eventNumberTable === 0) { alert("Le nombre de table de l'événement n'est pas défini"); formStatus = 0 }
        if (eventSeatTitle === []) { alert("Il n'y a aucun type de place de défini pour l'événement"); formStatus = 0 }
        if (eventSeatPrice === []) { alert("Il n'y a aucun prix de défini pour les places de l'événement"); formStatus = 0 }

        let dataTemp = GetSessionStorage('apelNDMdata', {"dataField":[], "instances" : []});

        if (dataTemp === {"dataField":[], "instances" : []}) { alert("Les données sur les tables ne sont pas définies"); formStatus = 0 }
        if (formStatus === 1)
        {
            const { REACT_APP_API_URL } = process.env;
            let request = REACT_APP_API_URL + "create-event";

            const options =
                {
                    method: 'POST',

                    body: JSON.stringify({
                            eventTitle: eventTitle,
                            eventContent: eventContent,
                            eventDate: eventDate,
                            eventStartTime: eventStartTime,
                            eventDuration: eventDuration,
                            eventLocation: eventLocation,
                            eventSeatTitle: eventSeatTitle,
                            eventSeatPrice: eventSeatPrice,
                            eventSlug: slug(eventTitle),
                            data: dataTemp
                        })
                };
            fetch(request, options)
            .then(response => { console.log(response); });

        }
    }

    return (
        <Fragment>
            <NavBar/>
            <section className="section sectionAdmin sectionFormEvent">
                <h1>Création d'un événement</h1>
                <form id='carouselCreateEvent' style={{ width: (widthSlides / nbSlides) + "%" }} onSubmit={handleSubmit}>
                    <div id='sliderCreateEvent' ref={myRef}
                         style={{
                             left : leftSlides,
                             width: widthSlides + "%",
                             height : heightSlides + "px"
                         }} >
                        <div className='eventTitle slide' style={{width : oneSlide + "%"}}>
                            <label htmlFor="eventTitle">Quel est le titre de l'événement ?</label>
                            <div><input type='text' name='eventTitle' value={eventTitle} onChange={handleChange}/></div>
                        </div>
                        <div className='eventLocation slide' style={{width : oneSlide + "%"}}>
                            <label htmlFor="eventLocation">Où se déroulera l'événement ?</label>
                            <div><input type='text' name='eventLocation' value={eventLocation} onChange={handleChange}/></div>
                        </div>
                        <div className='eventContent slide' style={{width : oneSlide + "%"}}>
                            <label htmlFor="eventContent">Description de l'événement</label>
                            <Editor
                                apiKey='yaysfwznq8jhd2wmq7ovzetookk8gjqqh4yls46m1fp7c8z3'
                                init={{
                                    height: 500,
                                    menubar: true,
                                    plugins: [
                                        'advlist autolink lists link image charmap print preview anchor',
                                        'searchreplace visualblocks code fullscreen',
                                        'insertdatetime media table paste code help wordcount'
                                    ],
                                    toolbar:
                                        'undo redo | formatselect | bold italic forecolor underline |  alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
                                }}
                                onEditorChange={handleEditorChange}
                                textareaName='eventContent'
                                powerpaste_word_import='clean'
                                powerpaste_html_import='clean'
                            />
                        </div>
                        <div className='eventDate slide' style={{width : oneSlide + "%"}}>
                            <label htmlFor="eventDate">Quelle est la date de l'événement ?</label>
                            <div><input type='date' name='eventDate' onChange={handleChange}/></div>
                        </div>
                        <div className='eventStartTime slide' style={{width : oneSlide + "%"}}>
                            <label htmlFor="eventStartTime">Quelle est l'heure de début de l'événement ?</label>
                            <div><input type='time' name='eventStartTime' onChange={handleChange}/></div>
                        </div>
                        <div className='eventDuration slide' style={{width : oneSlide + "%"}}>
                            <label htmlFor="eventDuration">Quelle est la durée de l'événement ?</label>
                            <div><input type='time' name='eventDuration' onChange={handleChange}/></div>
                        </div>
                        <div className='eventNumberTable slide' style={{width : oneSlide + "%"}}>
                            <label htmlFor="eventNumberTable">Combien de table voulez-vous ?</label>
                            <div><input type='number' name='eventNumberTable' onChange={handleChange}/></div>
                        </div>
                        <div className='eventSeatType slide' style={{width : oneSlide + "%"}}>
                            <label>Définissez les types de places</label>
                            <div className='containerSeat'>
                                <div className='containerSeat col-xs-12 col-sm-12 col-md-5'>
                                    <div className='labelSeat'>Libellé de la place</div><input type='text' id='seatTitle' />
                                </div>
                                <div className='containerSeat col-xs-12 col-sm-12 col-md-5'>
                                    <div className='labelSeat'>Prix de la place</div>
                                    <div className="input-group">
                                        <span className="input-group-text input-group-textEvent">€</span>
                                        <input type="number" min="0" step="0.01"
                                               data-number-to-fixed="2" data-number-stepfactor="100"
                                               id='seatPrice' lang="fr"
                                               className="inputNumberEvent" />
                                    </div>
                                </div>
                                <div className="btn btn-primary buttonSeat col-xs-12 col-sm-12 col-md-2" onClick={addElement.bind(this)}>Ajouter un élément</div>
                            </div>

                            <div className="eventWrapper">
                                <ul>
                                    Type de places :
                                    {
                                        Object.keys(eventSeatTitle).map( (index) => {
                                            return (
                                                <li>{eventSeatTitle[index]} - {eventSeatPrice[index]}</li>
                                            );
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className='eventTableType slide' style={{width : oneSlide + "%"}}>
                            <label htmlFor="eventTableType">Définissez les types de table</label>
                            <TableContainer dataProps={data} />
                            <div className='submitEvent'>
                                <button className="buttonEvent">Envoyer</button>
                            </div>
                        </div>
                    </div>
                </form>
                <div className="containerButton">
                    <div className='btnBefore btn btn-outline-warning' onClick={leftClick.bind(this)}>Précédant</div>
                    <div className='btnNext btn btn-outline-warning' onClick={rightClick.bind(this)}>Suivant</div>
                </div>
            </section>
            <FloatFooter/>
        </Fragment>
    );
}

export default CreateEvent

