import React, { Component } from 'react'
import RequireImage from "./RequireImage";

class Item extends Component
{
    constructor(props)
    {
        super(props)
        this.state = { level: this.props.level }
    }

    render()
    {
        const className = 'item level' + this.props.level;

        return(
            <div className={className}>
                <div className="imgBoardCont">
                    <RequireImage srcImage={this.props.id.image} classnameImage="imgBoards" repImage="board"/>
                </div>
                <div className="sliderName">
                    {this.props.id.firstname} {this.props.id.lastname}
                </div>
                <p className="sliderDescription" dangerouslySetInnerHTML={{ __html: this.props.id.description }} />
            </div>
        )
    }
}

export default Item