import React, {Fragment, useEffect, useState} from 'react'
import NavBar from "../Commun/NavBar";
import {useCookies} from "react-cookie";
import {useHistory} from "react-router";
import FloatFooter from "../Commun/FloatFooter";

const {REACT_APP_API_URL} = process.env;

const Reservation = () =>
{
    const [items, setItems] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [useOnce, setUseOnce] = useState(0);
    const [cookies, setCookie] = useCookies(['connectedCookie', 'connectedId', 'items', 'userEvent', 'reservationId']);
    const [setUserId] = useState(0);
    const history = useHistory();

    let reversationIdForCookie = 0;

    useEffect(() =>
    {
        if (useOnce === 0)
        {
            let userId = cookies['connectedId'];
            setUserId(userId);

            let userEvent = cookies.userEvent;
            let request = REACT_APP_API_URL + "event-reservation?id=" + encodeURIComponent(userEvent) + "&userId=" + encodeURIComponent(userId);

            fetch(request, {"method": "GET"})
                .then(res => res.json())
                .then(
                    (result) =>
                    {
                        setIsLoaded(true);
                        setItems(result);
                    },

                    (error) =>
                    {
                        setIsLoaded(true);
                        setError(error);
                    }
                )
            setUseOnce(1);
        }
    })

    const goPay = () =>
    {
        setCookie('reservationId', reversationIdForCookie, {path: "/"});
        history.push('/paiement');
    }

    if (error)
    {
        return <div>Error: {error.message}</div>;
    }
    else if (!isLoaded)
    {
        return (
            <Fragment>
                <NavBar/>
                <section className="sectionNDM sectionCommissions section100">
                    <div className="containerNDM containerCommissions margTop">
                        En attente
                    </div>
                </section>
                <FloatFooter/>
            </Fragment>);
    }
    else
    {
        let finalPrice = 0;
        let rows = [];
        items.forEach(function (reservation, index)
        {
            finalPrice = finalPrice + reservation['seat_price'];

            reversationIdForCookie = reservation['reservation']['id'];
            rows.push(
                <Fragment>
                    <tr className="tableReservation" key={reservation.id}>
                        <td className="inputReservation">{reservation.table_number}</td>
                        <td className="inputReservation">{reservation.seat_number}</td>
                        <td className="inputReservation">{reservation.seat_category}</td>
                        <td className="inputReservation">{reservation.first_name}</td>
                        <td className="inputReservation">{reservation.lastname}</td>
                        <td className="inputReservation">{reservation.seat_price}</td>
                        <td></td>
                    </tr>
                </Fragment>)
        }, this);

        return (
            <Fragment>
                <NavBar/>
                <section className="sectionNDM sectionCommissions section100">
                    <div className="containerNDM containerCommissions margTop">
                        <table className="table table-striped">
                            <thead>
                            <th className="inputReservation">Numéro de table</th>
                            <th className="inputReservation">Numéro de siège</th>
                            <th className="inputReservation">Type de place</th>
                            <th className="inputReservation">Prénom</th>
                            <th className="inputReservation">Nom</th>
                            <th className="inputReservation">Prix (Euros)</th>
                            </thead>
                            <tbody>
                            {rows}
                            </tbody>
                        </table>
                        <div> Total : {finalPrice}</div>
                        <div className="buttonOrder" onClick={goPay}>Payer: {finalPrice}</div>
                    </div>
                </section>
                <FloatFooter/>
            </Fragment>
        );
    }
}

export default Reservation