import React, {Fragment, useEffect, useState} from 'react'
import NavBar from "../Commun/NavBar";
import FloatFooter from "../Commun/FloatFooter";
import {useCookies} from "react-cookie";
import TableEvents from "./TableEvents";
import TableEventsCols from "./TableEventsCols";

;

const { REACT_APP_API_URL } = process.env;

const ManageEvents = () =>
{
    const [useOnce1, setUseOnce1] = useState(0);
    const [useOnce2, setUseOnce2] = useState(0);
    const [items1, setItems1] = useState([]);
    const [isLoaded1, setIsLoaded1] = useState(false);
    const [error1, setError1] = useState(null);
    const [items2, setItems2] = useState([]);
    const [isLoaded2, setIsLoaded2] = useState(false);
    const [error2, setError2] = useState(null);
    const [cookies] = useCookies();


    useEffect(() =>
    {
        if (useOnce1 === 0)
        {
            const tokenMember = cookies['ndmToken'];

            if (tokenMember)
            {
                let request = REACT_APP_API_URL + "member/get-autorisation/" + tokenMember;

                const optionsAPI =
                    {
                        method: 'GET'
                    };

                fetch(request, optionsAPI)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            setIsLoaded1(true);
                            setItems1(result);
                        },

                        (error) => {
                            setIsLoaded1(true);
                            setError1(error);
                        }
                    )
            }
            setUseOnce1(1);
        }

        if (useOnce2 === 0)
        {
            let request = REACT_APP_API_URL + "events";

            const optionsAPI =
                {
                    method: 'GET'
                };

            fetch(request, optionsAPI)
                .then(res => res.json())
                .then(
                    (result) => {
                        setIsLoaded2(true);
                        setItems2(result);
                    },

                    (error) => {
                        setIsLoaded2(true);
                        setError2(error);
                    }
                )

            setUseOnce2(1);
        }
    })

    if (error2 || error1)
    {
        return <div>Error: {error2.message}</div>;
    }
    else if (!isLoaded2 || !isLoaded1)
    {
        return (
            <Fragment>
                <NavBar/>
                <section className="sectionNDM sectionAdmin section100">
                    <div className="containerNDM containerAdmin margTop">
                        En attente
                    </div>
                </section>
                <FloatFooter/>
            </Fragment>);
    }
    else {
        if (items1.status === 0 && (items1.roles.includes('EDITOR') || items1.roles.includes('ADMINISTRATOR')))
        {
            return (
                <Fragment>
                    <NavBar/>
                    <section className="sectionAdmin section100">
                        <div className="containerNDM containerAdmin margTop">
                            <div id="tableContainerManagePosts">
                                <div className="table1">
                                    <div id="tableManagePosts">
                                        <h1>Gestion des événements</h1>
                                        <table className="tableManagePosts">
                                            <thead>
                                                <tr>
                                                    <th>Titre</th>
                                                    <th>Description</th>
                                                    <th>Date</th>
                                                    <th>Image</th>
                                                    <th>Plan</th>
                                                    <th>Lieu</th>
                                                    <th>Heure</th>
                                                    <th>Durée</th>
                                                    <th>Réservable</th>
                                                    <th>Statut</th>
                                                    <th>Editer</th>
                                                </tr>
                                            </thead>
                                            <TableEvents dataItems={items2}/>
                                        </table>
                                    </div>
                                </div>
                                <div className="table2">
                                    <div id="tableManagePosts">
                                        <h1>Gestion des événements</h1>
                                        <table className="tableManagePosts">
                                            <TableEventsCols dataItems={items2}/>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <FloatFooter/>
                </Fragment>
            );
        }
        else
        {
            return (
                <Fragment>
                    <NavBar/>
                    <section className="sectionNDM sectionAdmin section100">
                        <div className="containerNDM containerAdmin margTop">
                            Vous n'êtes pas autorisé à afficher cette page
                        </div>
                    </section>
                    <FloatFooter/>
                </Fragment>);
        }
    }
}

export default ManageEvents