import React, { Fragment } from 'react'
import NavBar from "../Commun/NavBar";
import FloatFooter from "../Commun/FloatFooter";
import OnePost from "../Commun/OnePost";

const Charte = () =>
{
    return (
        <Fragment>
            <NavBar/>
            <section className="sectionNDM sectionAssociation section100">
                <div className="containerNDM containerCommissions margTop">
                    <OnePost slug={"la-charte-éducative-de-confiance"}/>
                </div>
            </section>
            <FloatFooter/>
        </Fragment>
    );
}

export default Charte