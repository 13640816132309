import React, {Fragment, useEffect, useState} from "react";
import NavBar from "../Commun/NavBar";
import {Elements} from "@stripe/react-stripe-js";
import CheckoutForm from "../Commun/CheckoutForm";
import {loadStripe} from '@stripe/stripe-js';
import {useCookies} from "react-cookie";
import FloatFooter from "../Commun/FloatFooter";
const {REACT_APP_API_URL} = process.env;
const { STRIPE_PUBLISHABLE_KEY } = process.env;
const stripePromise = loadStripe("pk_test_51Jr1wXKUzZRJ94aP4LC5xcKGV2Kx6iTvGMHDfw3VQW6BAwnWg62flKLJXJ9m3BgF8RlqjZtflC4YXtTm7ugLCo2X006DQZQ17a");


const Paiement = () =>
{
    const [useOnce, setUseOnce] = useState(0);
    const [clientSecret, setClientSecret] = useState('');
    const [information, setInformation] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [cookies, setCookie, removeCookie] = useCookies(['reservationId']);
    const appearance = { theme: 'stripe' };

    useEffect(() =>
    {
        if (useOnce === 0)
        {
            let reservationId = cookies['reservationId'];
            console.log('reservationId: '+ reservationId);
            let request = REACT_APP_API_URL + "payment/reservation?reservationId=" + encodeURIComponent(reservationId);

            console.log('pay');
            const optionsAPI =
                {
                    method: 'POST'
                 /*   method: 'POST',
                    body: JSON.stringify({reservations: items}) */
                };

            fetch(request, optionsAPI)
                .then((res) => {
                    if(res.status === 200)
                    {
                        return res.json();
                    }
                    if(res.status === 204)
                    {
                        setInformation('Le paiement de la réservation a déjà été effectuée');
                    }
                    })
                .then(
                    (res) => {
                        console.log('result: ' + res);
                        console.log('result clientS: ' + res.secret);
                        let client_secret = res.secret;
                        setClientSecret(client_secret);
                        setIsLoaded(true);
                        },
                    (error) => { setError(error); }
                )

            setUseOnce(1);
        }
    })

    if (error)
    {
        return <div>Error: {error.message}</div>;
    }
    else if (!isLoaded)
    {
        return (
            <Fragment>
                <NavBar/>
                <section className="sectionNDM sectionCommissions section100">
                    <div className="containerNDM containerCommissions margTop">
                        En attente
                    </div>
                </section>
                <FloatFooter/>
            </Fragment>);
    }
    else if (information !== '')
    {
        return (
            <Fragment>
                <NavBar/>
                <section className="sectionNDM sectionCommissions section100">
                    <div className="containerNDM containerCommissions margTop">
                        {information}
                    </div>
                </section>
                <FloatFooter/>
            </Fragment>);
    }
    else
    {
        console.log('stripepro: ' + JSON.stringify(stripePromise));
        console.log('resultAPI:' + clientSecret);
        const options = {
            clientSecret,
            appearance,
        };
        console.log('options: ' + JSON.stringify(options));

        return (
            <Fragment>
                <NavBar/>
                <section className="sectionNDM sectionCommissions section100">
                    <div className="containerNDM containerCommissions margTop">
                        <Elements stripe={stripePromise} options={options}>
                            <CheckoutForm/>
                        </Elements>
                    </div>
                </section>
                <FloatFooter/>
            </Fragment>)
    }
}

export default Paiement